import React from "react";
import styles from "./Navigation.module.scss";
import { ReactComponent as Logo } from "../../../assets/images/new_omegame_logo.svg";
import menu from "../../../assets/images/burger-menu.svg";
import chat from "../../../assets/images/chat.svg";
import notifications1 from "../../../assets/images/notifications.svg";
import profile from "../../../assets/images/profile-pic.svg";
import { NavLink, Link } from "react-router-dom";
import { LogOutUser } from "../../../services";
import DialogBox from "../dialogbox/Dialogbox";
import { useState, useEffect } from "react";
import { GetNotificationCounter } from "../../../services/PushNotifications/NotificationMethods";
import settings from "../../../assets/images/profile-settings.svg";
import { StyledMenu, StyledMenuItem } from "./Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import customize from "../../../assets/images/profile-customize.svg";
import users from "../../../assets/images/profile-user.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getData } from "../../../utils/AppStorage";
import "./rightbar.css";
import cross from "../../../assets/images/cross.png";
import NavMobile from "./NavMobile";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import { useTranslation } from "react-i18next";

const Navigation = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [openDialog, setDialog] = React.useState(false);
  const [counter, setCounter] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [storeUser, setStoreUser] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  let userData = JSON.parse(localStorage.getItem("user"));
  const { user } = useSelector((state) => state.root);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotificationsHistory = () => {
    GetNotificationCounter((res) => {
      if (res) {
        setCounter(res.data.notificationCount);
      }
    });
  };
  useEffect(() => {
    getData("user", (data) => {
      if (data) {
        setStoreUser(data);
      }
    });
    getNotificationsHistory();
  }, []);
  return (
    <div className={`${styles.container}`}>
      <div className={styles.wrapper}>
        <NavLink exact={true} to="/">
          <div className={styles.logo}>
            <Logo />
            {/* <img src={logo} alt="OMEGAME" /> */}
            {/* <span>Omegame</span> */}
          </div>
        </NavLink>
        {toggleMenu && openDialog ? (
          <DialogBox value={openDialog} setvalue={setDialog} />
        ) : (
          ""
        )}
        <div className={styles.menuIcon}>
          <img src={menu} alt="menu" onClick={() => setToggleMenu(true)} />
        </div>
        {console.log("tootle menus", toggleMenu)}
        <NavMobile
          toggleMenu={toggleMenu}
          setToggleMenu={setToggleMenu}
          openDialog={openDialog}
          setDialog={setDialog}
        />

        <div className={styles.menubar}>
          <div className={styles.pageLinks}>
            <NavLink
              exact
              to="/"
              className={styles.inactive}
              activeClassName={styles.active}
            >
              <div className={styles.homeIcon}></div>
              <div className={styles.text}>{t("navigation.home")}</div>
            </NavLink>

            <NavLink
              to="/explore"
              className={styles.inactive}
              activeClassName={styles.active}
            >
              <div className={styles.exploreIcon}></div>
              <div className={styles.text}>{t("navigation.explore")}</div>
            </NavLink>

            <NavLink
              to="/reviews"
              className={styles.inactive}
              activeClassName={styles.active}
            >
              <div className={styles.reviewsIcon}></div>
              <div className={styles.text}>{t("navigation.reviews")}</div>
            </NavLink>

            <NavLink
              to="/quests"
              className={styles.inactive}
              activeClassName={styles.active}
            >
              <div className={styles.questsIcon}></div>
              <div className={styles.text}>{t("navigation.quests")}</div>
            </NavLink>

            {/* <NavLink
              className={styles.inactive}
              activeClassName={styles.active}
            >
              <div className={styles.postIcon}></div>
              <div className={styles.text}>New Post</div>
            </NavLink> */}
            <div
              className={styles.inactive}
              activeClassName={styles.active}
              style={{ cursor: "pointer" }}
              onClick={() => setDialog(true)}
            >
              <div className={styles.postIcon}></div>
              <div className={styles.text}>{t("navigation.newPost")}</div>
            </div>
            {openDialog ? (
              <DialogBox value={openDialog} setvalue={setDialog} t={t} />
            ) : (
              ""
            )}
          </div>
          <div className={styles.otherLinks}>
            <div className={styles.otherItem}>
              <Link to="/chat-screen">
                <div className={styles.itemIcon}>
                  <img src={chat} alt="chat" />
                </div>
              </Link>
            </div>
            <div className={styles.otherItem}>
              <Link to="/notifications">
                <div
                  className={styles.itemIcon}
                  onClick={() => {
                    setCounter(0);
                  }}
                >
                  <img src={notifications1} alt="notificatons" />
                  {counter > 0 ? (
                    <span className={styles.counter}>{counter}</span>
                  ) : null}
                </div>
              </Link>
            </div>

            <div>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <div className={styles.profile}>
                  <div className={styles.profileImage}>
                    <UserAvatar
                      size="35px"
                      innerImageSize="30px"
                      user={userData}
                    />
                    {/* <img
                      src={userData?.pic || profile}
                      alt="profile"
                      style={{ width: "35px", height: "35px" }}
                    /> */}
                  </div>
                </div>
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem
                  onClick={() => {
                    history.push({
                      pathname: "/profile",
                      state: { userID: storeUser?._id },
                    });
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <img src={users} alt="users" />
                  </ListItemIcon>
                  <ListItemText primary={t("navigation.profile")} />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    history.push({
                      pathname: "/setting",
                      state: { userData: user },
                    });
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <img src={settings} alt="Settings" />
                  </ListItemIcon>
                  <ListItemText primary={t("navigation.settings")} />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    history.push({
                      pathname: "/customize",
                      state: { userData: user },
                    });
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <img src={customize} alt="Customize" />
                  </ListItemIcon>
                  <ListItemText primary={t("navigation.customize")} />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
