import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { IconButton } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PostCard from "../Posts/PostCard/PostCard";
import { Container } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import "./postCamera.css";

export default function PostCamera({ value, setval, response }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  console.log("response", response);
  const styles = (theme) => ({
    root: {
      margin: 5,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const handleClose = () => {
    setval(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={value}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        // style={{ backgroundColor: "black" }}
        PaperProps={{
          style: {
            backgroundColor: "black",
            // boxShadow: "none",
          },
        }}
        disableBackdropClick
      >
        <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
          Camera Screen
        </DialogTitle>

        <DialogContent>
          <PostCard postData={response} innerWidth={"500px"} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
