import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import NicknameBox from "./NicknameBox";
import ColorScheme from "../../Profile/Customize/CustomizeTabs/Corner/ColorScheme";
import { GetAllAssets } from "../../../services/customizationService";
import { ASSET_TYPES } from "../../../utils/AppConstants";
import "../../../styles/loader.css";
import BuyNickname from "./BuyNickname";
const Nickname = () => {
  const [nickName, setNickname] = useState("");
  const [loading, setLoading] = useState(false);
  const [avatarData, setAvatarData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [color, setColor] = useState("gray");
  const [reloadData, setReloadData] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setLoading(true);
    GetAllAssets((res) => {
      console.log(res);
      setNickname(res);
      setLoading(false);
    }, ASSET_TYPES.NICKNAME);
  }, [reloadData]);
  const handleModalData = (picData) => {
    setAvatarData(picData);
    setOpenDialog(!openDialog);
  };
  const handleColor = (data) => {
    setColor(data);
  };
  const handleClose = () => {
    setOpenDialog(!openDialog);
    setReloadData(!reloadData);
  };
  return (
    <div>
      {loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <div style={{ marginLeft: "132px" }}>
          {/* <ColorScheme handleColor={handleColor} /> */}
          <Grid container spacing={3}>
            {nickName?.length > 0
              ? nickName?.map((item, index) => (
                  <NicknameBox
                    data={item}
                    handleModalData={handleModalData}
                    color={color}
                    user={user}
                  />
                ))
              : null}
          </Grid>
        </div>
      )}
      {openDialog ? (
        <BuyNickname
          open={openDialog}
          handleClose={handleClose}
          data={avatarData}
          color={color}
          user={user}
        />
      ) : null}
    </div>
  );
};
export default Nickname;
