import React, { useState, useEffect } from "react";
import ConversationList from "../ConversationList";
import MessageList from "../MessageList";
import EmptyChatComponent from "../EmptyChatComponent";
import { useSelector, useDispatch } from "react-redux";
import { GET_FRIEND_LIST_TYPES } from "../../../utils/AppConstants";
import { GetUserList } from "../../../services/profileService";
import { GetInboxList } from "../../../services/chatService";
import { resetComment } from "../../../redux/reducers/commentSlice";
import NewChat from "../NewChat";

import styles from "./Messenger.module.scss";
import { setFriends } from "../../../redux/reducers/friendsSlice";
import { useTranslation } from "react-i18next";

export default function Messenger(props) {
  const [selected, setSelected] = useState(null);
  const [newChatModal, setNewChatModal] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [reload, setReload] = useState(false);
  const friends = useSelector((state) => state.root.friends);
  const user = useSelector((state) => state.root.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const comment = useSelector((state) => console.log(state.root.comment));
  const { t } = useTranslation();
  const setSelectedConversation = (data) => {
    setSelected(data);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const otherUser =
    selected?.message.from._id === user?._id
      ? selected?.message.to
      : selected?.message.from;

  function getfriendshelper(cursor) {
    setLoading(true);
    GetUserList(
      (response) => {
        if (response) {
          setLoading(false);
          dispatch(setFriends(response.data));
          // dispatch(resetComment());

          // setState(prev => ({ ...prev, loading: false }));
        }
        // setState(prev => ({ ...prev, loading: false }));
      },
      10,
      "DESC",
      searchQuery,
      ""
    );
  }

  const toggleNewChatModal = () => {
    setNewChatModal(!newChatModal);
  };

  const startNewChat = (item) => {
    let temp = [...conversations];
    temp.push(item);
    setConversations(temp);
    // setSelected(item);
  };

  const conversationsData = async () => {
    GetInboxList((res) => {
      console.log(res);
      setConversations([...res]);
    }, "");
  };

  const handleReload = () => {
    setReload(!reload);
  };
  useEffect(() => {
    conversationsData();
  }, [comment, reload]);
  useEffect(() => {
    getfriendshelper(false);
  }, [searchQuery]);

  return (
    <>
      <div className={styles.messenger}>
        <div
          className={`${styles.scrollable} ${styles.content} ${styles.conversationsPadding}`}
        >
          <ConversationList
            selected={selected}
            setSelectedConversation={setSelectedConversation}
            toggleNewChatModal={toggleNewChatModal}
            otherUser={otherUser}
            friends={friends}
            conversations={conversations}
            handleReload={handleReload}
            t={t}
          />
        </div>

        <div className={`${styles.scrollable} ${styles.content}`}>
          {selected ? (
            <MessageList
              selected={selected}
              otherUser={otherUser}
              handleReload={handleReload}
              setSelectedConversation={setSelectedConversation}
              t={t}
            />
          ) : (
            <EmptyChatComponent t={t} />
          )}
        </div>
      </div>
      <NewChat
        show={newChatModal}
        handleClose={toggleNewChatModal}
        friends={friends}
        startNewChat={startNewChat}
        chatData={conversations}
        conversationsData={conversationsData}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        loading={loading}
        t={t}
      />
    </>
  );
}
