import React, { useEffect, useState } from "react";
import { GetInboxList } from "../../../services/chatService";
import ConversationListItem from "../ConversationListItem";
import ConversationSearch from "../ConversationSearch";
import Toolbar from "../Toolbar";
import ToolbarButton from "../ToolbarButton";
import "./ConversationList.css";

export default function ConversationList({
  setSelectedConversation,
  toggleNewChatModal,
  conversations,
  handleReload,
  t,
}) {
  const [searchData, setSearchData] = useState([]);

  const setSearch = (res) => {
    console.log(typeof res);

    setSearchData(res);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="conversation-list">
        <Toolbar
          title={t("chat.chatHead")}
          leftItems={[<ToolbarButton icon="ion-ios-cog" />]}
          rightItems={[
            <div key="add" onClick={toggleNewChatModal}>
              <ToolbarButton icon="ion-ios-add-circle-outline" />
            </div>,
          ]}
        />
        {/* <ConversationSearch
          conversations={conversations}
          setSearch={setSearch}
        /> */}
        {conversations.length > 0 ? (
          searchData.length > 0 ? (
            searchData.map((conversation, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedConversation(conversation);
                  }}
                >
                  <ConversationListItem />
                </div>
              );
            })
          ) : (
            <div style={{ marginTop: "40px" }}>
              {conversations.map((conversation, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedConversation(conversation);
                    }}
                  >
                    <ConversationListItem data={conversation} />
                  </div>
                );
              })}
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 700,
              flexDirection: "column",
            }}
          >
            <h2 style={{ color: "white" }}>{t("chat.noChat")}</h2>
            <p
              style={{
                color: "grey",
                textAlign: "center",
                fontWeight: "lighter",
                fontSize: 14,
              }}
            >
              {t("chat.addMore")}
            </p>
          </div>
        )}
      </div>
    </>
  );
}
