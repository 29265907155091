import React, { useState, useEffect } from "react";
import styles from "./InteractionBar.module.scss";
import { ReactComponent as Like } from "../../../../assets/images/like.svg";
import CommentImage from "../../../../assets/images/comment.svg";
import share from "../../../../assets/images/share.svg";
import { ReactComponent as Save } from "../../../../assets/images/save.svg";
import Heart from "../../../../assets/images/heart.svg";
import Comment from "../comments/comment";
import { setComment } from "../../../../redux/reducers/commentSlice";
import { resetCount } from "../../../../redux/reducers/countSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateShareFromReduxStore } from "../../../../services/mutateReduxState";
import {
  AppShareContents,
  largeNumberShortify,
} from "../../../../utils/AppHelperMethods";
import {
  LikePost,
  SaveOrBookMarkPost,
  SharePost,
  UpdatePostFromReduxStore,
} from "../../../../services";
import {
  DEEP_LINK_TYPES,
  SHARE_STATUS_TYPES,
} from "../../../../utils/AppConstants";
import { DOMAIN } from "../../../../utils/AppEndpoints";
import ShareDialogBox from "../../dialogbox/sharePost";

const InteractionBar = ({ postData, interWidth, open, setClose }) => {
  // console.log(postData?.isLiked);
  const dispatch = useDispatch();
  const { comment, query, count } = useSelector((state) => state.root);
  // console.log(
  //   "count",
  //   Number(postData?.computed?.find((ii) => ii.key === "COMMENTS")?.value) +
  //     Number(count)
  // );
  const [state, setState] = useState({
    isShared: postData?.isShared || false,
    isLiked:
      postData?.isLiked &&
      postData?.computed?.find((ii) => ii.key === "LIKE")?.value
        ? postData?.isLiked
        : false,
    isSaved: postData?.isSaved || false,
    postLike: postData?.computed?.find((ii) => ii.key === "LIKE")?.value || 0,
    value: postData?.isLiked ? 1 : 0,
    commentCount:
      postData?.computed?.find((ii) => ii.key === "COMMENTS")?.value || 0,
    toggleCommentBox: false,
    openSharedialog: false,
  });

  React.useEffect(() => {
    setState({
      ...state,
      isLiked:
        postData?.isLiked &&
        postData?.computed?.find((ii) => ii.key === "LIKE")?.value !== 0
          ? postData?.isLiked
          : false,
      commentCount: postData?.computed?.find((ii) => ii.key === "COMMENTS")
        ?.value,
    });
  }, []);
  // console.log(window.location.href());

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.interactions}
        style={{ minWidth: `${interWidth ? "448px" : ""}` }}
        // style={{
        //   minWidth: `${interWidth ? interWidth : "700px"}`,
        //   maxWidth: "inherit",
        // }}
      >
        <div
          className={styles.item}
          onClick={() => {
            UpdatePostFromReduxStore({ ...postData, isLiked: !state.isLiked });
            LikePost(
              () => {
                setState((prev) => ({
                  ...prev,
                  postLike:
                    state.value === 1 && state.postLike !== 0 && state.isLiked
                      ? state.postLike - 1
                      : state.value === 0 &&
                        state.postLike !== 0 &&
                        !state.isLiked
                      ? state.postLike + 1
                      : state.value === 0 &&
                        state.postLike === 0 &&
                        !state.isLiked
                      ? state.postLike + 1
                      : state.postLike === 0 &&
                        !state.isLiked &&
                        state.value !== 0
                      ? state.postLike + 1
                      : state.postLike !== 0 &&
                        state.isLiked &&
                        state.value == 0
                      ? state.postLike - 1
                      : state.postLike,

                  isLiked: !state.isLiked,
                  value: !state.value ? 1 : 0,
                }));
                // dispatch(setComment({}));
              },
              postData?._id,
              {
                type: state.isLiked ? "UN_LIKE" : "LIKE",
              }
            );
          }}
        >
          {/* {console.log("state value", state.value)} */}
          {/* {console.log("post like val", state.postLike)} */}
          <div className={styles.icon}>
            {state.isLiked ? <Like className={styles.img} /> : <Like />}

            {/* <img src={state.isLiked ? "asdf" : like} alt="like" /> */}
          </div>
          <div className={styles.text}>
            {largeNumberShortify(state.postLike)}
          </div>
        </div>
        <div
          className={styles.item}
          onClick={() => {
            if (open) {
              setClose({
                ...open,
                isopen: false,
              });
            }
            //:TO-DO navigate to comments screen
            setState(() => ({
              ...state,
              toggleCommentBox: !state.toggleCommentBox ? true : false,
            }));
          }}
        >
          <div className={styles.icon}>
            <img src={CommentImage} alt="comments" />
          </div>
          <div className={styles.text}>
            {largeNumberShortify(
              postData?.computed?.find((ii) => ii.key === "COMMENTS")?.value ||
                0
            )}
          </div>
        </div>

        <div
          className={styles.item}
          onClick={() => {
            SharePost(
              (data) => {
                updateShareFromReduxStore(data?.data?.post);
                setState({
                  ...state,
                  openSharedialog: true,
                });
                dispatch(setComment({}));
              },
              postData?._id,
              {
                platform: SHARE_STATUS_TYPES.FACEBOOK,
              }
            );
            AppShareContents((res) => {
              setState((prev) => ({ ...prev, isShared: res }));
            }, `Hey you might wanna check this post out on OmeGame.\n${DOMAIN}?${DEEP_LINK_TYPES.POST_ID}=${postData?._id}`);
          }}
        >
          <div className={styles.icon}>
            <img src={share} alt="share" />
          </div>
          <div className={styles.text}>
            {largeNumberShortify(
              postData?.computed?.find((ii) => ii.key === "SHARE")?.value +
                (state.isShared ? 1 : 0) || (state.isShared ? 1 : 0)
            )}
          </div>
        </div>

        {window.location.href.match(`/explore/${postData?._id}`) != null ? (
          state.openSharedialog ? (
            <>
              <ShareDialogBox
                value={state}
                setvalue={setState}
                obj={`Hey you might wanna check this post out on OmeGame.\n${window.location.href}`}
              />
            </>
          ) : (
            ""
          )
        ) : state.openSharedialog ? (
          <>
            <ShareDialogBox
              value={state}
              setvalue={setState}
              obj={`Hey you might wanna check this post out on OmeGame.\n${window.location.href}/${postData?._id}`}
            />
          </>
        ) : (
          ""
        )}

        <div
          className={styles.item}
          onClick={() => {
            UpdatePostFromReduxStore({ ...postData, isSaved: !state.isSaved });
            SaveOrBookMarkPost((res) => {}, postData?._id, {
              bookmark: !state.isSaved,
            });

            setState((prev) => ({ ...prev, isSaved: !state.isSaved }));
          }}
        >
          <div className={styles.icon}>
            {state.isSaved ? <Save className={styles.img} /> : <Save />}
          </div>
        </div>
      </div>
      <div className={styles.list_bar}>
        {open?.isopen ? (
          <Comment PostInfo={postData} />
        ) : state.toggleCommentBox ? (
          <div
            style={{
              paddingTop: "12px",
              marginTop: "6px",
            }}
          >
            <Comment PostInfo={postData} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default InteractionBar;
