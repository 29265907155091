import React, { useRef } from "react";
import styles from "./PostUserInfo.module.scss";
import UserAvatar from "../../UserAvatar/UserAvatar";
import verified from "../../../../assets/images/verified.svg";
import options from "../../../../assets/images/options.svg";
import { Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getData } from "../../../../utils/AppStorage";
import DialogBox from "../../dialogbox/Dialogbox";
import DeletePost from "../../dialogbox/deleteDialogbox";
import BlockUserDialog from "../../dialogbox/userBlockDialogbox";
import ReportDialogBox from "../../dialogbox/reportDialogbox";
import { FollowPost, PostMuteUnmute } from "../../../../services/postService";
import { ReactComponent as Block } from "../../../../assets/images/block.svg";
import { ReactComponent as Delete } from "../../../../assets/images/delete.svg";
import { ReactComponent as Mute } from "../../../../assets/images/mute.svg";
import { ReactComponent as Report } from "../../../../assets/images/report.svg";
import { ReactComponent as Follow } from "../../../../assets/images/unfollow.svg";
import "./postUserinfo.css";
import { useTranslation } from "react-i18next";
const PostUserInfo = ({ userData, createdAt }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [ShowDelDialog, setShowDelDialog] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [block, setblock] = React.useState(false);
  const [report, setreport] = React.useState(false);
  // console.log("data", userData);
  const [isfollow, setFollow] = React.useState(
    userData?.createdBy?.isFollowing || false
  );
  const [ismute, setMute] = React.useState(false);
  React.useEffect(() => {
    if (user === "") {
      getData("user", (data) => {
        setUser(data);
      });
    }
    setFollow(isfollow);
  });
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModify = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setShowDelDialog(true);
  };
  const handleblock = () => {
    setAnchorEl(null);
    setblock(true);
  };
  const handleReport = () => {
    setAnchorEl(null);
    setreport(true);
  };
  return (
    <div className={styles.wrapper}>
      <UserAvatar
        user={userData?.createdBy}
        size={"50px"}
        innerImageSize="45px"
      />
      <div className={styles.userInfo}>
        <div
          className={styles.info}
          onClick={() => {
            history.push({
              pathname: "/view-profile",
              state: { userID: userData?.createdBy?._id },
            });
          }}
        >
          <div className={styles.infoTop}>
            <div className={styles.name}>{userData?.createdBy?.userName}</div>

            {userData?.createdBy?.isVerified && (
              <img src={verified} alt="verified" />
            )}
            <div className={styles.followers}>{userData?.createdBy?.level}</div>
            {createdAt && (
              <div className={styles.time}>
                {" "}
                - {moment(createdAt).fromNow(true)}
              </div>
            )}
          </div>
          <div className={styles.nickname}>{userData?.createdBy?.nickName}</div>
        </div>
        <div className={styles.options}>
          <img
            src={options}
            alt="options"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            // style={{ width: "190px" }}
          />
          {userData?.createdBy?._id === user?._id ? (
            <>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpenModify}>
                  {t("dashboard.modify")}
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  {t("dashboard.delete")}
                </MenuItem>
              </Menu>
              {openModal ? (
                <DialogBox
                  value={openModal}
                  setvalue={setOpenModal}
                  obj={userData}
                  t={t}
                />
              ) : (
                ""
              )}
              {ShowDelDialog ? (
                <DeletePost
                  value={ShowDelDialog}
                  setvalue={setShowDelDialog}
                  obj={userData}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleReport}>
                <Report className="icon" /> {t("setting.report")}
              </MenuItem>
              {report ? (
                <ReportDialogBox
                  value={report}
                  setvalue={setreport}
                  obj={userData}
                  type={"post"}
                />
              ) : (
                ""
              )}
              <MenuItem
                onClick={() => {
                  PostMuteUnmute(
                    () => {
                      setAnchorEl(null);
                      setMute(!ismute);
                    },
                    userData?._id,
                    {
                      mute: ismute ? false : true,
                    }
                  );
                }}
              >
                <Mute className="icon" />{" "}
                {ismute ? t("dashboard.unmute") : t("dashboard.mute")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  FollowPost(
                    () => {
                      setAnchorEl(null);
                      setFollow(!isfollow);
                    },
                    userData?._id,
                    {
                      follow: isfollow ? false : true,
                    }
                  );
                }}
              >
                <Follow className="icon" />
                {isfollow ? t("dashboard.unFollow") : t("dashboard.follow")}
              </MenuItem>
              <MenuItem onClick={handleblock}>
                <Block className="icon" /> {t("dashboard.block")}
              </MenuItem>

              {block ? (
                <BlockUserDialog
                  value={block}
                  setvalue={setblock}
                  obj={userData}
                />
              ) : (
                ""
              )}
            </Menu>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostUserInfo;
