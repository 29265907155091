import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "./DeleteAccount.module.scss";
import { DeleteUserAccount } from "../../../../../services/authService";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const DeleteAccount = ({
  deleteAccount,
  handleDeleteAccount,
  handleUpdateUser,
  t,
}) => {
  let history = useHistory();
  const [values, setValues] = useState({
    loading: false,
    error: false,
  });
  const handleSubmit = () => {
    setValues({ ...values, loading: true });
    DeleteUserAccount((res) => {
      if (res) {
        setValues({ ...values, loading: false, error: false });
        history.push("/");
      } else {
        setValues({ ...values, loading: false, error: true });
      }
    });
  };
  return (
    <div>
      <Dialog
        open={deleteAccount}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("setting.deleteAccount").toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <h3>{t("setting.sorryText")}</h3>
          <p className={styles.dummyText}>{t("setting.beforeText")}</p>
          <p className={styles.detail}>
            {t("setting.sickText")}
            <button
              className={styles.disablebutton}
              onClick={handleDeleteAccount}
            >
              {t("setting.disable")}
            </button>
            .
          </p>
          <p className={styles.detail}>
            {t("setting.changeUsername")}
            <button
              className={styles.disablebutton}
              onClick={() => {
                handleUpdateUser();
              }}
            >
              {t("setting.doText")}
            </button>
          </p>
          <p className={styles.detail}>{t("setting.finalText")}</p>
        </DialogContent>
        <button className={styles.keepbuttons} onClick={handleDeleteAccount}>
          {t("setting.neverTitle")}
        </button>
        <button className={styles.deletebuttons} onClick={handleSubmit}>
          {t("setting.deleteTitle")}
        </button>
      </Dialog>
      {values.error ? (
        <Snackbar
          open={values.error}
          autoHideDuration={4000}
          onClose={() => {
            setValues({ ...values, error: false });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => {
              setValues({ ...values, error: false });
            }}
            severity="error"
          >
            Something went wrong! Please try again
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      {values.loading ? (
        <center>
          <CircularProgress className={styles.progressBar} />
        </center>
      ) : null}
    </div>
  );
};
export default DeleteAccount;
