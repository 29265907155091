import React, { useState, useEffect } from "react";
import styles from "./Customize.module.scss";
import { useLocation } from "react-router-dom";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import coin from "../../../assets/images/coin.svg";
import verified from "../../../assets/images/verified.svg";
import CustomizeTabs from "./CustomizeTabs/CustomizeTabs";
import headerBg from "../../../assets/images/sample-profile-header.png";
import { useTranslation } from "react-i18next";
import { GetSingleUserProfile } from "../../../services";
const Customize = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const localUser = JSON.parse(localStorage.getItem("user"));
  const userData = location.state.userData;
  let userId = localUser?._id;
  console.log(localUser?._id);
  const [user, setUserData] = useState(userData);
  const [values, setValues] = useState({
    showFollowers: false,
    showFollowing: false,
    selectedTabType: "background",
  });
  const handleFollowers = () => {
    setValues({ ...values, showFollowers: false });
  };
  const handleFollowing = () => {
    setValues({ ...values, showFollowing: false });
  };
  useEffect(() => {
    GetSingleUserProfile((dta) => {
      setUserData(dta);
    }, userId);
  }, [userId]);
  return (
    <div>
      <div className={styles.wrapper}>
        <div
          className={styles.header}
          style={{
            backgroundImage: `url(${user?.cover || headerBg})`,
          }}
        >
          <div className={styles.headerContent}>
            <UserAvatar user={user} size={"150px"} innerImageSize={"135px"} />

            <div className={styles.levelBar}>
              <div className={styles.coins}>
                <div className={styles.coinIcon}>
                  <img src={coin} alt="coins" />
                </div>
                <div className={styles.coinText}>{user?.earnedCoins}</div>
              </div>
              <div className={styles.progressBar}></div>
              <div className={styles.xpPoints}>{user?.earnedXps}/100</div>
            </div>
            <div className={styles.userInfo}>
              <div className={styles.userDetails}>
                <div className={styles.nameWrapper}>
                  <div className={styles.username}>
                    {user?.firstName} {user?.lastName}
                  </div>
                  {user?.isVerified && <img src={verified} alt="verified" />}
                </div>
                <div
                  className={styles.nickname}
                  style={{ color: userData?.nickNameColor }}
                >
                  {user?.nickName}
                </div>
              </div>
              <div className={styles.level}>
                <div className={styles.levelHeading}>
                  {t("dashboard.level")}
                </div>
                <div className={styles.currentLevel}>{user?.level}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.customizeTab}>
        <CustomizeTabs userData={userData} />
      </div>
    </div>
  );
};

export default Customize;
