import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Navigation from "./components/common/Navigation/Navigation";
import Explore from "./components/Explore/Explore";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import Home from "./components/Home/Home";
import Profile from "./components/Profile/Profile";
import ReviewPage from "./components/Reviews/ReviewPage/ReviewPage";
import Reviews from "./components/Reviews/Reviews";
import SignUp from "./components/SignUp/SignUp";
import { setUser } from "./redux/reducers/userSlice";
import { getData } from "./utils/AppStorage";
import Interceptor from "./utils/Interceptor";
import Quest from "./components/quest/quest";
import CreatePost from "./components/create Post/createPost";
import EditProfile from "./components/Profile/EditProfile/EditProfile";
import SignIn from "./components/SignIn/SignIn";
import Setting from "./components/Profile/ProfileHeader/Settings/Setting";
import HelpCenter from "./components/Profile/ProfileHeader/Settings/HelpCenter/HelpCenter";
import NotificationsScreen from "./components/NotificationsScreen";
import { requestPushNotificationPermission } from "./services/PushNotifications/RequestPermissionsService";

import Messenger from "./components/Chat/Messenger";

import SavedPosts from "./components/Profile/ProfileHeader/Settings/SavedPosts/SavedPosts";
import DataPolicy from "./components/Profile/ProfileHeader/Settings/DataPolicy/DataPolicy";
import TermsOfUse from "./components/Profile/ProfileHeader/Settings/TermsOfUse/TermsOfUse";
import ReportProblem from "./components/Profile/ProfileHeader/Settings/ReportProblem/ReportProblem";
import Customize from "./components/Profile/Customize/Customize";
import Omegastore from "./components/Omegastore/Omegastore";
import LandingPage from "./components/LandingPage/LandingPage";
import { useHistory } from "react-router-dom";
import "@draft-js-plugins/mention/lib/plugin.css";
import ExploreId from "./components/Explore/ExploreId";
import Donation from "./components/Profile/ProfileHeader/Settings/donation";
import { useTranslation } from "react-i18next";
import { setItalian } from "./redux/reducers/translationSlice";
const App = () => {
  const { user, isItalian } = useSelector((state) => state.root);
  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation();
  // var historyPath = history?.location?.pathname;

  const setLanguage = () => {
    const italian = JSON.parse(localStorage.getItem("italian"));
    dispatch(setItalian(italian));
    if (italian) {
      i18n.changeLanguage("it");
    } else {
      i18n.changeLanguage("en");
    }
  };

  useEffect(() => {
    setLanguage();
    getData("user", (data) => {
      if (data) {
        dispatch(setUser(data));
        Interceptor.setToken(data?.token);
      }
    });
  }, []);

  const isAuthenticated = user?.token || false;
  const emailVerified = user?.emailVerified || false;

  if (isAuthenticated) {
    var PrivateRoute = (
      <React.Fragment>
        <Navigation />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          {/* <Route exact path="/sign-up">
            <SignUp />
          </Route> */}
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/forget-password">
            <ForgetPassword />
          </Route>
          <Route exact path="/explore">
            <Explore />
          </Route>
          <Route exact path="/explore/:id">
            <ExploreId />
          </Route>
          <Route exact path="/reviews">
            <Reviews />
          </Route>

          <Route exact path="/review-page">
            <ReviewPage />
          </Route>
          <Route exact path="/quests">
            <Quest />
          </Route>
          <Route exact path="/post">
            <CreatePost />
          </Route>
          <Route exact path="/setting">
            <Setting />
          </Route>
          <Route exact path="/edit-profile">
            <EditProfile />
          </Route>
          <Route exact path="/help-center">
            <HelpCenter />
          </Route>
          <Route exact path="/chat-screen">
            <Messenger />
          </Route>
          <Route exact path="/notifications">
            <NotificationsScreen />
          </Route>
          <Route exact path="/saved-posts">
            <SavedPosts />
          </Route>
          <Route exact path="/data-policy">
            <DataPolicy />
          </Route>
          <Route exact path="/terms-of-use">
            <TermsOfUse />
          </Route>
          <Route exact path="/report-problem">
            <ReportProblem />
          </Route>
          <Route exact path="/customize">
            <Customize />
          </Route>
          <Route exact path="/omega-store">
            <Omegastore />
          </Route>
          <Route exact path="/view-profile">
            <Profile />
          </Route>
          <Route exact path="/donations/:id">
            <Donation />
          </Route>
          <Redirect to="/" />
        </Switch>
      </React.Fragment>
    );
  } else {
    var PrivateRoute = (
      <React.Fragment>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/sign-in">
            <SignIn />
          </Route>
          <Route exact path="/donations/:id">
            <Donation />
          </Route>
          <Route exact path="/sign-up">
            <SignUp />
          </Route>
          <Route exact path="/forget-password">
            <ForgetPassword />
          </Route>
          <Route exact path="/terms-of-use">
            <TermsOfUse />
          </Route>
          <Route exact path="/data-policy">
            <DataPolicy />
          </Route>
          {/* <Redirect to="/" /> */}
        </Switch>
      </React.Fragment>
    );
  }
  return <>{PrivateRoute}</>;
};

export default App;
