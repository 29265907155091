import { createSlice } from "@reduxjs/toolkit";

const initState = "";
export const picUrlSlice = createSlice({
    name: "picUrl",
    initialState: initState,
    reducers: {
        setPicUrl: (state, action) => action.payload,

        resetPicUrl: () => initState,
    },
});

export const { setPicUrl, resetPicUrl } = picUrlSlice.actions;

export default picUrlSlice.reducer;