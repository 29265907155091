import React from "react";
import "./MessageList.css";

export default function EmptyChatComponent({ selected, t }) {
  return (
    <div className="message-list">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: 600,
        }}
      >
        <h2
          style={{
            color: "white",
            textAlign: "center",
          }}
        >
          {t("chat.selectChat")}
        </h2>
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "lighter",
            fontSize: 14,
          }}
        >
          {t("chat.p1")}
        </p>
      </div>
    </div>
  );
}
