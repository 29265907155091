import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHomeFeed } from "../../redux/reducers/homeFeedSlice";
import { GetHomeFeed } from "../../services";
import { GetCounterNumberOfNotifications } from "../../services/appSettingsService";
import { initSocket } from "../../services/socketService";
import PostListing from "../common/Posts/PostListing";
import styles from "../../styles/pages.module.scss";
import { Link } from "react-router-dom";
import Post from "../../assets/images/sample-post.png";
import { GetAllHashTags } from "../../services";
import "./homefeed.css";
let cursorArr = [];
const HomeFeed = ({}) => {
  const { user, homeFeed, comment, query } = useSelector((state) => state.root);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    loading: true,
    refreshing: false,
  });

  function getHomeFeedHelper(cursor) {
    if (!cursor) cursorArr = [];
    if (!cursorArr.includes(cursor)) {
      GetHomeFeed((res) => {
        if (res) {
          let newArr = cursor ? [...homeFeed, ...res] : res;
          let uniqueArr = [
            ...new Set(newArr.map((item) => (item?._id ? item : false))),
          ];
          dispatch(setHomeFeed(uniqueArr));
        }
        setState((prev) => ({ ...prev, loading: false, refreshing: false }));
      }, cursor);
    } else {
      setState((prev) => ({ ...prev, loading: false, refreshing: false }));
    }
    cursorArr.push(cursor);
  }

  useEffect(() => {
    initSocket(user.token);
    getHomeFeedHelper(false);
    GetCounterNumberOfNotifications();
    GetAllHashTags();
  }, [comment, query]);
  return (
    <div className={styles.wrapper}>
      {!state.loading && homeFeed && homeFeed.length > 0 ? (
        <PostListing data={homeFeed} />
      ) : (
        <div className="homeCard">
          <div>
            <img src={Post} height="200px" width="400px" />
            <p style={{ textAlign: "center" }}>No Posts Found</p>
            <Link to="/explore">
              <button className="btn-grad">Follow User</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeFeed;
