import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import user from "./reducers/userSlice";
import hashTags from "./reducers/hashTagsSlice";
import homeFeed from "./reducers/homeFeedSlice";
import savedPosts from "./reducers/savedPostsSlice";
import gameReviews from "./reducers/gameReviewsSlice";
import friends from "./reducers/friendsSlice";
import inbox from "./reducers/inboxSlice";
import myAssets from "./reducers/myAssetsSlice";
import settings from "./reducers/settingsSlice";
import notifications from "./reducers/notificationsSlice";
import postData from "./reducers/postDataSlice";
import userProfileData from "./reducers/userProfileDataSlice";
import query from "./reducers/querySlice";
import quests from "./reducers/questsSlice";
import comment from "./reducers/commentSlice";
import picUrl from "./reducers/picUrlSlice";
import count from "./reducers/countSlice";
import exploreFeed from "./reducers/exploreFeedSlice";
import commentList from "./reducers/listCommentSlice";
import isItalian from "./reducers/translationSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["hashTags", "user"],
};

const rootReducer = combineReducers({
  user: user,
  homeFeed: homeFeed,
  exploreFeed: exploreFeed,
  savedPosts: savedPosts,
  gameReviews: gameReviews,
  friends: friends,
  inbox: inbox,
  myAssets: myAssets,
  settings: settings,
  notifications: notifications,
  postData: postData,
  userProfileData: userProfileData,
  query: query,
  quests: quests,
  comment: comment,
  picUrl: picUrl,
  count: count,
  commentList: commentList,
  isItalian: isItalian,
  hashTags: hashTags,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: {
    root: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["register"],
        ignoredActionPaths: ["rehydrate", "register"],
        ignoredPaths: ["register"],
      },
    }),
});

const persistor = persistStore(store);
export { store, persistor };
