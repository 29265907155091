import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
export default function PrivateMenu({
  value,
  setvalue,
  privateStatus,
  setPrivateStatus,
}) {
  const { t } = useTranslation();
  const handleClose = (val) => {
    setvalue(null);
    setPrivateStatus(val);
  };
  const privacy = [
    { key: t("dashboard.public"), value: "PUBLIC" },
    { key: t("dashboard.onlyFriends"), value: "FRIENDS" },
    { key: t("dashboard.onlyMe"), value: "ONLY_ME" },
  ];
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={value}
        keepMounted
        open={Boolean(value)}
        onClose={handleClose}
      >
        {privacy.map((data, i) => {
          return (
            <MenuItem
              onClick={() => handleClose(data.value)}
              key={i}
              selected={data.key === privateStatus}
            >
              {data.key}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
