import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import styles from "./PersonalInformation.module.scss";
const PersonalInformation = ({ checkedPersonal, handleToggle, t }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  let data = user?.dateOfBirth;
  let dob = data?.slice(0, 10);
  return (
    <div>
      <Dialog
        open={checkedPersonal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("setting.personalInformation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("setting.personalText")}
          </DialogContentText>
          <p>{t("auth.username")}: </p>
          <p>{user?.userName}</p>
          <p> {t("auth.email")}:</p>
          <p>{user?.email}</p>
          <p>{t("setting.gender")}:</p>
          <p>{user?.gender}</p>
          <p>{t("setting.dateOfBirth")}:</p>
          <p>{dob}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle} className={styles.closeButton}>
            {t("setting.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PersonalInformation;
