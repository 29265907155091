import React from "react";
import defaultImage from "../../../assets/images/sample-media.png";

const GameAvatar = ({ source, size, rounded }) => {
  return (
    <img
      src={source || defaultImage}
      alt="profile image"
      style={{
        height: `${size || "120px"}`,
        width: `${size || "120px"}`,
        objectFit: "cover",
        borderRadius: `${rounded ? "50%" : "0"}`,
        border: "1px solid white",
      }}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src =
          "https://www.google.com/url?sa=i&url=https%3A%2F%2F1080motion.com%2Fjump-start-1080-map-montana%2F&psig=AOvVaw12njjKGoMBppgJExHapLg2&ust=1614856378346000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPChvvj-k-8CFQAAAAAdAAAAABAN";
      }}
    />
  );
};

export default GameAvatar;
