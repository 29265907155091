import { createSlice } from "@reduxjs/toolkit";
import { storeData } from "../../utils/AppStorage";

const initState = {
  tags: [],
};

export const hashTagsSlice = createSlice({
  name: "hashTags",
  initialState: initState,
  reducers: {
    setHashTags: (state, action) => {
      let tempObj = { ...state, ...action.payload };
      storeData("hashTags", tempObj);
      return tempObj;
    },
    resetHashTags: () => initState,
  },
});

export const { setHashTags, resetHashTags } = hashTagsSlice.actions;

export default hashTagsSlice.reducer;
