import React from "react";
import {
  DialogTitle,
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  Slide,
  Container,
  Grid,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Input,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
  InputLabel,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ActionsOnUsers } from "../../../services/profileService";
import { setComment } from "../../../redux/reducers/commentSlice";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import UserAvatar from "../UserAvatar/UserAvatar";
import { getData } from "../../../utils/AppStorage";
import { ReportIssueOrSpam } from "../../../services/reportService";
import { SendFeedback } from "../../../services/feedbackService";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// ------------------------------Material css---------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));
export default function ReportDialogBox({ value, setvalue, obj, type }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const styles = (theme) => ({
    root: {
      margin: 5,
      color: "gray",
      padding: theme.spacing(1),
      fontSize: "14px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(0),
      top: theme.spacing(-0.5),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  // ---------------------end material css --------------
  const dispatch = useDispatch();
  const [radioVal, setRadioVal] = React.useState(null);
  const [userProfile, setuserProfile] = React.useState(null);
  const [description, setdescription] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [response, setresponse] = React.useState("");
  // console.log("report", obj);
  React.useEffect(() => {
    getData("user", (data) => {
      if (data) {
        setuserProfile(data);
      }
    });
  }, []);

  const handleClose = () => {
    setvalue(false);
  };

  const handleSpam = () => {
    // userProfile?.userName
    console.log("delete id", description, type);
    if (type == "setting") {
      SendFeedback(
        {
          description: description,
        },
        (data) => {
          if (data) {
            setresponse("Thank you for your feedback!!");
            setTimeout(() => {
              setresponse("");
              setvalue(false);
            }, 3000);
          }
        }
      );
    } else if (type == "post") {
      var payload = {
        targetObjectId: obj?._id,
        targetEntity: type,
        reportType: "ABUSE_OR_SPAM",
        reportCase: radioVal,
        url: url,
        userName: obj?.createdBy?.userName,
        reporterEmail: userProfile?.email,
        description: description,
        model: "Post",
      };

      ReportIssueOrSpam(payload).then((res) => {
        setresponse("Thank you for your feedback!!");
        setTimeout(() => {
          setresponse("");
          setvalue(false);
        }, 3000);
      });
    } else if (type == "comment") {
      var payload = {
        targetObjectId: obj?._id,
        targetEntity: type,
        reportType: "ABUSE_OR_SPAM",
        reportCase: radioVal,
        url: url,
        userName: obj?.createdBy?.userName,
        reporterEmail: userProfile?.email,
        description: description,
        model: "Comment",
      };

      ReportIssueOrSpam(payload).then((res) => {
        setresponse("Thank you for your feedback!!");
        setTimeout(() => {
          setresponse("");
          setvalue(false);
        }, 3000);
      });
    } else if (type == "review") {
      var payload = {
        targetObjectId: obj?._id,
        targetEntity: type,
        reportType: "ABUSE_OR_SPAM",
        reportCase: radioVal,
        url: url,
        userName: obj?.createdBy?.userName,
        reporterEmail: userProfile?.email,
        description: description,
        model: "Review",
      };

      ReportIssueOrSpam(payload).then((res) => {
        setresponse("Thank you for your feedback!!");
        setTimeout(() => {
          setresponse("");
          setvalue(false);
        }, 3000);
      });
    } else if (type == "user") {
      var payload = {
        targetObjectId: obj?.profileId,
        targetEntity: type,
        reportType: "ABUSE_OR_SPAM",
        reportCase: radioVal,
        url: url,
        userName: obj?.userName,
        reporterEmail: userProfile?.email,
        description: description,
        model: "Profile",
      };

      ReportIssueOrSpam(payload).then((res) => {
        setresponse("Thank you for your feedback!!");
        setTimeout(() => {
          setresponse("");
          setvalue(false);
        }, 3000);
      });
    }

    // ReportIssueOrSpam;
  };

  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          open={value}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          disableBackdropClick
        >
          <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
            {t("setting.abuse").toUpperCase()}
          </DialogTitle>

          <DialogContent>
            <DialogContentText style={{ margin: "-10px" }}>
              <div>
                <div>
                  <div>
                    <Grid spacing={1}>
                      <Container>
                        <h3 style={{ color: "black", fontWeight: "500" }}>
                          {t("setting.abuseHeading1")}
                        </h3>
                        <p>{t("setting.abusePara")}</p>
                        <h3 style={{ color: "black", fontWeight: "500" }}>
                          {t("setting.abuseHeading2")}
                        </h3>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={radioVal}
                            onChange={(e) => setRadioVal(e.target.value)}
                          >
                            <FormControlLabel
                              value="HARASSMENT"
                              control={<Radio />}
                              label={t("setting.abuseText1")}
                            />
                            <FormControlLabel
                              value="POSTING_SPAM"
                              control={<Radio />}
                              label={t("setting.abuseText2")}
                            />
                            <FormControlLabel
                              value="HATE"
                              control={<Radio />}
                              label={t("setting.abuseText3")}
                            />
                            <FormControlLabel
                              value="HOMOPHOBIA"
                              control={<Radio />}
                              label={t("setting.abuseText4")}
                            />
                            <FormControlLabel
                              value="PRIVATE"
                              control={<Radio />}
                              label={t("setting.abuseText5")}
                            />
                          </RadioGroup>
                        </FormControl>

                        <FormControl
                          className={clsx(
                            classes.margin,
                            classes.withoutLabel,
                            classes.textField
                          )}
                          style={{ width: "inherit" }}
                        >
                          <Input
                            id="standard-adornment-weight"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder="http://"
                            aria-describedby="standard-weight-helper-text"
                          />
                        </FormControl>
                        {response !== "" ? (
                          <Alert severity="success">{response}</Alert>
                        ) : (
                          ""
                        )}
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-amount">
                            {t("setting.report").toUpperCase()}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            value={description}
                            onChange={(e) => {
                              setdescription(e.target.value);
                            }}
                            placeholder="Please provide as much detail as possible"
                            startAdornment={
                              <InputAdornment position="start">
                                <UserAvatar
                                  source={userProfile?.pic}
                                  size={"40px"}
                                />
                              </InputAdornment>
                            }
                            labelWidth={60}
                          />
                        </FormControl>
                      </Container>
                    </Grid>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Container>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "inherit" }}
                disabled={description === "" ? true : false}
                onClick={handleSpam}
              >
                {t("setting.report").toUpperCase()}
              </Button>
            </Container>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
