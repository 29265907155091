import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetExploreMediaOnlyPosts } from "../../services";
import { RemoveDuplicateObjectsFromArray } from "../../utils/AppHelperMethods";
import PostGridListing from "../common/Posts/PostGridListing";
import "../../styles/loader.css";
import { ToastContainer, toast } from "react-toastify";
import { getData } from "../../utils/AppStorage";

let enableReset = true;
let cursorArrMedia = [];
const ExploreMedia = () => {
  const { query, comment } = useSelector((state) => state.root);
  const [state, setState] = useState({
    loading: true,
    loadingMedia: true,
    refreshingMedia: false,
    mediaPosts: [],
  });

  function getexploremediaonlypostshelper(cursor, searchQuery) {
    if (!cursor) cursorArrMedia = [];
    getData("search", (searchvalue) => {
      if (searchvalue !== "") {
        searchQuery = searchvalue ? "&search=" + searchvalue : "";
        console.log("inner if", searchQuery);
      } else {
        searchQuery = "";
        console.log("else", searchQuery);
      }
    });
    if (!cursorArrMedia.includes(cursor)) {
      GetExploreMediaOnlyPosts(
        (postResponse) => {
          if (postResponse.data && (postResponse.data.length || enableReset)) {
            let tempData = postResponse.data;
            setState((prev) => ({
              ...prev,
              loadingMedia: false,
              refreshingMedia: false,
              loading: false,
              mediaPosts: RemoveDuplicateObjectsFromArray(tempData),
            }));
          } else {
            setState((prev) => ({
              ...prev,
              loadingMedia: false,
              refreshingMedia: false,
              loading: false,
            }));
            toast.error(postResponse?.message);
          }
        },
        cursor,
        searchQuery
      );
      cursorArrMedia.push(cursor);
    } else {
      setState((prev) => ({ ...prev, refreshingMedia: false }));
    }
  }

  useEffect(() => {
    enableReset = true;
    cursorArrMedia = [];
    getexploremediaonlypostshelper(false, query);
  }, [query, comment]);
  // console.log("media post", state.mediaPosts);
  return (
    <React.Fragment>
      {state.loading ? (
        <div className="loader"></div>
      ) : state.mediaPosts.length > 0 ? (
        <PostGridListing data={state.mediaPosts} />
      ) : (
        <>
          No Result Found
          <ToastContainer />
        </>
      )}
    </React.Fragment>
  );
};

export default ExploreMedia;
