import React, { useState } from "react";
import {
  DialogTitle,
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  Slide,
  Grid,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { DeletePosts } from "../../../services/postService";
import { setComment } from "../../../redux/reducers/commentSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileData } from "../../../redux/reducers/userProfileDataSlice";
import { DeletePostExploreStore } from "../../../services/mutateReduxState";
import ShowError from "../../error/ShowError";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DeletePost({ value, setvalue, obj }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [state,setstate] = useState({
    toggleBool:false,
    msg:""
  })
  const dispatch = useDispatch();
  let { userProfileData } = useSelector((state) => state.root);
  const handleClose = () => {
    setvalue(false);
  };
  const handleDelete = () => {
    DeletePosts((data) => {
      if (data) {
        setstate({
          ...state, 
          toggleBool:true,
          msg:"Post deleted successfully"

        })
        // setvalue(false);
        DeletePostExploreStore(obj._id);
        dispatch(setComment({}));
        let updatedPosts = userProfileData.posts.filter(
          (post) => post._id !== obj._id
        );
        dispatch(setUserProfileData({ posts: updatedPosts }));
      }
    }, obj?._id);
  };

  return (
    <>
      <div>
        {
          state.toggleBool ? <ShowError open={state.toggleBool} handleClose={setstate} severity={"Success"} message={state.msg} />:""
        }
        <Dialog
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          open={value}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          disableBackdropClick
        >
          <DialogContent>
            <DialogContentText>
              <div>
                <div>
                  <div>
                    <div>
                      <Grid container spacing={2} alignItems="flex-end">
                        <p>Are you sure want to delete the Post!!</p>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleDelete}>
              Yes
            </Button>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    </>
  );
}
