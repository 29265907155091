import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileData } from "../../redux/reducers/userProfileDataSlice";
import { GetMediaOnlyPosts } from "../../services";
import styles from "../../styles/pages.module.scss";
import PostGridListing from "../common/Posts/PostGridListing";
import "../../styles/loader.css";
const UserProfileMedia = ({ userID }) => {
  let { userProfileData } = useSelector((state) => state.root);
  let dispatch = useDispatch();

  let [state, setState] = useState({
    loading: true,
  });

  useEffect(() => {
    GetMediaOnlyPosts((userPosts) => {
      if (userPosts) dispatch(setUserProfileData({ media: userPosts }));
      setState((prev) => ({ ...prev, loading: false }));
    }, userID);
  }, []);
  return (
    <div>
      {state.loading ? (
        <div className="loader"></div>
      ) : (
        <div className={styles.wrapper}>
          <PostGridListing data={userProfileData.media} />
        </div>
      )}
    </div>
  );
};

export default UserProfileMedia;
