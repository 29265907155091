import React, { useState } from "react";
import styles from "./EditProfile.module.scss";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { styled } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useHistory } from "react-router-dom";
import media from "../../../assets/images/profile-media.svg";
import { UploadMedia } from "../../../services/mediaUploader";
import "../../../styles/loader.css";
import { UpdateProfile } from "../../../services/profileService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";

//-------------------------------- Material-ui styling start ---------------------------
const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "2px solid #808080",
        },
        "&:hover:before": {
          borderColor: "#808080",
        },
        "&:after": {
          borderBottom: "2px solid #808080",
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#808080",
      light: "#808080",
      dark: "#808080",
    },
    secondary: {
      main: "#808080",
    },
  },
});
const styles1 = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const MySelect = styled(Select)({
  root: {
    color: "#808080",
    ".MuiSelect-icon": {
      color: "#fff", // for icon drop down icon color
    },
  },
  color: "#808080",
});

//-------------------------------- Material-ui styling end ---------------------------

const EditProfile = () => {
  let history = useHistory();
  const location = useLocation();
  let userData = location.state.userData;
  const [user, setUser] = useState(location.state.userData);
  const [consolesList, setConsolesList] = useState(
    userData?.ownedConsoles ? userData?.ownedConsoles : []
  );
  const [singleConsole, setSingleConsole] = useState("");

  let userName = JSON.parse(localStorage.getItem("user"));
  //Slice prefix from picture url
  let pic = userData?.pic;
  let slicedUrl = pic?.slice(52);

  const myclass = styles1();
  let gac = [];
  if (userData?.gamingAccounts && userData?.gamingAccounts.length > 0) {
    ["XBOX", "PSN", "STREAM", "NINTENDO"].forEach((game) => {
      let check = userData?.gamingAccounts.filter(
        (g) => g.gamingAccountProvider == game
      );
      if (check.length > 0) {
        gac.push(check[0]);
      } else {
        gac.push({ gamingAccountProvider: game, account: "" });
      }
    });
  } else {
    gac = [
      { gamingAccountProvider: "XBOX", account: "" },
      { gamingAccountProvider: "PSN", account: "" },
      { gamingAccountProvider: "STREAM", account: "" },
      { gamingAccountProvider: "NINTENDO", account: "" },
    ];
  }

  const [state, setState] = useState({
    openModal: false,
    email: userData ? userData.email : "",
    file: "",
    userName: userName.userName,
    favoriteGame: userData ? userData.favouriteGame : "",
    FavouriteVideogameGenre: userData ? userData.FavouriteVideogameGenre : "",
    bio: userData ? userData.bio : "",
    xbox: gac[0].account,
    favoriteConsole: userData ? userData.favouriteConsole : "",
    nintendo: gac[3].account,
    dob: userData ? userData.dateOfBirth : new Date("2015-08-18T21:11:54"),
    psn: gac[1].account,
    stream: gac[2].account,
    gender: userData ? userData.gender : "",
    loading: false,
    picUrlServer: userData ? userData.pic : "",
    error: false,
    errorMsg: "",
    age: 15,
    ownedConsoles: userData?.ownedConsoles ? userData?.ownedConsoles : [],
    name: userData
      ? userData.firstName
        ? userData.firstName + " " + userData?.lastName
        : ""
      : "",
  });

  const handleFileUpload = (event) => {
    setState((prev) => ({
      ...prev,
      file: event.target.files[0],
      picUrlServer: URL.createObjectURL(event.target.files[0]),
    }));
  };
  //-----------------Api call to update the profile ------------------------
  async function updateProfileHandler(formData) {
    return new Promise((resolve, reject) => {
      UpdateProfile((res) => {
        if (res) {
          setState({ ...state, loading: false });
          localStorage.removeItem("url");
          resolve(true);
        } else {
          setState({ ...state, error: true, loading: false });
          resolve(false);
        }
      }, formData);
    });
  }
  let gAccount = [];
  const handleSubmit = (event) => {
    event.preventDefault();
    let fullName = state.name.split(" ");
    setState({ ...state, loading: true });
    if (state.file !== "") {
      //---------------- if user try to update his/her profile with by uploading picture -------------------//
      UploadMedia(
        (res) => {
          if (res) {
            setState({
              ...state,
              picUrlServer: res.data.data.media[0].url,
              loading: false,
            });
            if (state.age > 14) {
              localStorage.removeItem("url");
              localStorage.setItem("url", res.data.data.media[0].url);
              let formData = {};
              if (state.bio) {
                formData = { ...formData, bio: state.bio };
              }
              if (state.dob) {
                formData = { ...formData, dateOfBirth: state.dob };
              }
              if (state.favoriteConsole) {
                formData = {
                  ...formData,
                  favouriteConsole: state.favoriteConsole,
                };
              }
              if (state.ownedConsoles) {
                formData = { ...formData, ownedConsoles: state.ownedConsoles };
              }
              if (state.favoriteGame) {
                formData = { ...formData, favouriteGame: state.favoriteGame };
              }
              if (state.FavouriteVideogameGenre) {
                formData = {
                  ...formData,
                  FavouriteVideogameGenre: state.FavouriteVideogameGenre,
                };
              }
              if (fullName[0]) {
                formData = { ...formData, firstName: fullName[0] };
              }
              if (state.xbox) {
                gAccount.push({
                  gamingAccountProvider: "XBOX",
                  account: state.xbox,
                });
                formData = {
                  ...formData,
                  gamingAccounts: gAccount,
                };
              }
              if (state.psn) {
                gAccount.push({
                  gamingAccountProvider: "PSN",
                  account: state.psn,
                });
                formData = {
                  ...formData,
                  gamingAccounts: gAccount,
                };
              }
              if (state.stream) {
                gAccount.push({
                  gamingAccountProvider: "STREAM",
                  account: state.stream,
                });
                formData = {
                  ...formData,
                  gamingAccounts: gAccount,
                };
              }
              if (state.nintendo) {
                if (state.nintendo) {
                  gAccount.push({
                    gamingAccountProvider: "NINTENDO",
                    account: state.nintendo,
                  });
                  formData = {
                    ...formData,
                    gamingAccounts: gAccount,
                  };
                }
              }
              if (state.gender) {
                formData = { ...formData, gender: state.gender };
              }
              if (fullName[1]) {
                formData = {
                  ...formData,
                  lastName: fullName[1],
                };
              }
              if (localStorage.getItem("url")) {
                formData = { ...formData, pic: localStorage.getItem("url") };
              }

              let response = updateProfileHandler(formData)
                .then((res) => {
                  if (res) {
                    history.push("/profile");
                  } else {
                    setState({ ...state, error: true, loading: false });
                  }
                })
                .catch((err) => {
                  setState({ ...state, error: true, loading: false });
                });
            } else {
              setState({
                ...state,
                error: true,
                loading: false,
                errorMsg: "Age must be greater than 14",
              });
            }
          } else {
            setState({ ...state, error: true, loading: false });
          }
        },
        "PROFILE",
        state.file
      );
    } else {
      //---------------- if user try to update his/her profile with already uploaded picture Picture -------------------//
      if (state.age > 14) {
        localStorage.removeItem("url");
        localStorage.setItem("url", slicedUrl);
        let formData = {};
        if (state.bio) {
          formData = { ...formData, bio: state.bio };
        }
        if (state.dob) {
          formData = { ...formData, dateOfBirth: state.dob };
        }
        if (state.favoriteConsole) {
          formData = { ...formData, favouriteConsole: state.favoriteConsole };
        }
        if (state.ownedConsoles) {
          formData = { ...formData, ownedConsoles: state.ownedConsoles };
        }
        if (state.favoriteGame) {
          formData = { ...formData, favouriteGame: state.favoriteGame };
        }
        if (state.FavouriteVideogameGenre) {
          formData = {
            ...formData,
            FavouriteVideogameGenre: state.FavouriteVideogameGenre,
          };
        }
        if (fullName[0]) {
          formData = { ...formData, firstName: fullName[0] };
        }
        if (state.xbox) {
          gAccount.push({ gamingAccountProvider: "XBOX", account: state.xbox });
          formData = {
            ...formData,
            gamingAccounts: gAccount,
          };
        }
        if (state.psn) {
          gAccount.push({ gamingAccountProvider: "PSN", account: state.psn });
          formData = {
            ...formData,
            gamingAccounts: gAccount,
          };
        }
        if (state.stream) {
          gAccount.push({
            gamingAccountProvider: "STREAM",
            account: state.stream,
          });
          formData = {
            ...formData,
            gamingAccounts: gAccount,
          };
        }
        if (state.nintendo) {
          gAccount.push({
            gamingAccountProvider: "NINTENDO",
            account: state.nintendo,
          });
          formData = {
            ...formData,
            gamingAccounts: gAccount,
          };
        }
        if (state.gender) {
          formData = { ...formData, gender: state.gender };
        }
        if (fullName[1]) {
          formData = { ...formData, lastName: fullName[1] };
        }
        if (localStorage.getItem("url")) {
          formData = { ...formData, pic: localStorage.getItem("url") };
        }
        let response = updateProfileHandler(formData)
          .then((res) => {
            if (res) {
              history.push("/profile");
            } else {
              setState({ ...state, error: true, loading: false });
            }
          })
          .catch((err) => {
            setState({ ...state, error: true, loading: false });
          });
      } else {
        setState({
          ...state,
          error: true,
          loading: false,
          errorMsg: "Age must be greater than 14",
        });
      }
    }
  };

  //--------------------------handleSubmit End --------------------------/

  //--------------------------handle Owned Console List--------------------------/
  const handleConsoleList = () => {
    let obj = [...consolesList];
    if (obj.find((str) => str === singleConsole)) {
      setState({
        ...state,
        error: true,
        loading: false,
        errorMsg: "This Console is Already Added",
      });
    } else {
      if (singleConsole != "") {
        obj.push(singleConsole);
        setConsolesList(obj);
        setSingleConsole("");
        setState((prev) => ({
          ...prev,
          ownedConsoles: obj,
        }));
      } else {
        setState({
          ...state,
          error: true,
          loading: false,
          errorMsg: "Please Write Something",
        });
      }
    }
  };

  const removeConsole = (item) => {
    let obj = [...consolesList];
    var filtered = obj.filter(function (value, index, arr) {
      return value != item;
    });
    setConsolesList(filtered);
    setState((prev) => ({
      ...prev,
      ownedConsoles: filtered,
    }));
  };
  //-------------------------- Owned Console List End--------------------------/

  //-------------------------- Age Check --------------------------/

  const handleAge = (date) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age > 14) {
      setState((prev) => ({ ...prev, dob: date, age: age }));
    } else {
      setState({
        ...state,
        age: age,
        error: true,
        loading: false,
        errorMsg: "Age must be greater than 14",
      });
    }
  };

  //-------------------------- Age Check End--------------------------/

  return (
    <React.Fragment>
      {state.loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <div>
          <MuiThemeProvider theme={theme}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <div>
                  <Button
                    className={styles.backButton}
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    <ArrowBackIcon className={styles.iconStyle} />
                  </Button>
                </div>
                <Grid item xs={12} className={myclass.paper}>
                  <div className={styles.userAvatar}>
                    <div className={styles.parent}>
                      {/*---------if User has selected corners around the image------------*/}

                      {user?.corner ? (
                        <div>
                          <div className={styles.image1}>
                            <UserAvatar
                              source={userData?.corner}
                              size={"200px"}
                            />
                          </div>
                          <div className={styles.image2}>
                            <UserAvatar
                              source={state?.picUrlServer}
                              size={"150px"}
                            />
                            <Button
                              variant="contained"
                              component="label"
                              style={{
                                marginLeft: "-60px",
                                background: "none",
                                outline: "none",
                              }}
                            >
                              <img src={media} alt="media" />
                              <input
                                type="file"
                                // accept="image/*"
                                hidden
                                onChange={handleFileUpload}
                              />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.image3}>
                          {/*---------if User has not selected corners around the image------------*/}

                          <UserAvatar
                            source={state?.picUrlServer}
                            size={"150px"}
                          />
                          <Button
                            variant="contained"
                            component="label"
                            style={{
                              marginLeft: "-60px",
                              background: "none",
                              outline: "none",
                            }}
                          >
                            <img src={media} alt="media" />
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={handleFileUpload}
                            />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    id="standard-basic"
                    label="Username"
                    color="primary"
                    value={state.userName}
                    className={styles.divTextField}
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        userName: e.target.value,
                      }))
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    id="standard-basic"
                    label="Name"
                    value={state.name}
                    color="primary"
                    className={styles.divTextField}
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Date of Birth"
                      format="MM/dd/yyyy"
                      value={state.dob}
                      className={styles.divTextField}
                      inputProps={{ style: { color: "#808080" } }}
                      InputLabelProps={{
                        style: { color: "#808080" },
                      }}
                      keyboardIcon={<DateRangeIcon color="primary" />}
                      onChange={(date) => {
                        handleAge(date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={6} className={myclass.paper}>
                  <FormControl className={styles.divTextField}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ color: "#808080" }}
                    >
                      Gender
                    </InputLabel>
                    <MySelect
                      labelId="demo-simple-select-label"
                      label="Gender"
                      id="demo-simple-select"
                      value={state.gender}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          gender: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={"He/Him"}>He/Him</MenuItem>
                      <MenuItem value={"She/Her"}>She/Her</MenuItem>
                      <MenuItem value={"They/Them"}>They/Them</MenuItem>
                    </MySelect>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    id="standard-basic"
                    label="Favorite Game"
                    value={state.favoriteGame}
                    color="primary"
                    className={styles.divTextField}
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        favoriteGame: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    id="standard-basic"
                    label="Favorite Game Genre"
                    value={state.FavouriteVideogameGenre}
                    color="primary"
                    className={styles.divTextField}
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        FavouriteVideogameGenre: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    className={styles.divTextField}
                    label="Favorite Console"
                    inputProps={{ style: { color: "#808080" } }}
                    value={state.favoriteConsole}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        favoriteConsole: e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    id="standard-basic"
                    label="XBOX"
                    color="primary"
                    className={styles.divTextField}
                    value={state.xbox}
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        xbox: e.target.value,
                      }))
                    }
                  />
                </Grid>

                {/* <Grid item xs={6} className={myclass.paper}>
                  <FormControl className={styles.divTextField}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ color: "#808080" }}
                    >
                      Gender
                    </InputLabel>
                    <MySelect
                      labelId="demo-simple-select-label"
                      label="Gender"
                      id="demo-simple-select"
                      value={state.gender}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          gender: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={"MALE"}>Male</MenuItem>
                      <MenuItem value={"FEMALE"}>Female</MenuItem>
                      <MenuItem
                        value={"Rather not say"}
                        className={styles.divTextField}
                      >
                        Rather not say
                      </MenuItem>
                    </MySelect>
                  </FormControl>
                </Grid> */}
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    label="PSN"
                    value={state.psn}
                    className={styles.divTextField}
                    inputProps={{
                      style: { color: "#808080", borderColor: "#808080" },
                    }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, psn: e.target.value }))
                    }
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    label="NINTENDO"
                    className={styles.divTextField}
                    value={state.nintendo}
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        nintendo: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <TextField
                    id="standard-basic"
                    value={state.stream}
                    label="STEAM"
                    color="primary"
                    inputProps={{ style: { color: "#808080" } }}
                    className={styles.divTextField}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, stream: e.target.value }))
                    }
                  />
                </Grid>
                <Grid item xs={6} className={myclass.paper}>
                  <div>
                    <TextField
                      id="standard-basic"
                      value={singleConsole}
                      label="Owned Consoles"
                      color="primary"
                      inputProps={{ style: { color: "#808080" } }}
                      className={styles.divTextField2}
                      InputLabelProps={{
                        style: { color: "#808080" },
                      }}
                      onChange={(e) => setSingleConsole(e.target.value)}
                      // onChange={(e) =>
                      //   setState((prev) => ({ ...prev, stream: e.target.value }))
                      // }
                    />
                    <AddBoxIcon
                      className={styles.addIcon}
                      onClick={handleConsoleList}
                    />
                  </div>
                  <div className={styles.listItemContainer}>
                    {consolesList?.map((item, key) => {
                      return (
                        <div className={styles.listItem} key={key}>
                          <span style={{ marginRight: "20px" }}>{item}</span>
                          <CloseIcon
                            className={styles.crossIcon}
                            onClick={() => removeConsole(item)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Grid>
                <Grid item xs={12} className={myclass.paper}>
                  <TextField
                    id="standard-basic"
                    label="Bio"
                    value={state.bio}
                    color="primary"
                    style={{ width: "77%" }}
                    className={styles.divTextField}
                    multiline
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, bio: e.target.value }))
                    }
                  />
                </Grid>
                <Grid item xs={12} className={myclass.paper}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    className={styles.submitbutton}
                  >
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </form>
          </MuiThemeProvider>
          {state.error ? (
            <Snackbar
              open={state.error}
              autoHideDuration={4000}
              onClose={() => {
                setState({ ...state, error: false });
              }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={() => {
                  setState({ ...state, error: false });
                }}
                severity="error"
              >
                {state.errorMsg
                  ? state.errorMsg
                  : "Could not be able to submit the form!"}
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default EditProfile;
