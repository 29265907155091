import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import cart from "../../../assets/images/buy.svg";
import { setGameReviews } from "../../../redux/reducers/gameReviewsSlice";
import { GetGameReviews } from "../../../services";
import GameAvatar from "../../common/GameAvatar/GameAvatar";
import UserReview from "../../common/UserReview/UserReview";
import styles from "./ReviewPage.module.scss";
import RateGameDialog from "../../common/dialogbox/RateGameDialog";
import { DeleteGameReview } from "../../../services/gamesService";
import ShowError from "../..//error/ShowError";
import { useTranslation } from "react-i18next";
const ReviewPage = ({}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  /*
  GET parse gameData from previous screen, when you click on a game and comes to this screen
  */
  let gameData = location.state.data;
  const { gameReviews } = useSelector((state) => state.root);
  const [gameReviewsData, setGameReviewsData] = useState(gameReviews);
  const { user } = useSelector((state) => state.root);
  let [state, setState] = useState({
    loading: true,
    selectedSortType: "RECENT",
    showBuyModal: false,
    toggleRateDialog: false,
  });
  const [values, setValues] = useState({
    error: false,
    message: "",
    severity: "",
  });
  const [reload, setReload] = useState(0);
  const [alreadRated, setRated] = useState(false);
  const handleReload = () => {
    setReload(reload + 2);
  };

  function getgamereviewshelper(cursor, filter) {
    GetGameReviews(
      (reviewsRes) => {
        if (reviewsRes) {
          dispatch(setGameReviews(reviewsRes));
          setGameReviewsData(reviewsRes);
          setState((prev) => ({ ...prev, loading: false }));
        } else {
          setState((prev) => ({ ...prev, loading: false }));
        }
      },
      0,
      filter,
      gameData._id
    );
  }

  useEffect(() => {
    getgamereviewshelper(0, "RECENT");
  }, [reload]);
  const handleDeleteReview = (id) => {
    DeleteGameReview((res) => {
      if (res) {
        let newArray = gameReviewsData.filter((res) => res._id !== id);
        setGameReviewsData(newArray);
        dispatch(setGameReviews(newArray));
        setValues({
          ...values,
          error: !values.error,
          message: "You have successfully deleted the review!",
          severity: "success",
        });
      } else {
        setValues({
          ...values,
          error: !values.error,
          message: "Something went wrong, Please try again",
          severity: "error",
        });
      }
    }, id);
  };
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.header}
        style={{
          backgroundImage: `url(${gameData?.background?.url})`,
        }}
      >
        <div>
          <div className={styles.buy}>
            <div className={styles.icon}>
              <img src={cart} alt="cart" />
            </div>
            <div className={styles.buyText}>
              <a href={gameData?.referralLink} target="__blank">
                {" "}
                {t("review.buyNow")}
              </a>
            </div>
          </div>
          <div className={styles.headerContent}>
            <GameAvatar
              source={gameData?.profile?.url}
              size={"150px"}
              rounded
            />
            <div className={styles.itemInfo}>
              <div className={styles.itemDetails}>
                <div className={styles.itemName}>{gameData.name}</div>
                <div className={styles.itemType}>
                  {gameData.supportedDevices?.map((ii) =>
                    (ii + ", ").toUpperCase()
                  ) || gameData.devices?.map((ii) => (ii + ", ").toUpperCase())}
                </div>
                <div className={styles.itemDate}>
                  {t("review.releaseDate")}:{" "}
                  {moment(gameData.releaseDate).format("DD MMMM YYYY")}
                </div>
              </div>
              <div
                className={` ${styles.itemRating}  ${
                  gameData?.computed != undefined &&
                  gameData?.computed[0]?.value / gameData?.computed[1]?.value >
                    5
                    ? styles.rating_success
                    : styles.rating_danger
                }  `}
              >
                <div className={styles.ratingHeading}>
                  {t("review.ratings")}
                </div>
                <div className={` ${styles.avgRating}`}>
                  {gameData?.computed
                    ? (
                        (gameData?.computed[0]?.value || 0) /
                        (gameData?.computed[1]?.value || 1)
                      )?.toFixed(2)
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.description}>{gameData?.description}</div>
      {/* <div className={styles.price}>
        {t("review.suggestedPrice")}: {gameData?.price}$
      </div> */}
      {alreadRated ? <p style={{ color: "red" }}>{t("review.p0")}</p> : null}
      <div className={styles.rate}>
        <button
          onClick={() => {
            let findReview = gameReviewsData.filter(
              (res) => res?.createdBy?._id === user?._id
            );
            if (findReview.length > 0) {
              setRated(true);
              setTimeout(() => {
                setRated(false);
              }, 2000);
            } else {
              setState({
                ...state,
                toggleRateDialog: !state.toggleRateDialog,
              });
            }
          }}
        >
          {t("review.rateGame")}
        </button>
      </div>
      {state.toggleRateDialog ? (
        <RateGameDialog
          value={state}
          setval={setState}
          response={gameData}
          handleReload={handleReload}
          t={t}
        />
      ) : (
        ""
      )}
      <div className={styles.filterRow}>
        <div
          className={`${styles.filter} ${
            state.selectedSortType === "RECENT" && styles.filterActive
          }`}
          onClick={() => {
            getgamereviewshelper(0, "RECENT");
            setState((prev) => ({
              ...prev,
              loading: true,
              selectedSortType: "RECENT",
            }));
          }}
        >
          {t("review.recent")}
        </div>
        <div
          className={`${styles.filter} ${
            state.selectedSortType === "LOWEST" && styles.filterActive
          }`}
          onClick={() => {
            getgamereviewshelper(0, "LOWEST");
            setState((prev) => ({
              ...prev,
              loading: true,
              selectedSortType: "LOWEST",
            }));
          }}
        >
          {t("review.lowest")}
        </div>
        <div
          className={`${styles.filter} ${
            state.selectedSortType === "HIGHEST" && styles.filterActive
          }`}
          onClick={() => {
            getgamereviewshelper(0, "HIGHEST");
            setState((prev) => ({
              ...prev,
              loading: true,
              selectedSortType: "HIGHEST",
            }));
          }}
        >
          {t("review.highest")}
        </div>
      </div>
      {gameReviewsData?.map((item, index) => {
        return (
          <UserReview
            profile={item}
            handleDeleteReview={handleDeleteReview}
            key={index}
            user={user}
            handleReload={handleReload}
            t={t}
          />
        );
      })}
      {values.error ? (
        <ShowError
          open={values.error}
          message={values.message}
          severity={values.severity}
          handleClose={() => {
            setValues({ ...values, error: !values.error });
          }}
        />
      ) : null}
      {/* <UserReview game /> */}
    </div>
  );
};

export default ReviewPage;
