import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styles from "./ChangePassword.module.scss";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ChangeUserPassword } from "../../../../../services/authService";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "70%",
  },
  paper: { minWidth: "40%" },
}));
const ChangePassword = ({ handleChangePassword, changePassword, t }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmed: false,
    showNew: false,
    error: false,
    loading: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = () => {
    if (values.oldPassword) {
      if (values.newPassword) {
        if (values.confirmPassword) {
          if (values.newPassword === values.confirmPassword) {
            const formData = {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            };
            ChangeUserPassword((res) => {
              if (res) {
                setValues({ ...values, loading: false });
                handleChangePassword();
              } else {
                setValues({ ...values, error: true });
              }
            }, formData);
            setValues({ ...values, error: false });
          } else {
            setValues({ ...values, error: true });
          }
        } else {
          setValues({ ...values, error: true });
        }
      } else {
        setValues({ ...values, error: true });
      }
    } else {
      setValues({ ...values, error: true });
    }
  };
  return (
    <div>
      <Dialog
        open={changePassword}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title">
          {t("setting.changePasswordC")}
        </DialogTitle>
        <center>
          <DialogContent>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                {t("setting.currentPassword")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.oldPassword || ""}
                onChange={handleChange("oldPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <br />
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                {t("setting.newPassword")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showNew ? "text" : "password"}
                value={values.newPassword || ""}
                onChange={handleChange("newPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(event) => {
                        setValues({ ...values, showNew: !values.showNew });
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showNew ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <br />
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                {t("auth.confirmPassword")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showConfirmed ? "text" : "password"}
                value={values.confirmPassword || ""}
                onChange={handleChange("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(event) => {
                        setValues({
                          ...values,
                          showConfirmed: !values.showConfirmed,
                        });
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showConfirmed ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </DialogContent>
        </center>
        {values.error ? (
          <Snackbar
            open={values.error}
            autoHideDuration={4000}
            onClose={() => {
              setValues({ ...values, error: false });
            }}
          >
            <Alert
              onClose={() => {
                setValues({ ...values, error: false });
              }}
              severity="error"
            >
              Please enter old password, same new and confirm Password!
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}
        {values.loading ? (
          <center>
            <CircularProgress className={styles.progressBar} />
          </center>
        ) : null}
        <div className={styles.footer}>
          <button className={styles.cancel} onClick={handleChangePassword}>
            {t("setting.cancel")}
          </button>
          <button className={styles.submit} onClick={handleSubmit}>
            {t("setting.submit")}
          </button>
        </div>
      </Dialog>
    </div>
  );
};
export default ChangePassword;
