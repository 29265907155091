import React, { useEffect, useState } from "react";
import styles from "./Nickname.module.scss";
import { getData } from "../../../../../utils/AppStorage";
const NicknameBox = ({ source, handleModalData, color }) => {
  const [user, setUser] = useState("");
  useEffect(() => {
    getData("user", (data) => {
      if (data) {
        setUser(data);
        // console.log(data);
      }
    });
  });
  return (
    <>
      {user.nickName !== source.nickName ? (
        <div
          className={styles.parent}
          onClick={() => {
            handleModalData(source);
          }}
        >
          <div style={{ color: color }}>{source.nickName}</div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default NicknameBox;
