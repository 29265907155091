import { JSONBodyHelper } from ".";
import { EndPoints } from "../utils/AppEndpoints";
import Interceptor from "../utils/Interceptor";

async function ReportIssueOrSpam(PAYLOAD) {
  return await fetch(`${EndPoints.REPORT}`, {
    method: "POST",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(PAYLOAD),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      if (status === 201 || status === 200) {
        return data;
      } else return false;
    })
    .catch((error) => {
      return false;
    });
}

export { ReportIssueOrSpam };
