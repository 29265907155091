import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetGamesList } from "../../services";
import styles from "../../styles/pages.module.scss";
import ReviewItem from "./ReviewItem/ReviewItem";
import "../../styles/loader.css";
import { useTranslation } from "react-i18next";
const Reviews = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    searchTerm: "",
    offset: 0,
    showFilter: false,
    visibleFilter: "",
    selectedConsoleTypes: [],
    selectedGenreTypes: [],
    releaseDate: "All time",
    data: [],
  });
  function getgameshelper(offset, query) {
    GetGamesList(
      (gamesRes) => {
        if (gamesRes) {
          setState((prev) => ({ ...prev, loading: false, data: gamesRes }));
        } else {
          setState((prev) => ({ ...prev, loading: false }));
        }
      },
      offset,
      query
    );
  }

  useEffect(() => {
    getgameshelper(false, {});
  }, []);
  const { data, loading } = state;
  console.log("data", data);
  return (
    <div className={styles.wrapper}>
      {data.length > 0 && !loading ? (
        data.map((item, index) => {
          return (
            <ReviewItem
              t={t}
              game={item}
              key={item?._id}
              onClick={() => {
                history.push({
                  pathname: "/review-page",
                  state: { data: item, type: "review" },
                });
              }}
            />
          );
        })
      ) : data.length == 0 && !loading ? (
        <div style={{ position: "absolute", top: "50%" }}>
          {t("review.noReview")}
        </div>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
};

export default Reviews;
