import React, { useEffect, useState } from "react";
import styles from "../../Settings/Setting.module.scss";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import settings from "../../../../../assets/images/acountsettings1.svg";
import PersonalInformation from "../PersonalInformation/PersonalInformation";
import ChangePassword from "../ChangePassword/ChangePassword";
import RequestVerification from "../RequestVerification/RequestVerification";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import FeedBack from "../FeedBack/FeedBack";
import BlockedAccount from "../BlockedAccount/BlockedAccount";
import ChangeUsername from "../ChangeUsername/ChangeUsername";
import AppVersion from "../AppVersion/AppVersion";
import ShowError from "../../../../error/ShowError";

const AccountSetting = ({ userData, userProfile, t }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [state, setState] = useState({
    checkedPersonal: false,
    changePassword: false,
    requestVerification: false,
    deleteAccount: false,
    feedback: false,
    blockedAccount: false,
    appVersion: false,
    updateUser: false,
    showError: false,
    message: "",
    severity: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTogglePersonal = () => {
    setState({ ...state, checkedPersonal: !state.checkedPersonal });
  };

  const handleChangePassword = () => {
    setState({ ...state, changePassword: !state.changePassword });
  };

  const handleRequestVerification = () => {
    setState({ ...state, requestVerification: !state.requestVerification });
  };

  const handleDeleteAccount = () => {
    setState({ ...state, deleteAccount: !state.deleteAccount });
  };

  const handleOpenSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };

  const handleBlockedAccount = () => {
    setState({ ...state, blockedAccount: false });
  };

  const handleAppVersion = () => {
    setState({ ...state, appVersion: false });
  };

  const handleUpdateUser = () => {
    setState({ ...state, updateUser: true, deleteAccount: false });
  };

  const handleUserUpdateClose = () => {
    setState({ ...state, updateUser: false, deleteAccount: true });
  };

  const handleCloseError = () => {
    setState({ ...state, showError: false });
  };
  const handleFeedBack = () => {
    setState({
      ...state,
      feedback: false,
    });
  };
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="secondary"
        style={{ padding: 0 }}
        onClick={handleOpenSettings}
        className={styles.accountSetting}
      >
        <img
          src={settings}
          alt="setting"
          style={{
            height: "30px",
            width: "50px",
            marginRight: "5px",
          }}
        />
        {t("setting.accountSettings")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setState(() => ({
              ...state,
              checkedPersonal: true,
            }));
            setAnchorEl(null);
          }}
          value="PersonalInformation"
        >
          {t("setting.personalInformation")}
        </MenuItem>

        <MenuItem
          onClick={() => {
            // if (user?.isVerified) {
            setState({
              ...state,
              changePassword: true,
            });
            // } else {
            //   setState({
            //     ...state,
            //     showError: true,
            //     message: "You need to verify your account to change Password",
            //     severity: "info",
            //   });
            // }
            setAnchorEl(null);
          }}
          value="changePassword"
        >
          {t("setting.changePassword")}
        </MenuItem>
        {user?.isVerified ? (
          ""
        ) : (
          <MenuItem
            onClick={() => {
              setState(() => ({
                ...state,
                requestVerification: true,
              }));
              setAnchorEl(null);
            }}
            value="requestVerification"
          >
            {t("setting.requestVerification")}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setState(() => ({
              ...state,
              blockedAccount: true,
            }));
            setAnchorEl(null);
          }}
          value="blockedAccount"
        >
          {t("setting.blockedAccount")}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setState(() => ({
              ...state,
              deleteAccount: true,
            }));
            setAnchorEl(null);
          }}
          value="deleteAccount"
        >
          {t("setting.deleteAccount")}
        </MenuItem>
      </Menu>

      {state?.checkedPersonal ? (
        <PersonalInformation
          checkedPersonal={state.checkedPersonal}
          handleToggle={handleTogglePersonal}
          t={t}
        />
      ) : null}
      {state?.changePassword ? (
        <ChangePassword
          changePassword={state.changePassword}
          handleChangePassword={handleChangePassword}
          t={t}
        />
      ) : null}
      {state?.requestVerification ? (
        <RequestVerification
          requestVerification={state.requestVerification}
          handleRequestVerification={handleRequestVerification}
          t={t}
        />
      ) : null}
      {state?.deleteAccount ? (
        <DeleteAccount
          deleteAccount={state.deleteAccount}
          handleDeleteAccount={handleDeleteAccount}
          handleUpdateUser={handleUpdateUser}
          userProfile={userProfile}
          t={t}
        />
      ) : null}
      {state.feedback ? (
        <FeedBack open={state.feedback} handleClose={handleFeedBack} t={t} />
      ) : null}
      {state.blockedAccount ? (
        <BlockedAccount
          open={state.blockedAccount}
          handleClose={handleBlockedAccount}
          t={t}
        />
      ) : null}
      {state.appVersion ? (
        <AppVersion
          open={state.appVersion}
          handleClose={handleAppVersion}
          t={t}
        />
      ) : null}
      {state.updateUser ? (
        <ChangeUsername
          open={state.updateUser}
          handleClose={handleUserUpdateClose}
          userData={userData}
          userProfile={userProfile}
          t={t}
        />
      ) : null}
      {state.showError ? (
        <ShowError
          open={state.showError}
          handleClose={handleCloseError}
          message={state.message}
          severity={state.severity}
          t={t}
        />
      ) : null}
    </div>
  );
};
export default AccountSetting;
