import styles from "./Nickname.module.scss";
import coin from "../../../assets/images/coin.svg";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(1),
  },
}));

const NicknameBox = ({ data, handleModalData, color, user }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.control}>
      <div
        onClick={() => {
          handleModalData(data);
        }}
        className={styles.dataHandler}
      >
        <div className={styles.container}>
          <p className={styles.namePadding}>{user.firstName}</p>
          <div>
            <p className={styles.subName} style={{ color: color }}>
              {data.nickName}
            </p>
          </div>
        </div>
        <div className={styles.footerContainer}>
          <div>
            <p>{data.name}</p>
            <img
              src={coin}
              alt="coin"
              style={{ height: "10px", width: "10px" }}
            />{" "}
            * {data.priceInCoins}
          </div>
        </div>
      </div>
    </Grid>
  );
};
export default NicknameBox;
