import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Container } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import style from "./IssueScreen.module.scss";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import TextField from "@material-ui/core/TextField";
import { ReportIssueOrSpam } from "../../../../../services/reportService";
import { SendFeedback } from "../../../../../services/feedbackService";

import Alert from "@material-ui/lab/Alert";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    minHeight: "200px",
    minWidth: "200px",
  },
}))(MuiDialogContent);

const IssueScreen = ({ open, handleIssueScreen, t }) => {
  const [values, setValues] = useState({
    problem: "",
    response: "",
  });
  let user = JSON.parse(localStorage.getItem("user"));
  const handleReport = () => {
    // let payload = {
    //   reportType: "SYSTEM_ISSUES",
    //   description: values.problem,
    // };
    // ReportIssueOrSpam(payload).then((res) => {
    //   setValues({ ...values, response: "Thank you for your feedback!!" });
    //   setTimeout(() => {
    //     setValues({ ...values, response: "", problem: "" });
    //     handleIssueScreen();
    //   }, 3000);
    // });
    let feedrespond = {
      description: values.problem,
    };
    SendFeedback(feedrespond, (data) => {
      if (data) {
        setValues({ ...values, response: "Thank you for your feedback!!" });
        setTimeout(() => {
          setValues({ ...values, response: "", problem: "" });
          handleIssueScreen();
        }, 3000);
      }
    });
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        className={style.body}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleIssueScreen}>
          {t("setting.issue").toUpperCase()}
        </DialogTitle>
        <DialogContent dividers>
          <UserAvatar source={user?.pic} size={"40px"} />
          <TextField
            id="standard-basic"
            label={t("setting.issuePlaceHolder")}
            className={style.textStyle}
            onChange={(event) => {
              setValues({ ...values, problem: event.target.value });
            }}
          />
        </DialogContent>
        {values.response !== "" ? (
          <Alert severity="success">{values.response}</Alert>
        ) : (
          ""
        )}
        <Container>
          <Button
            variant="contained"
            color="primary"
            className={style.reportButton}
            onClick={handleReport}
            disabled={values.problem !== "" ? false : true}
          >
            {t("setting.report")}
          </Button>
        </Container>
      </Dialog>
    </div>
  );
};
export default IssueScreen;
