import React from "react";
import "./questContent.css";
import QuestItem from "../questItem/questItem";
export default function QuestContent({ quests, t }) {
  return (
    <>
      <div className="quest-content-wrapper">
        {quests?.length > 0 &&
          quests?.map((item, index) => {
            return <QuestItem item={item} key={index} t={t} />;
          })}
      </div>
    </>
  );
}
