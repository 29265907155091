import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileData } from "../../redux/reducers/userProfileDataSlice";
import { GetUserReviews } from "../../services";
import styles from "../../styles/pages.module.scss";
import PostListing from "../common/Posts/PostListing";
import ReviewItem from "../Reviews/ReviewItem/ReviewItem";
import { useHistory } from "react-router-dom";
import { DeleteGameReview } from "../../services/gamesService";
import { DeleteProfileReview } from "../../services/mutateReduxState";
import ShowError from "../error/ShowError";
import { setUser } from "../../redux/reducers/userSlice";
import UserReview from "../common/UserReview/UserReview";
const UserProfileReviews = ({ userID }) => {
  let history = useHistory();
  let { userProfileData } = useSelector((state) => state.root);
  const [userData, setUserData] = useState(userProfileData);
  const [values, setValues] = useState({
    error: false,
    message: "",
    severity: "",
  });
  const [reload, setReload] = useState(0);
  let dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  let [state, setState] = useState({
    loading: true,
  });
  // const { userProfileData } = useSelector((state) => state.root);
  // console.log("selectror", res);
  const handleReload = () => {
    setReload(reload + 2);
  };
  useEffect(() => {
    GetUserReviews((reviewRes) => {
      if (reviewRes?.length > 0) {
        let arr = [];
        reviewRes.forEach((element) => {
          if (Object.keys(element).length > 0) {
            arr = [element, ...arr];
          }
          dispatch(setUserProfileData({ reviews: arr }));
          setState((prev) => ({ ...prev, loading: false }));
        });
      } else {
        dispatch(setUserProfileData({ reviews: reviewRes }));
        setState((prev) => ({ ...prev, loading: false }));
      }
    }, userID);
  }, []);
  const handleDeleteReview = (id) => {
    DeleteGameReview((res) => {
      if (res) {
        // let newArray = gameReviewsData.filter((res) => res._id !== id);
        // setGameReviewsData(newArray);
        // dispatch(setGameReviews(newArray));
        console.log(res);
        DeleteProfileReview(res);
        setValues({
          ...values,
          error: !values.error,
          message: "You have successfully deleted the review!",
          severity: "success",
        });
      } else {
        setValues({
          ...values,
          error: !values.error,
          message: "Something went wrong, Please try again",
          severity: "error",
        });
      }
    }, id);
  };
  return (
    <div>
      {state.loading ? (
        <div className="loader"></div>
      ) : (
        <div className={styles.wrapper} style={{ width: `88vw` }}>
          {userProfileData?.reviews?.length > 0
            ? userProfileData.reviews.map((game, index) => {
                return (
                  <UserReview
                    profile={game}
                    handleDeleteReview={handleDeleteReview}
                    key={index}
                    user={user}
                    handleReload={handleReload}
                  />
                );
              })
            : "No Result Found"}

          {values.error ? (
            <ShowError
              open={values.error}
              message={values.message}
              severity={values.severity}
              handleClose={() => {
                setValues({ ...values, error: !values.error });
              }}
            />
          ) : null}
          {/* <PostListing data={userProfileData.reviews} /> */}
        </div>
      )}
    </div>
  );
};

export default UserProfileReviews;
