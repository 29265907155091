import React, { useState } from "react";
import styles from "./ForgetPassword.module.scss";
import logo from "../../assets/images/favicons.ico";
import { Link } from "react-router-dom";
import { ForgotPasswordCall } from "../../services";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ShowError from "../error/ShowError";
import PinInput from "react-pin-input";
import { ValidatePassword } from "../../utils/AppValidators";
import { ResetPasswordService } from "../../services";
import { useTranslation } from "react-i18next";

const ForgetPassword = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: false,
    email: "",
    showError: false,
    message: "",
    severity: "",
    changePassword: false,
    newPassword: "",
    confirmPassword: "",
    verficationCode: "",
  });
  const handleClose = () => {
    setState({ ...state, showError: false });
  };
  const onSubmit = (e) => {
    e?.preventDefault();
    if (state.email.trim()) {
      setState((prev) => ({ ...prev, loading: true }));
      ForgotPasswordCall(
        (res) => {
          if (res?.success) {
            setState({
              ...state,
              showError: true,
              message:
                "Recovery link has been sent to your email,Please Change Password",
              severity: "success",
              changePassword: true,
            });
          } else if (!res?.success) {
            setState({
              ...state,
              showError: true,
              message: "Kindly provide valid username or email address",
              severity: "error",
            });
          } else {
            setState({
              ...state,
              showError: true,
              message: "Internal server error",
              severity: "error",
            });
          }
          setState((prev) => ({ ...prev, loading: false }));
        },
        state.email.includes("@")
          ? state.email?.toLowerCase().trim()
          : state.email?.trim()
      );
    } else {
      setState({
        ...state,
        showError: true,
        message: "Kindly provide valid username or email address",
      });
    }
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    if (state.verficationCode.length === 4) {
      if (ValidatePassword(state.newPassword)) {
        if (ValidatePassword(state.confirmPassword)) {
          if (state.newPassword === state.confirmPassword) {
            ResetPasswordService(
              (res) => {
                if (res) {
                  setState({ ...state, loading: false });
                  history.push("/");
                } else {
                  setState({
                    ...state,
                    showError: true,
                    message:
                      "Could not be able to update, Please enter same verification Code",
                    severity: "error",
                    loading: false,
                  });
                }
              },
              {
                password: state.newPassword,
                code: parseInt(state.verficationCode),
                email: state.email,
              }
            );
          } else {
            setState({
              ...state,
              showError: true,
              message: "New and confirm password must be the same",
              severity: "error",
              loading: false,
            });
          }
        } else {
          setState({
            ...state,
            showError: true,
            message:
              "Confirm Password should contain [special characters, Upper and Lower case Alphabets and numbers]",
            severity: "error",
            loading: false,
          });
        }
      } else {
        setState({
          ...state,
          showError: true,
          message:
            " New Password should contain [special characters, Upper and Lower case Alphabets and numbers]",
          severity: "error",
          loading: false,
        });
      }
    } else {
      setState({
        ...state,
        showError: true,
        message: "Please Enter 4 digit verification code",
        severity: "error",
        loading: false,
      });
    }
  };
  return (
    <div>
      {!state.changePassword ? (
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logo} style={{ height: "69px" }} alt="logo" />
            </Link>
          </div>
          <div className={styles.card}>
            <div className={styles.content}>
              <div className={styles.heading}>{t("fP.recoveryPassword")}</div>
              <div className={styles.text}>{t("fP.recoveryParagraph")}</div>
            </div>
            <div className={styles.main}>
              <form onSubmit={onSubmit}>
                <input
                  type="text"
                  name={"email"}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      [e?.target?.name]: e?.target?.value,
                    }))
                  }
                  placeholder="username or e-mail"
                />
                <div className={styles.button}>
                  <button>
                    {state.loading ? (
                      <CircularProgress size={30} />
                    ) : (
                      t("fP.sendLink")
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {state.showError ? (
            <ShowError
              open={state.showError}
              handleClose={handleClose}
              severity={state.severity}
              message={state.message}
            />
          ) : null}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <div className={styles.content}>
              <div className={styles.text}>{t("fP.enterVerification")}</div>
            </div>
            <div className={styles.main}>
              <form onSubmit={handleChangePassword}>
                <PinInput
                  length={4}
                  focus
                  // disabled
                  inputFocusStyle={{ borderColor: "blue" }}
                  inputStyle={{ borderColor: "#868686" }}
                  type="numeric"
                  onChange={(value) => {
                    setState({ ...state, verficationCode: value });
                  }}
                  required
                />
                <input
                  onChange={(e) => {
                    setState({ ...state, newPassword: e.target.value });
                  }}
                  type="password"
                  name={"password"}
                  placeholder={t("fP.newPassword")}
                  required
                />
                <input
                  onChange={(e) => {
                    setState({ ...state, confirmPassword: e.target.value });
                  }}
                  type="password"
                  name={"retypedPassword"}
                  placeholder={t("fP.confirmPassword")}
                  required
                />
                <div className={styles.button}>
                  <button>
                    {state.loading ? (
                      <CircularProgress size={30} />
                    ) : (
                      t("fP.changePassword")
                    )}
                  </button>
                </div>
                {state.showError ? (
                  <ShowError
                    open={state.showError}
                    handleClose={handleClose}
                    severity={state.severity}
                    message={state.message}
                  />
                ) : null}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgetPassword;
