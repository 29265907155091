import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { GetUserFollower } from "../../../../../services/profileService";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import style from "./BlockedAccount.module.scss";
import "../../../../../styles/loader.css";
import { FRIEND_STATUSES_ACTIONS } from "../../../../../utils/AppConstants";
import { ActionsOnUsers } from "../../../../../services/profileService";
import ShowError from "../../../../error/ShowError";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 100,
    minWidth: 400,
  },
}))(MuiDialogContent);

const BlockedAccount = ({ open, handleClose, t }) => {
  const [reload, setReload] = useState(false);
  const [values, setValues] = useState({
    userData: "",
    loading: false,
    showError: false,
    severity: "",
    message: "",
  });
  useEffect(() => {
    setValues({ ...values, loading: true });
    GetUserFollower((res) => {
      setValues({ ...values, userData: res, loading: false });
    }, "BLOCKED");
  }, []);

  const hanldeUnBlock = (user, index) => {
    setValues({ ...values, loading: true });
    ActionsOnUsers(
      (res) => {
        setReload(true);
        if (res) {
          let temp = values.userData;
          temp.splice(index, 1);
          setValues({
            ...values,
            loading: false,
            showError: true,
            message: "You have successfully unblocked!",
            severity: "success",
            userData: temp,
          });
        } else {
          setValues({
            ...values,
            loading: false,
            showError: true,
            message: "Could not be able to unblock! Please try again",
            severity: "error",
          });
        }
      },
      user._id,
      FRIEND_STATUSES_ACTIONS.UNBLOCKED
    );
  };

  const handlToastClose = () => {
    setValues({ ...values, showError: false });
  };
  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        {values.loading ? (
          <div className={style.dialogHeight}>
            <DialogContent>
              <center>
                <div className="loader alignBlockedAccount"></div>
              </center>
            </DialogContent>
          </div>
        ) : (
          <div className={style.dialogHeight}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {t("setting.blockedAccounts")}
            </DialogTitle>
            <div>
              {values.userData.length > 0 ? (
                values.userData?.map((item, index) => {
                  return (
                    <DialogContent dividers className={style.parentStyle}>
                      <div className={style.alignAccount}>
                        <UserAvatar source={item?.pic} size={"40px"} />
                        <Typography className={style.textStyle}>
                          {item.userName}
                        </Typography>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            hanldeUnBlock(item, index);
                          }}
                          className={style.buttonStyle}
                        >
                          UnBlock
                        </Button>
                      </div>
                    </DialogContent>
                  );
                })
              ) : (
                <DialogContent>
                  <Typography>{t("dashboard.noData")}</Typography>
                </DialogContent>
              )}
            </div>
          </div>
        )}
      </Dialog>
      {values.showError ? (
        <ShowError
          open={values.showError}
          handleClose={handlToastClose}
          severity={values.severity}
          message={values.message}
        />
      ) : null}
    </div>
  );
};
export default BlockedAccount;
