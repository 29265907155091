import React, { useState, useEffect } from "react";
import {
  GetNotificationHistory,
  ReadUpdateNotificationStatus,
} from "../../services/PushNotifications/NotificationMethods";
import { ActionsOnUsers, GetPostByCommentID } from "../../services";
import { FRIEND_STATUSES_ACTIONS } from "../../utils/AppConstants";
import moment from "moment";
import "./NotificationsScreen.css";
import { notificationCounter } from "../../redux/reducers/notificationsSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetSinglePost } from "../../services/postService";
import PostCamera from "../common/dialogbox/postCameraDialog";
import { useTranslation } from "react-i18next";

const NotificationsScreen = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [FriendRequests, setFriendRequests] = useState([]);
  const { t } = useTranslation();
  let history = useHistory();

  const getNotificationsHistory = () => {
    GetNotificationHistory((res) => {
      console.log(res);
      let arr = res?.filter((item) => {
        return (
          item.type === "FriendRequest" &&
          item.status === "" &&
          !item.body.includes("accepted")
        );
      });
      setFriendRequests(arr);
      setNotifications(res);
    }, "");
  };

  useEffect(() => {
    getNotificationsHistory();
  }, []);

  const handleFollowRequest = (item, id, type) => {
    try {
      ActionsOnUsers(
        (res) => {
          getNotificationsHistory();
        },
        id,
        type
      );
    } catch (err) {}
  };

  return (
    <div className="notification_container">
      <div className="notifications_containerTop">
        <p className="notification_title">
          {" "}
          {t("notifications.followRequest")}
        </p>
        <div className="notification_followRequest">
          {FriendRequests.length > 0 ? (
            FriendRequests.map((item, index) => {
              return (
                <FollowRequestCard
                  key={index}
                  item={item}
                  handleFollowRequest={handleFollowRequest}
                />
              );
            })
          ) : (
            <p className="notifications_text">{t("notifications.noRequest")}</p>
          )}
        </div>
      </div>

      <div className="notifications_containerBottom">
        <p className="notification_title">{t("notifications.notif")}</p>
        <div className="notification_followRequest">
          {notifications?.map((item, index) => {
            return <NotificationCard item={item} key={index} t={t} />;
          })}
        </div>
      </div>
    </div>
  );
};

const NotificationCard = ({ item, t }) => {
  let history = useHistory();
  const [resp, setresp] = useState({});
  const [open, setOpen] = useState(false);
  const handlePost = (id) => {
    history.push(`/explore/${id}`);
    console.log(id);

    // GetSinglePost((data) => {
    //   if (data) {
    //     setresp(data);
    //     setOpen(true);
    //   }
    // }, id);
  };
  const getCommentPostId = (id) => {
    GetPostByCommentID(id).then((res) => history.push(`/explore/${res?._id}`));
  };

  return (
    <>
      {item?.type === "Post" ? (
        <div className="notification_cardContainer">
          <img
            src={item?.createdBy?.pic}
            className="notification_avatar"
            style={{
              backgroundSize: "cover",
              height: 50,
              width: 50,
              borderRadius: 60,
              marginRight: 20,
              marginLeft: 20,
            }}
          />
          <p
            className="notification_cardTitle"
            // style={{
            //   marginRight: 20,
            // }}
            onClick={() => handlePost(item?.post)}
          >
            {item.body}
          </p>
          <p className="notification_date">
            {moment(item?.createdAt).format("LLLL")}
          </p>
          {open ? (
            <PostCamera value={open} setval={setOpen} response={resp} />
          ) : (
            ""
          )}
        </div>
      ) : item?.type === "Comment" ? (
        <div
          className="notification_cardContainer"
          onClick={() => {
            getCommentPostId(item?.comment);
          }}
        >
          <img
            src={item?.createdBy?.pic}
            className="notification_avatar"
            style={{
              backgroundSize: "cover",
              height: 50,
              width: 50,
              borderRadius: 60,
              marginRight: 20,
              marginLeft: 20,
            }}
          />
          <p className="notification_cardTitle">{item.body}</p>
          <p className="notification_date">
            {moment(item?.createdAt).format("LLLL")}
          </p>
        </div>
      ) : (
        <div
          className="notification_cardContainer"
          onClick={() => {
            history.push({
              pathname: "/view-profile",
              state: { userID: item?.createdBy?.profileId },
            });
          }}
        >
          <img
            src={item?.createdBy?.pic}
            className="notification_avatar"
            style={{
              backgroundSize: "cover",
              height: 50,
              width: 50,
              borderRadius: 60,
              marginRight: 20,
              marginLeft: 20,
            }}
          />
          <p className="notification_cardTitle">{item.body}</p>
          <p className="notification_date">
            {moment(item?.createdAt).format("LLLL")}
          </p>
        </div>
      )}
    </>
  );
};
const FollowRequestCard = ({ item, handleFollowRequest }) => {
  const { t } = useTranslation();
  return (
    <div className="notification_cardContainer">
      <img
        src={item?.createdBy?.pic}
        className="notification_avatar"
        style={{
          backgroundSize: "cover",
          height: 50,
          width: 50,
          borderRadius: 60,
          marginRight: 20,
          marginLeft: 20,
        }}
      />
      <div className="notification_cardRight">
        <p className="notification_cardTitle">{item.body}</p>
        <div className="notification_buttonContainer">
          <button
            onClick={() => {
              handleFollowRequest(
                item,
                item?.createdBy?._id,
                FRIEND_STATUSES_ACTIONS.ACCEPT_FOLLOW_REQUEST
              );
            }}
            title="Accept"
            className="notification_button"
          >
            {t("dashboard.accept")}
          </button>
          <button
            onClick={() => {
              handleFollowRequest(
                item?._id,
                FRIEND_STATUSES_ACTIONS.CANCEL_FOLLOW_REQUEST
              );
            }}
            title="Accept"
            className="notification_buttonDeny"
          >
            {t("dashboard.deny")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationsScreen;
