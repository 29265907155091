import { useState, useEffect } from "react";
import styles from "./Corner.module.scss";
import ColorScheme from "./ColorScheme";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import CornerAvatar from "./CornerAvatar";
import "../../../../../styles/loader.css";
import { GetMyAssets } from "../../../../../services/customizationService";
import { ASSET_TYPES } from "../../../../../utils/AppConstants";
import ApplyBackground from "../Background/ApplyBackground";
import { SetAssetAsDefault } from "../../../../../services/customizationService";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../../redux/reducers/userSlice";
const Corner = ({ userData }) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [corners, setCorners] = useState("");
  const [cornerData, setCornersdata] = useState("");
  const [openDialog, setOpendialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetMyAssets((res) => {
      setCorners(res);
      setLoading(false);
    }, ASSET_TYPES.CORNER);
  }, []);
  const handleModalData = (cornerData) => {
    setCornersdata(cornerData);
    setOpendialog(!openDialog);
  };
  const handleClose = () => {
    setOpendialog(!openDialog);
  };
  const handleSubmit = (data) => {
    SetAssetAsDefault(
      (res) => {
        if (res) {
          dispatch(setUser(res));
          setLoading(false);
          setOpendialog(!openDialog);
          history.push("/profile");
        }
      },
      ASSET_TYPES.CORNER,
      cornerData._id,
      color
    );
    setOpendialog(!openDialog);
  };
  const handleColor = (getcolor) => {
    setColor(getcolor);
  };
  return (
    <div>
      {loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <div>
          {/* <ColorScheme handleColor={handleColor} /> */}
          <div style={{ display: "flex" }}>
            <div className={styles.cornerPointer}>
              {corners?.length > 0
                ? corners?.map((item, index) => (
                    <CornerAvatar
                      source={item}
                      profilePic={userData.pic}
                      handleModalData={handleModalData}
                      key={index}
                    />
                  ))
                : null}
            </div>
            <span className={styles.blueCircle}>
              <span className={styles.whiteCircle}>
                <AddIcon
                  className={styles.addIcon}
                  onClick={() => {
                    history.push({
                      pathname: "omega-store",
                      state: { tab: 1 },
                    });
                  }}
                />
              </span>
            </span>
          </div>
          {openDialog ? (
            <ApplyBackground
              open={openDialog}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              data={cornerData}
              title="Corners"
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default Corner;
