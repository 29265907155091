import UserAvatar from "../../common/UserAvatar/UserAvatar";
import styles from "./Corners.module.scss";
import defaultImage from "../../../assets/images/defaultpic.jpeg";
const FramedAvatar = ({ source, profilePic }) => {
  return (
    <div className={styles.parent}>
      <div className={styles.image1}>
        <img
          src={source || defaultImage}
          alt="profile image"
          style={{
            height: "100px",
            width: "100px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      </div>
      <div className={styles.image2}>
        <img
          src={profilePic || defaultImage}
          alt="profile image"
          style={{
            height: "80px",
            width: "80px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      </div>
    </div>
  );
};
export default FramedAvatar;
