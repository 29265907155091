import React, { useState, useRef, useMemo, useCallback } from "react";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import { TextField, Grid, Button, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import "./addComment.css";
import { Link, useHistory } from "react-router-dom";
// import Avatar from "../../../UserAvatar/UserAvatar";
import { getData } from "../../../../../../utils/AppStorage";
import { CommentPost, EditComment } from "../../../../../../services";
import { setComment } from "../../../../../../redux/reducers/commentSlice";
import { updateCommentFromRduxStore } from "../../../../../../services/mutateReduxState";
import ListCard from "./listCard";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import mentionsStyles from "./MentionsStyles.module.css";
import CommentInput from "./input";
import { replace } from "lodash-es";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    width: "100%",
  },
  multilineColor: {
    color: "#fff",
  },
}));

const MyButton = styled(({ color, ...other }) => <Button {...other} />)({
  background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: "white",
  height: 38,
  padding: "0 30px",
  margin: 8,
  width: "216px",
});

export default function AddComment({
  state,
  setstate,
  PostInfo,
  commentData,
  inputWidth,
  editorState,
  setEditorState,
  EditorState,
}) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [Errorfor, setError] = useState("");
  const [ErrorMsg, setErrorMsg] = useState("");
  const [dumVal, setDumVal] = useState("");
  const [listTag, setTag] = useState([]);
  const [showCard, setCard] = useState(false);

  React.useEffect(() => {
    getData("user", function (data) {
      if (data) {
        setstate({
          ...state,
          user: data,
        });
      }
    });
  }, []);
  let validate = () => {
    if (state.comment === "") {
      setError("Comment");
      setErrorMsg("Comment is Required field!!");
      return false;
    } else {
      return true;
    }
  };
  let ResetError = () => {
    setError("");
    setErrorMsg("");
  };

  let AddCommentPost = () => {
    let valid = validate();
    if (valid) {
      setLoader(true);
      let arr = [];
      state.comment.split(" ").forEach((ele) => {
        if (state.mentionUser.find((i) => ele == `@${i.userName}`)?.userName) {
          arr.push(state.mentionUser.find((i) => ele == `@${i.userName}`)?._id);
        }
      });

      if (commentData?.parentComment) {
        var obj = {
          mentions: arr,
          parentComment: commentData?.parentComment,
          post: commentData?.post,
          text: state.comment,
        };

        CommentPost(function (data) {
          if (data) {
            updateCommentFromRduxStore(commentData?.post);
            setstate({
              ...state,
              comment: "",
              mentionUser: [],
            });
            setLoader(false);
            dispatch(setComment(data?.text));
          }
        }, obj);
      } else if (commentData?._id) {
        var obj = {
          mentions: arr,
          parentComment: commentData?._id,
          post: commentData?.post,
          text: state.comment,
        };
        CommentPost(function (data) {
          if (data) {
            updateCommentFromRduxStore(commentData?.post);
            setstate({
              ...state,
              comment: "",
              mentionUser: [],
            });
            setLoader(false);

            dispatch(setComment(data?.text));
          }
        }, obj);
      } else {
        var obj = {
          mentions: arr,
          post: PostInfo?._id,
          text: state.comment,
        };
        CommentPost(function (data) {
          console.log("main", data);
          updateCommentFromRduxStore(data?.post);
          setEditorState(() => EditorState.createEmpty());
          if (data) {
            setstate({
              ...state,
              comment: "",
              mentionUser: [],
            });
            setLoader(false);

            dispatch(setComment(data?.text));
          }
        }, obj);
      }
    }
  };
  let EditCommentPost = () => {
    setLoader(true);
    let arr = [];
    state.comment.split(" ").forEach((ele) => {
      if (state.mentionUser.find((i) => ele == `@${i.userName}`)?.userName) {
        arr.push(state.mentionUser.find((i) => ele == `@${i.userName}`)?._id);
      }
    });
    var obj = {
      mentions: arr,
      post: PostInfo?._id,
      text: state.comment,
    };
    EditComment(
      function (data) {
        setLoader(false);
        setEditorState(() => EditorState.createEmpty());
        dispatch(setComment(data));
        setstate({
          user: "",
          comment: "",
          currentFocus: null,
          mentionUser: [],
        });
      },
      state.eidId,
      obj
    );
  };
  return (
    <div className="wrapper_comment">
      <div className={classes.margin}>
        <CommentInput
          state={state}
          setstate={setstate}
          EditorState={EditorState}
          editorState={editorState}
          setEditorState={setEditorState}
          ref={ref}
          ResetError={ResetError}
        />

        <div style={{ position: "relative", top: "0" }}>
          {showCard ? (
            <ListCard
              value={state}
              dumVal={dumVal}
              setCard={setCard}
              listTag={listTag}
              setTag={setTag}
            />
          ) : (
            ""
          )}
        </div>
        <Grid container spacing={1} alignItems="flex-end">
          {Errorfor === "Comment" && ErrorMsg !== "" ? (
            <Grid container>
              <Alert severity="error" style={{ width: "inherit" }}>
                {ErrorMsg}
              </Alert>
            </Grid>
          ) : (
            ""
          )}
          {state?.eidId ? (
            <MyButton color="blue" disabled={loader} onClick={EditCommentPost}>
              {loader ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                t("dashboard.editComment")
              )}
            </MyButton>
          ) : (
            <MyButton color="blue" disabled={loader} onClick={AddCommentPost}>
              {loader ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                t("dashboard.addComment")
              )}
            </MyButton>
          )}
        </Grid>
      </div>
    </div>
  );
}
