import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { IconButton } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PostCard from "../Posts/PostCard/PostCard";
import moment from "moment";
import {
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  Radio,
  ListItemText,
  Avatar,
  ListItemAvatar,
  FormControl,
  FormLabel,
  FormGroup,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CustomStyle from "../../Reviews/ReviewPage/ReviewPage.module.scss";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },

  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
}));
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
export default function GameConsoleDialog({ value, setval, response, t }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = (theme) => ({
    root: {
      margin: 5,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const handleClose = () => {
    setval({
      ...value,
      ConsoleDialogOpen: false,
    });
  };
  var group1 = [
    "PC",
    "PlayStation",
    "PlayStation 2",
    "PlayStation 3",
    "PlayStation 4",
    "PlayStation 5",
    "Xbox",
    "Xbox 360",
    "Xbox One",
    "Nintendo 64",
    "GameCube",
    "Atari",
    "Other",
  ];
  var group2 = [
    "Xbox Series X/S",
    "GameBoy Color",
    "GameBoy Advance",
    "Nintendo DS",
    "Nintendo 3DS",
    "Nintendo Switch",
    "Wii",
    "Wii U",
    "PSP",
    "PSP Vita",
    "NES",
    "SNES",
  ];
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={value}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "black",
          },
        }}
        disableBackdropClick={true}
      >
        <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
          {/* Camera Screen */}
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{ width: "500px", height: "500px", color: "#7f7f7f" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel style={{ color: "white" }}>Console:</FormLabel>
                    <FormGroup style={{ marginLeft: "121px" }}>
                      <RadioGroup
                        className="group"
                        value={value.consoleValue}
                        onChange={(e) =>
                          setval({
                            ...value,
                            consoleValue: e.target.value,
                          })
                        }
                        style={{ paddingTop: "20px" }}
                      >
                        <div className="group-item" style={{ width: "150px" }}>
                          {group1.map((item, indx) => {
                            return (
                              <FormControlLabel
                                value={item}
                                key={indx}
                                control={<StyledRadio />}
                                label={item}
                              />
                            );
                          })}
                        </div>
                        <div className="group-item">
                          {group2.map((item, indx) => {
                            return (
                              <FormControlLabel
                                value={item}
                                key={indx}
                                control={<StyledRadio />}
                                label={item}
                              />
                            );
                          })}
                        </div>
                      </RadioGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={CustomStyle.wrapper}>
          <div className={CustomStyle.rate}>
            <button
              onClick={() => {
                setval({
                  ...value,
                  ConsoleDialogOpen: false,
                });
              }}
            >
              {t("dashboard.select")}
            </button>
          </div>
          <div
            className={CustomStyle.rate}
            style={{
              background:
                "linear-gradient(90deg, rgb(255 30 0 / 53%) 0%, rgb(255 30 0 / 55%) 100%)",
            }}
          >
            <button
              onClick={() => {
                setval({
                  ...value,
                  consoleValue: "Ps4",
                });
              }}
              style={{ color: "#ff0d00" }}
            >
              {t("dashboard.reset")}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
