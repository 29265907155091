import React from "react";
import {
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  Slide,
  RadioGroup,
  Radio,
  IconButton,
  Container,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import "./filter.css";
import { useTheme } from "@material-ui/core/styles";
import {
  GetCurrentDate,
  GetLastWeekStartOf,
  GetLastWeekEndOf,
  GetLastYearStartOf,
  GetLastYearEndOf,
  GetLastMonthStartOf,
  GetLastMonthEndOf,
} from "../../../../utils/AppHelperMethods";
import { setQuery } from "../../../../redux/reducers/querySlice";
import { store } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {
  storeData,
  getData,
  removeItemsFromLocalStorage,
} from "../../../../utils/AppStorage";
import { resetExploreFeed } from "../../../../redux/reducers/exploreFeedSlice";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },

  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
}));
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
const styles = (theme) => ({
  root: {
    margin: 5,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function FilterComp({ value, setval }) {
  const [sort, setSort] = React.useState("Best");
  const [time, settime] = React.useState("Newest");
  const [searchTerm, setSearchTerm] = React.useState("");

  const classes = useStyles();

  const handleClose = () => {
    setval(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //   console.log("sort", sort);
  const { query } = useSelector((state) => state.root);
  React.useEffect(() => {
    getData("sort", (data) => {
      if (data) {
        setSort(data);
      }
    });
    getData("time", (data) => {
      if (data) {
        settime(data);
      }
    });
  }, []);
  const ToadyPost = () => {
    console.log({ sort: sort, time: time });
    if (sort) {
      storeData("sort", sort);
      storeData(
        "filter",
        `${sort ? "&sort=" + sort.toUpperCase() : ""}${
          searchTerm ? "&search=" + searchTerm : ""
        }&from=${
          time === "Newest"
            ? GetLastMonthStartOf()
            : time === "Past week"
            ? GetLastWeekStartOf()
            : time === "Past month"
            ? GetLastMonthStartOf()
            : time === "Past year"
            ? GetLastYearStartOf()
            : GetLastYearStartOf()
        }&to=${
          time === "Newest"
            ? GetCurrentDate()
            : time === "Past week"
            ? GetLastWeekEndOf()
            : time === "Past month"
            ? GetLastMonthEndOf()
            : time === "Past year"
            ? GetLastYearEndOf()
            : GetCurrentDate()
        }`
      );
    }
    if (time) {
      storeData("time", time);
      storeData(
        "filter",
        `${sort ? "&sort=" + sort.toUpperCase() : ""}${
          searchTerm ? "&search=" + searchTerm : ""
        }&from=${
          time === "Newest"
            ? GetLastMonthStartOf()
            : time === "Past week"
            ? GetLastWeekStartOf()
            : time === "Past month"
            ? GetLastMonthStartOf()
            : time === "Past year"
            ? GetLastYearStartOf()
            : GetLastYearStartOf()
        }&to=${
          time === "Newest"
            ? GetCurrentDate()
            : time === "Past week"
            ? GetLastWeekEndOf()
            : time === "Past month"
            ? GetLastMonthEndOf()
            : time === "Past year"
            ? GetLastYearEndOf()
            : GetCurrentDate()
        }`
      );
    }
    store.dispatch(resetExploreFeed([]));
    store.dispatch(
      setQuery(
        `${sort ? "&sort=" + sort.toUpperCase() : ""}${
          searchTerm ? "&search=" + searchTerm : ""
        }&from=${
          time === "Newest"
            ? GetLastMonthStartOf()
            : time === "Past week"
            ? GetLastWeekStartOf()
            : time === "Past month"
            ? GetLastMonthStartOf()
            : time === "Past year"
            ? GetLastYearStartOf()
            : GetLastYearStartOf()
        }&to=${
          time === "Newest"
            ? GetCurrentDate()
            : time === "Past week"
            ? GetLastWeekEndOf()
            : time === "Past month"
            ? GetLastMonthEndOf()
            : time === "Past year"
            ? GetLastYearEndOf()
            : GetCurrentDate()
        }`
      )
    );
    setval(false);
  };
  const ResetFilter = () => {
    removeItemsFromLocalStorage("sort");
    removeItemsFromLocalStorage("time");
    removeItemsFromLocalStorage("filter");
    setSort("Best");

    settime("Newest");
    store.dispatch(setQuery(""));
  };
  console.log(sort);

  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          open={value}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          disableBackdropClick
        >
          <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
            Filter
          </DialogTitle>
          {/* <DialogTitle id="responsive-dialog-title">Sort Post By:</DialogTitle> */}
          <DialogContent>
            <DialogContentText>
              <div>
                <div>
                  <div>
                    <div>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <h3 style={{ color: "black", fontWeight: "500" }}>
                          Sort Post BY:
                        </h3>
                        <FormGroup>
                          <RadioGroup
                            value={sort}
                            // defaultValue={sort}
                            onChange={(e) => setSort(e.target.value)}
                            className="group"
                          >
                            <div className="group-item">
                              <FormControlLabel
                                value="Best"
                                control={<StyledRadio />}
                                label="Best"
                              />
                              <FormControlLabel
                                value="Hot"
                                control={<StyledRadio />}
                                label="Hot"
                              />
                              <FormControlLabel
                                value="Rising"
                                control={<StyledRadio />}
                                label="Rising"
                              />
                            </div>
                            <div className="group-item">
                              <FormControlLabel
                                value="Top"
                                control={<StyledRadio />}
                                label="Top"
                              />
                              <FormControlLabel
                                value="Controversial"
                                control={<StyledRadio />}
                                label="Controversial"
                              />
                            </div>
                          </RadioGroup>
                        </FormGroup>

                        <h3 style={{ color: "black", fontWeight: "500" }}>
                          Release Date
                        </h3>
                        <FormGroup>
                          <RadioGroup
                            value={time}
                            // defaultValue={`${time ? time : time}`}
                            onChange={(e) => settime(e.target.value)}
                            className="group"
                          >
                            <div className="group-item">
                              <FormControlLabel
                                value="Newest"
                                control={<StyledRadio />}
                                label="Newest"
                              />
                              <FormControlLabel
                                value="Past week"
                                control={<StyledRadio />}
                                label="Past Week"
                              />
                              <FormControlLabel
                                value="Past month"
                                control={<StyledRadio />}
                                label="Past Month"
                              />
                            </div>
                            <div className="group-item">
                              <FormControlLabel
                                value="Past year"
                                control={<StyledRadio />}
                                label="Past Year"
                              />
                              <FormControlLabel
                                value="All time"
                                control={<StyledRadio />}
                                label="All time"
                              />
                            </div>
                          </RadioGroup>
                        </FormGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Container>
              <Button
                onClick={ToadyPost}
                style={{ width: "inherit" }}
                variant="contained"
                color="primary"
              >
                Start
              </Button>
              <Button
                onClick={ResetFilter}
                style={{ width: "inherit", marginTop: "10px" }}
                variant="contained"
                color="danger"
              >
                Reset
              </Button>
            </Container>

            {/* <Button onClick={handleClose} color="danger" autoFocus>
                        Agree
          </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
