import React, { useEffect, useState } from "react";
import coin from "../../../assets/images/coin.svg";
import customize from "../../../assets/images/customize.svg";
import { ReactComponent as Report } from "../../../assets/images/report.svg";
import headerBg from "../../../assets/images/sample-profile-header.png";
import settings from "../../../assets/images/settings.svg";
import verified from "../../../assets/images/verified.svg";
import { largeNumberShortify } from "../../../utils/AppHelperMethods";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import styles from "./ProfileHeader.module.scss";
import { useHistory } from "react-router-dom";
import Followers from "./Followers/Followers";
import Following from "./Following/Following";
import { FRIEND_STATUSES_ACTIONS } from "../../../utils/AppConstants";
import { ActionsOnUsers } from "../../../services";
import ReportDialogBox from "../../common/dialogbox/reportDialogbox";

const ProfileHeader = ({ userData, t }) => {
  const [user, setUser] = useState(userData);
  let actualUser = JSON.parse(localStorage.getItem("user"));
  const [report, setReport] = useState(false);
  let history = useHistory();
  const [values, setValues] = useState({
    showFollowers: false,
    showFollowing: false,
  });
  const [followFreind, setFollowFriend] = useState(userData);
  useEffect(() => {
    actualUser = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
  }, [userData]);
  const handleFollowers = () => {
    setValues({ ...values, showFollowers: false });
  };
  const handleFollowing = () => {
    setValues({ ...values, showFollowing: false });
  };
  // console.log("actual users",actualUser)
  const handleFriendFollowers = (type) => {
    console.log(type);
    let clonetype;
    if (type == "FOLLOW") {
      setFollowFriend({
        ...followFreind,
        isRequested: true,
        isFollowing: false,
      });
      clonetype = type;
    } else if (type == "CANCEL_FOLLOW_REQUEST") {
      setFollowFriend({
        ...followFreind,
        isRequested: false,
        isFollowing: false,
      });
      clonetype = type;
    } else if (type == "UN_FOLLOW") {
      setFollowFriend({
        ...followFreind,
        isRequested: false,
        isFollowing: false,
      });
      clonetype = type;
    }
    ActionsOnUsers(
      function (data) {
        console.log("data", data);
        if (data && data?.data?.status === "ACCEPTED") {
          setFollowFriend({
            ...followFreind,
            isRequested: false,
            isFollowing: true,
          });
        }
        const location = {
          pathname: "/view-profile",
          state: {
            userID: followFreind?._id,
          },
        };
        history.replace(location);
      },
      followFreind?._id,
      clonetype
    );
  };
  // console.log(followFreind)
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.header}
        style={{
          backgroundImage: `url(${user?.cover ? user?.cover : headerBg})`,
        }}
      >
        <div>
          {/* <div className={styles.topBar}>
            <div className={styles.customize}>
              {actualUser._id === userData._id ? (
                <div className={styles.customizeIcon}>
                  <button
                    className={styles.customizeButton}
                    onClick={() => {
                      history.push({
                        pathname: "/customize",
                        state: { userData: userData },
                      });
                    }}
                  >
                    <img
                      src={customize}
                      alt="customize"
                      className={styles.imgStyle}
                    />
                    Customize
                  </button>
                </div>
              ) : null}
            </div>
            {actualUser._id === userData._id ? (
              <div
                className={styles.settings}
                onClick={() => {
                  history.push({
                    pathname: "/setting",
                    state: { userData: userData },
                  });
                }}
              >
                <img src={settings} alt="settings" />
              </div>
            ) : null}
          </div> */}
          <div className={styles.headerContent}>
            <UserAvatar size={"200px"} user={user} innerImageSize="180px" />

            <div className={styles.levelBar}>
              <div className={styles.coins}>
                <div className={styles.coinIcon}>
                  <img src={coin} alt="coins" />
                </div>
                <div className={styles.coinText}>
                  {largeNumberShortify(user?.earnedCoins)}
                </div>
              </div>
              <div className={styles.progressBar}></div>
              <div className={styles.xpPoints}>{user?.earnedXps}/100</div>
            </div>
            <div className={styles.followBar}>
              <div className={styles.followText}>
                <span>{largeNumberShortify(user?.followers)}</span>{" "}
                <button
                  className={styles.buttonStyle}
                  onClick={() => {
                    setValues({ ...values, showFollowers: true });
                  }}
                >
                  {t("profile.followers")}
                </button>
                {values.showFollowers ? (
                  <Followers
                    open={values.showFollowers}
                    handleClose={handleFollowers}
                    userData={userData}
                    t={t}
                  />
                ) : null}
              </div>
              <div className={styles.followText}>
                <span>{largeNumberShortify(user?.following)}</span>
                <button
                  className={styles.buttonStyle}
                  onClick={() => {
                    setValues({ ...values, showFollowing: true });
                  }}
                >
                  {t("profile.following")}
                </button>
                {values.showFollowing ? (
                  <Following
                    open={values.showFollowing}
                    handleClose={handleFollowing}
                    userData={userData}
                    t={t}
                  />
                ) : null}
              </div>
            </div>
            {user?._id == actualUser?._id ? (
              ""
            ) : (
              <div className={styles.friendContainer}>
                <div className={styles.friend}>
                  {followFreind?.isFollowing ? (
                    <button
                      onClick={() => {
                        handleFriendFollowers(FRIEND_STATUSES_ACTIONS.UNFOLLOW);
                      }}
                    >
                      {t("profile.following")}
                    </button>
                  ) : followFreind?.isRequested ? (
                    <button
                      onClick={() => {
                        handleFriendFollowers(
                          FRIEND_STATUSES_ACTIONS.CANCEL_FOLLOW_REQUEST
                        );
                      }}
                    >
                      {t("profile.requested")}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleFriendFollowers(FRIEND_STATUSES_ACTIONS.FOLLOW);
                      }}
                    >
                      {t("profile.follow")}
                    </button>
                  )}
                </div>
                <div
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  onClick={() => {
                    setReport(true);
                  }}
                >
                  <Report className={styles.reportIcon} />
                </div>
                {report && (
                  <ReportDialogBox
                    value={report}
                    setvalue={setReport}
                    obj={userData}
                    type={"user"}
                  />
                )}
              </div>
            )}

            <div className={styles.userInfo}>
              <div className={styles.userDetails}>
                <div className={styles.nameWrapper}>
                  <div className={styles.username}>
                    {user?.userName ? user?.userName : actualUser?.userName}
                    {/* {user?.lastName} */}
                  </div>
                  {user?.isVerified && <img src={verified} alt="verified" />}
                </div>
                <div
                  className={styles.nickname}
                  style={{ color: userData?.nickNameColor }}
                >
                  {user?.nickName}
                </div>
              </div>
              <div className={styles.level}>
                <div className={styles.levelHeading}>
                  {t("dashboard.level")}
                </div>
                <div className={styles.currentLevel}>{user?.level}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
