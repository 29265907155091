import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import styles from "./RequestVerification.module.scss";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import Box from "@material-ui/core/Box";
import { RequestVerificationService } from "../../../../../services/profileService";
import { UploadMedia } from "../../../../../services/mediaUploader";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import "../../../../../styles/loader.css";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "90%",
      backgroundColor: "white",
      justifyContent: "left",
    },
    root: {
      justifyContent: "left",
    },
  },
}));
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{ float: "right", top: "-38px" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const RequestVerification = ({
  requestVerification,
  handleRequestVerification,
  t,
}) => {
  const classes = useStyles();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [state, setState] = useState({
    fullname: "",
    knownas: "",
    category: "",
    file: "",
    error: false,
    loading: false,
    severity: "",
    message: "",
  });
  const handleFileUpload = (event) => {
    setState((prev) => ({ ...prev, file: event.target.files[0] }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setState({ ...state, loading: true });
    UploadMedia(
      (res) => {
        if (res) {
          setState({ ...state, loading: false });
          localStorage.removeItem("verificationUrl");
          localStorage.setItem("verificationUrl", res.data.data.media[0].url);
          const formData = {
            category: state.category,
            fullName: state.fullname,
            identityDoc: localStorage.getItem("verificationUrl"),
            knownAs: state.knownas,
            userName: user?.userName,
          };
          RequestVerificationService((res) => {
            if (res) {
              setState({
                ...state,
                error: true,
                severity: "success",
                message: "Your request has been sent successfully",
              });
              setTimeout(() => {
                localStorage.removeItem("verificationUrl");
                setState({
                  fullname: "",
                  knownas: "",
                  category: "",
                  file: "",
                  error: false,
                  loading: false,
                });
                handleRequestVerification();
              }, 3000);
            } else {
              setState({
                ...state,
                error: true,
                severity: "error",
                message: "Please Try again",
                loading: false,
              });
            }
          }, formData);
        } else {
          setState({
            ...state,
            error: true,
            severity: "error",
            message: "Please Try again",
            loading: false,
          });
        }
      },
      "PROFILE_DOC",
      state.file
    );
  };

  return (
    <div>
      {state.error ? (
        <Snackbar
          open={state.error}
          autoHideDuration={4000}
          onClose={() => {
            setState({ ...state, error: false });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => {
              setState({ ...state, error: false });
            }}
            severity={state.severity}
          >
            {state.message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <Dialog
        open={requestVerification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleRequestVerification}
          style={{ alignItem: "right" }}
        >
          {t("setting.requestVerificationC")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("setting.requestText")}
          </DialogContentText>
          <form className={classes.root} onSubmit={handleSubmit}>
            <FormControl>
              <InputLabel htmlFor="username">{t("auth.username")}</InputLabel>
              <Input id="username" value={user.userName} disabled />
            </FormControl>
            <br />
            <FormControl>
              <InputLabel htmlFor="fullname">
                {t("setting.fullName")}
              </InputLabel>
              <Input
                id="fullname"
                name="fullname"
                value={state.fullname}
                onChange={(event) => {
                  setState({
                    ...state,
                    [event.target.name]: event.target.value,
                  });
                }}
                required
              />
            </FormControl>
            <br />
            <FormControl>
              <InputLabel htmlFor="knownas">{t("setting.knownAs")}</InputLabel>
              <Input
                id="knownas"
                name="knownas"
                value={state.knownas}
                onChange={(event) => {
                  setState({
                    ...state,
                    [event.target.name]: event.target.value,
                  });
                }}
                required
              />
            </FormControl>
            <br />
            <FormControl>
              <InputLabel htmlFor="category">
                {t("setting.category")}
              </InputLabel>
              <Input
                id="category"
                name="category"
                value={state.category}
                onChange={(event) => {
                  setState({
                    ...state,
                    [event.target.name]: event.target.value,
                  });
                }}
                required
              />
            </FormControl>
            <br />
            <Button className={styles.icon} component="label">
              {" "}
              {t("setting.uploadPhoto")}
              {t("setting.uploadText")}
              <Box ml={24}>
                <ImageSearchIcon className={styles.icon} p={0.5} />
              </Box>
              <input type="file" onChange={handleFileUpload} hidden />
            </Button>
            {state.loading ? (
              <center>
                <CircularProgress className={styles.progressBar} />
              </center>
            ) : null}
            {state.file === "" ? (
              <button
                className={styles.disabledButton}
                style={{ borderRadius: "100px !important" }}
                type="submit"
                disabled
              >
                {t("setting.submitVerification")}
              </button>
            ) : (
              <button
                className={styles.submitButton}
                style={{ borderRadius: "100px !important" }}
                type="submit"
              >
                {t("setting.submitVerification")}
              </button>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default RequestVerification;
