import React, { useState, useEffect } from "react";
import styles from "../../styles/pages.module.scss";
import QuestHeader from "./questHeader/questHeader";
import QuestContent from "./questContent/questContent";
import { useSelector } from "react-redux";
import { GetQuests } from "../../services/questService";
import { useTranslation } from "react-i18next";

export default function Quest() {
  const [quests, setQuests] = useState();
  const { user } = useSelector((state) => state.root);
  const { t } = useTranslation();

  useEffect(() => {
    GetQuests((res) => {
      setQuests(res);
    });
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        {user && quests ? (
          <>
            {/* <ShoppingCartIcon color="primary" variant="contained" /> */}

            <QuestHeader user={user} t={t} />
            <QuestContent quests={quests} t={t} />
          </>
        ) : (
          <div className="loader"></div>
        )}
      </div>
    </>
  );
}
