import { createSlice } from "@reduxjs/toolkit";

const initState = "";
export const querySlice = createSlice({
  name: "comment",
  initialState: initState,
  reducers: {
    setComment: (state, action) => action.payload,

    resetComment: () => initState,
  },
});

export const { setComment, resetComment } = querySlice.actions;

export default querySlice.reducer;
