import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../styles/pages.module.scss";
import { GetSinglePost } from "../../services";
import PostCard from "../common/Posts/PostCard/PostCard";
import "../../styles/loader.css";
function ExploreId() {
  const [state, setState] = useState({
    isLoader: true,
    item: null,
    isopen: true,
  });
  const location = useLocation();
  useEffect(() => {
    const id = location.pathname.replace("/explore/", "");
    SinglePost(id);
  }, []);
  const SinglePost = (id) => {
    GetSinglePost((data) => {
      if (data && Object.keys(data).length > 0) {
        setState({
          ...state,
          isLoader: false,
          item: data,
        });
      }
    }, id);
  };

  console.log(state);
  return (
    <div className={styles.wrapper}>
      {state.isLoader ? (
        <div className="loader"></div>
      ) : (
        <PostCard postData={state.item} open={state} setClose={setState} />
      )}
    </div>
  );
}

export default ExploreId;
