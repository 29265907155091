import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styles from "./DataPolicy.module.scss";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#262626",
    marginTop: "5px",
    color: "#ffffff",
    width: "50%",
  },
  bullet: {
    display: "inline-block",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    color: "#ffffff",
  },
});

const DataPolicy = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div className={styles.parent}>
        <CardContent>
          <Typography className={styles.title}>
            {t("setting.dataPolicy").toUpperCase()}
          </Typography>
          <Typography className={styles.body}>
            {t("setting.dataPolicyLine")} <br />
            {t("setting.dataPolicyText")}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};
export default DataPolicy;
