import React from "react";
import {
  DialogTitle,
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  Slide,
  Container,
  Grid,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Input,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ActionsOnUsers } from "../../../services/profileService";
import { setComment } from "../../../redux/reducers/commentSlice";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import UserAvatar from "../UserAvatar/UserAvatar";

// ------------------------------Material css---------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));
export default function ShareDialogBox({ value, setvalue, obj }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = (theme) => ({
    root: {
      margin: 5,
      color: "gray",
      padding: theme.spacing(1),
      fontSize: "14px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(0),
      top: theme.spacing(-0.5),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  // ---------------------end material css --------------
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });

    var copyText = obj.substr(52, 200);
    navigator.clipboard.writeText(copyText);
    setTimeout(() => {
      setState({
        ...newState,
        open: false,
      });
    }, 2000);
  };
  const handleClose = () => {
    setvalue({
      ...value,
      openSharedialog: false,
    });
  };

  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          open={value}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          disableBackdropClick
        >
          <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
            Share Post
          </DialogTitle>

          <DialogContent style={{ height: "100px" }}>
            <div style={{ margin: "-10px" }}>
              <div>
                <div>
                  <Grid spacing={1}>
                    <Container>
                      <DialogContentText>{obj.substr(0, 51)}</DialogContentText>

                      <FormControl>{obj.substr(52, 200)}</FormControl>
                    </Container>
                  </Grid>
                </div>
              </div>
            </div>
            {state.open ? (
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                key={vertical + horizontal}
              >
                <Alert severity="success">Link are Copy Thank you!!</Alert>
              </Snackbar>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Container>
              <Button
                onClick={handleClick({ vertical: "top", horizontal: "center" })}
                variant="contained"
                color="primary"
                style={{ width: "inherit" }}
              >
                Copy
              </Button>
            </Container>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
