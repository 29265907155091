import FramedAvatar from "../../../../Omegastore/Corners/FramedAvatar";

const CornerAvatar = ({ source, profilePic, handleModalData }) => {
  return (
    <div
      onClick={() => {
        handleModalData(source);
      }}
    >
      <FramedAvatar source={source?.attachment?.url} profilePic={profilePic} />
    </div>
  );
};
export default CornerAvatar;
