import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import styles from "./ChangeUsername.module.scss";
import media from "../../../../../assets/images/media.svg";
import { UploadMedia } from "../../../../../services/mediaUploader";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import defaultImage from "../../../../../assets/images/defaultpic.jpeg";
import { CircularProgress } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { styled } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { UpdateProfile } from "../../../../../services/profileService";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

//-------------------------------- Material-ui styling start ---------------------------

const MySelect = styled(Select)({
  root: {
    color: "#808080",
    ".MuiSelect-icon": {
      color: "#fff", // for icon drop down icon color
    },
  },
  color: "#808080",
});

//-------------------------------- Material-ui styling end ---------------------------

const ChangeUsername = ({ open, handleClose, userProfile }) => {
  const { user } = useSelector((state) => state.root);

  let userName = JSON.parse(localStorage.getItem("user"));

  // Chop off the initial part of url  [https://omegame-profiles.s3.us-east-2.amazonaws.com/]
  let pic = user?.pic;
  let slicedUrl = pic?.slice(52);
  const [state, setState] = useState({
    file: "",
    picUrlServer: user?.pic || "",
    userName: userName?.userName || "",
    favConsole: userProfile ? userProfile.favouriteConsole : "",
    favGame: userProfile ? userProfile.favouriteGame : "",
    gender: userProfile?.gender || "",
    dob: user?.dateOfBirth || new Date("2015-08-18T21:11:54"),
    name: user
      ? user.firstName
        ? user.firstName + " " + user.lastName
        : ""
      : "",
    loading: false,
    error: false,
    severity: "",
    message: "",
    bio: user?.bio || "",
  });

  const handleFileUpload = (event) => {
    setState((prev) => ({
      ...prev,
      file: event.target.files[0],
      picUrlServer: URL.createObjectURL(event.target.files[0]),
    }));
  };

  //profile Update Api call
  async function updateProfileHandler(formData) {
    return new Promise((resolve, reject) => {
      UpdateProfile((res) => {
        if (res) {
          setState({ ...state, loading: false });
          localStorage.removeItem("url");
          resolve(true);
        } else {
          setState({ ...state, error: true, loading: false });
          resolve(false);
        }
      }, formData);
    });
  }
  //-------------------------------- hanldeSubmit Start -----------------------------------/

  const handleSubmit = (event) => {
    event.preventDefault();
    let fullName = state.name.split(" ");
    setState({ ...state, loading: true });
    if (state.file !== "") {
      //---------------- if user try to update his/her profile with uploading picture -------------------//

      UploadMedia(
        (res) => {
          if (res) {
            setState({
              ...state,
              picUrlServer: res.data.data.media[0].url,
              loading: false,
            });
            localStorage.removeItem("url");
            localStorage.setItem("url", res.data.data.media[0].url);

            let formData = {};
            if (state.bio) {
              formData = { ...formData, bio: state.bio };
            }
            if (state.dob) {
              formData = { ...formData, dateOfBirth: state.dob };
            }
            if (state.xbox) {
              formData = { ...formData, favoriteConsole: state.xbox };
            }
            if (state.favoriteGame) {
              formData = { ...formData, favouriteGame: state.xbox };
            }
            if (fullName[0]) {
              formData = { ...formData, firstName: fullName[0] };
            }
            if (state.gender) {
              formData = { ...formData, gender: state.gender };
            }
            if (fullName[1]) {
              formData = { ...formData, lastName: fullName[1] };
            }
            if (localStorage.getItem("url")) {
              formData = { ...formData, pic: localStorage.getItem("url") };
            }
            formData = { ...formData, userName: state.userName };
            updateProfileHandler(formData)
              .then((res) => {
                if (res) {
                  setState({
                    ...state,
                    error: true,
                    severity: "success",
                    message: "Changed user name Successfully",
                    loading: false,
                  });
                  setTimeout(() => {
                    handleClose();
                  }, 3000);
                } else {
                  setState({
                    ...state,
                    error: true,
                    severity: "error",
                    message: "Internal Server Error!",
                    loading: false,
                  });
                }
              })
              .catch((err) => {
                setState({
                  ...state,
                  error: true,
                  severity: "error",
                  message: "Internal Server Error!",
                  loading: false,
                });
              });
          }
        },
        "PROFILE",
        state.file
      );
    } else {
      //---------------- if user try to update his/her profile with already uploaded picture Picture -------------------//

      localStorage.removeItem("url");
      localStorage.setItem("url", slicedUrl);
      let formData = {};
      if (state.bio) {
        formData = { ...formData, bio: state.bio };
      }
      if (state.dob) {
        formData = { ...formData, dateOfBirth: state.dob };
      }
      if (state.xbox) {
        formData = { ...formData, favoriteConsole: state.xbox };
      }
      if (state.favoriteGame) {
        formData = { ...formData, favouriteGame: state.xbox };
      }
      if (fullName[0]) {
        formData = { ...formData, firstName: fullName[0] };
      }
      if (state.gender) {
        formData = { ...formData, gender: state.gender };
      }
      if (fullName[1]) {
        formData = { ...formData, lastName: fullName[1] };
      }
      if (localStorage.getItem("url")) {
        formData = { ...formData, pic: localStorage.getItem("url") };
      }
      formData = { ...formData, userName: state.userName };
      updateProfileHandler(formData)
        .then((res) => {
          if (res) {
            setState({
              ...state,
              error: true,
              severity: "success",
              message: "Changed user name Successfully",
              loading: false,
            });
            setTimeout(() => {
              handleClose();
            }, 3000);
          } else {
            setState({
              ...state,
              error: true,
              severity: "error",
              message: "Internal Server Error!",
              loading: false,
            });
          }
        })
        .catch((err) => {
          setState({
            ...state,
            error: true,
            severity: "error",
            message: "Internal Server Error!",
            loading: false,
          });
        });
    }
  };
  //--------------------------handleSubmit End --------------------------/

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Username</DialogTitle>
        <DialogContent style={{ width: "400px" }}>
          <form onSubmit={handleSubmit}>
            <div className={styles.wrapper}>
              <div className={styles.parent}>
                {/*---------if User has selected corners around the image------------*/}
                {user?.corner ? (
                  <div>
                    <div className={styles.image1}>
                      <UserAvatar source={user?.corner} size={"200px"} />
                    </div>
                    <div className={styles.image2}>
                      <UserAvatar source={state.picUrlServer} size={"150px"} />
                      <Button
                        component="label"
                        style={{
                          marginLeft: "-60px",
                        }}
                      >
                        <img src={media} alt="media" />
                        <input
                          accept="image/*"
                          type="file"
                          hidden
                          onChange={handleFileUpload}
                        />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className={styles.image3}>
                    {/*---------if User has not selected corners around the image------------*/}

                    <UserAvatar
                      source={state?.picUrlServer || defaultImage}
                      size={"150px"}
                    />
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        marginLeft: "-60px",
                      }}
                    >
                      <img src={media} alt="media" />
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleFileUpload}
                      />
                    </Button>
                  </div>
                )}
              </div>

              <div className={styles.parentTextfield}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div className={styles.alignTextfield}>
                      <TextField
                        type="text"
                        value={state.userName}
                        label="Username"
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            userName: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className={styles.alignTextfield}>
                      <TextField
                        type="text"
                        value={state.name}
                        label="Name"
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.alignTextfield}>
                      <TextField
                        type="text"
                        value={state.bio}
                        label="Bio"
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            bio: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className={styles.alignTextfield}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="Date of Birth"
                          format="MM/dd/yyyy"
                          value={state.dob}
                          className={styles.fieldWidth}
                          onChange={(date) => {
                            setState((prev) => ({ ...prev, dob: date }));
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.alignTextfield}>
                      <TextField
                        type="text"
                        value={state.favConsole}
                        label="Favorite Console"
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            favConsole: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className={styles.alignTextfield}>
                      <TextField
                        type="text"
                        value={state.favGame}
                        label="Favorite Game"
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            favGame: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.alignTextfield}>
                      <FormControl className={styles.divTextField}>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ color: "#808080" }}
                        >
                          Gender
                        </InputLabel>
                        <MySelect
                          labelId="demo-simple-select-label"
                          label="Gender"
                          id="demo-simple-select"
                          className={styles.fieldWidth}
                          value={state.gender}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              gender: e.target.value,
                            }))
                          }
                        >
                          <MenuItem value={"MALE"}>Male</MenuItem>
                          <MenuItem value={"FEMALE"}>Female</MenuItem>
                          <MenuItem
                            value={"RATHER NOT SAY"}
                            className={styles.divTextField}
                          >
                            Rather not say
                          </MenuItem>
                        </MySelect>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ marginTop: "25px" }}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className={styles.submitButton}
              style={{ marginTop: "25px" }}
              disabled={state.picUrlServer !== "" ? false : true}
            >
              {state.loading ? <CircularProgress /> : "Submit"}
            </Button>
          </form>
          {state.error ? (
            <Snackbar
              open={state.error}
              autoHideDuration={4000}
              onClose={() => {
                setState({ ...state, error: false });
              }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={() => {
                  setState({ ...state, error: false });
                }}
                severity={state.severity}
              >
                {state.message}
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ChangeUsername;
