import { ReactComponent as Logo } from "../../../../../assets/images/translate.svg";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "../Setting.module.scss";

const SwitchToItalian = ({ isItalian, handleChangeItalian, t }) => {
  console.log(isItalian);
  return (
    <div className={styles.notifContainer}>
      <div className={styles.notifInnerContainer}>
        <div className={styles.notifIconContainer}>
          <div className={styles.notifImageInnerContainer}>
            <Logo className={styles.notifImageStyles} />
          </div>
        </div>
        <div className={styles.textContainer}>
          <p style={{ color: "#868686" }}>{t("setting.switch")}</p>
        </div>
      </div>
      <div className={styles.toggleButtonContainer}>
        <div className={styles.toggleButtonInnerContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={isItalian}
                onChange={handleChangeItalian}
                name="checkedPrivate"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
export default SwitchToItalian;
