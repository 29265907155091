import React from "react";
import coin from "../../../assets/images/coin.svg";
import buy from "../../../assets/images/buy.svg";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import headerBg from "../../../assets/images/sample-profile-header.png";
import { useHistory } from "react-router-dom";
import { largeNumberShortify } from "../../../utils/AppHelperMethods";
// import GameAvatar from '../common/GameAvatar/GameAvatar';
// import arrow from '../../assets/images/sample-profile-header.png'
import styles from "./questHeader.module.scss";
export default function QuestHeader({ user, t }) {
  let history = useHistory();
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.header}
          style={{
            backgroundImage: user?.cover
              ? `url(${user.cover})`
              : `url(${headerBg})`,
          }}
        >
          <div className={styles.alignStore}>
            <div
              className={styles.omegastore}
              onClick={() => {
                history.push({
                  pathname: "omega-store",
                  state: { data: user, tab: 0 },
                });
              }}
            >
              <img src={buy} alt="omegastore"></img>
              Omegastore
            </div>
          </div>
          <div className={styles.headerContent}>
            <div className={styles.parent}>
              <UserAvatar size="200px" innerImageSize="180px" user={user} />
            </div>
            <div className={styles.levelBar}>
              <div className={styles.coins}>
                <div className={styles.coinIcon}>
                  <img src={coin} alt="coins" />
                </div>
                <div className={styles.coinText}>
                  {largeNumberShortify(user?.earnedCoins)}
                </div>
              </div>
              <div className={styles.progressBar}></div>
              <div className={styles.xpPoints}>{user?.earnedXps}/100</div>
            </div>
            <div className={styles.userInfo}>
              <div className={styles.userDetails}>
                <div className={styles.nameWrapper}>
                  <div>
                    <div className={styles.username}>
                      {user?.userName || user?.firstName}
                    </div>
                  </div>
                  {/* {user?.isVerified && <img src={verified} alt="verified" />} */}
                </div>
                <div
                  className={styles.nickname}
                  style={{ color: user.nickNameColor }}
                >
                  {user?.nickName}
                </div>
              </div>
              <div className={styles.level}>
                <div className={styles.levelHeading}>
                  {t("dashboard.level")}
                </div>
                <div className={styles.currentLevel}>{user?.level}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
