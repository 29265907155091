import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Container,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import style from "./Impersonation.module.scss";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import TextField from "@material-ui/core/TextField";
import { ReportIssueOrSpam } from "../../../../../services/reportService";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import Box from "@material-ui/core/Box";
import { UploadMedia } from "../../../../../services/mediaUploader";
import { CircularProgress } from "@material-ui/core";
import ShowError from "../../../../error/ShowError";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const Impersonation = ({ open, handleIssueScreen }) => {
  const [values, setValues] = useState({
    problem: "",
    username: "",
    email: "",
    file: "",
    loading: false,
    error: false,
    severity: "",
    response: "",
  });
  let user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => {
    setValues({ ...values, error: !values.error });
  };
  const handleFileUpload = (event) => {
    setValues((prev) => ({ ...prev, file: event.target.files[0] }));
  };
  const handleReport = (event) => {
    event.preventDefault();
    setValues({ ...values, loading: true });
    UploadMedia(
      (res) => {
        if (res) {
          localStorage.removeItem("reportDoc");
          localStorage.setItem("reportDoc", res.data.data.media[0].url);
          let payload = {
            reportType: "IMPERSONATION",
            description: values.problem,
            reporterEmail: values.email,
            targetEntity: "user",
            userName: values.username,
            attachments: [localStorage.getItem("reportDoc")],
          };

          ReportIssueOrSpam(payload).then((res) => {
            setValues({
              ...values,
              response: "Thank you for your feedback!!",
              severity: "success",
              loading: false,
              error: true,
            });
            setTimeout(() => {
              setValues({
                ...values,
                response: "",
                problem: "",
                loading: false,
              });
              handleIssueScreen();
            }, 3000);
          });
        } else {
          setValues({
            ...values,
            error: true,
            response: "Please Try again",
            severity: "error",
            loading: false,
          });
        }
      },
      "PROFILE_DOC",
      values.file
    );
  };
  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        className={style.body}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleIssueScreen}>
          Are you being impersonated?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Omegame takes safety seriously. If someone created an account
            pretending to be you , you can report it to us. Make sure to provide
            all requested information, including a photo of your ID. We only
            repond to reports sent to us from the person who's being
            impersonated
          </Typography>
        </DialogContent>
        <DialogContent dividers>
          <form onSubmit={handleReport}>
            <Typography gutterBottom>What do you want to report?.</Typography>
            <TextField
              id="standard-basic"
              label="Username of account you are reporting?"
              style={{ width: "400px" }}
              onChange={(event) => {
                setValues({ ...values, username: event.target.value });
              }}
              required
            />
            <Typography gutterBottom className={style.textMargin}>
              Please provide your email. We'll use it to contact you.
            </Typography>
            <TextField
              id="standard-basic"
              label="Enter your email?"
              type="email"
              style={{ width: "400px" }}
              onChange={(event) => {
                setValues({ ...values, email: event.target.value });
              }}
              required
            />
            <br />
            <Button className={style.textMargin} component="label">
              {" "}
              Please upload a photo of your ID
              <Box ml={24}>
                <ImageSearchIcon p={0.5} />
              </Box>
              <input type="file" onChange={handleFileUpload} hidden />
            </Button>
            <div className={style.textMargin}>
              {/* <InputLabel htmlFor="standard-adornment-amount">
                Amount
              </InputLabel>
              <Input
                id="standard-adornment-amount"
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              /> */}

              <UserAvatar source={user?.pic} size={"40px"} />
              <TextField
                id="standard-basic"
                label="What went Wrong?"
                style={{ marginLeft: "10px", width: "400px" }}
                onChange={(event) => {
                  setValues({ ...values, problem: event.target.value });
                }}
                required
              />
            </div>
            {values.error ? (
              <ShowError
                open={values.error}
                handleClose={handleClose}
                severity={values.severity}
                message={values.response}
              />
            ) : (
              ""
            )}
            {values.loading ? (
              <center>
                <CircularProgress className={styles.progressBar} />
              </center>
            ) : null}
            <Container>
              <Button
                variant="contained"
                color="primary"
                className={style.reportButton}
                type="submit"
                disabled={values.file !== "" ? false : true}
              >
                Report
              </Button>
            </Container>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Impersonation;
