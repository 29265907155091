import { createSlice } from '@reduxjs/toolkit';

const initState = []

export const exploreFeedSlice = createSlice({
    name: 'exploreFeed',
    initialState: initState,
    reducers: {
        setExploreFeed: (state, action) => action.payload,
        resetExploreFeed: () => initState,
    },
});

export const {
    setExploreFeed,
    resetExploreFeed
} = exploreFeedSlice.actions;

export default exploreFeedSlice.reducer;