import Grid from "@material-ui/core/Grid";
import FramedAvatar from "./FramedAvatar";
import styles from "./Corners.module.scss";
import coin from "../../../assets/images/coin.svg";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(1),
  },
}));
const CornerContainer = ({ data, handleModalData, user, color }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.control}>
      <div
        className={styles.parentContainer}
        onClick={() => {
          handleModalData(data);
        }}
      >
        <div className={styles.container}>
          <div className={styles.centerItem}>
            <FramedAvatar source={data.attachment.url} profilePic={user?.pic} />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.centerItem} style={{ color: color }}>
            <p>{data.name}</p>
            <img
              src={coin}
              alt="coin"
              style={{ height: "10px", width: "10px", marginLeft: "15px" }}
            />{" "}
            * {data.priceInCoins}
          </div>
        </div>
      </div>
    </Grid>
  );
};
export default CornerContainer;
