import { setHomeFeed } from "../redux/reducers/homeFeedSlice";
import { setExploreFeed } from "../redux/reducers/exploreFeedSlice";
import { setMyAssets } from "../redux/reducers/myAssetsSlice";
import { setSavedPosts } from "../redux/reducers/savedPostsSlice";
import { setUserProfileData } from "../redux/reducers/userProfileDataSlice";
import { store } from "../redux/store";
/* --------------------------- Comment Count--------------------------------- */
const updateCommentFromRduxStore = (postid) => {
  let temphomefeed = [...store.getState().root.exploreFeed];
  var ind = temphomefeed.findIndex((i) => i._id === postid);

  if (ind > -1) {
    var convertArr = [];

    if (
      temphomefeed[ind]?.computed?.find((i) => i.key === "COMMENTS") &&
      Object.keys(
        temphomefeed[ind]?.computed?.find((i) => i.key === "COMMENTS")
      ).length > 0 &&
      temphomefeed[ind]?.computed?.find((i) => i.key === "COMMENTS").key ===
        "COMMENTS"
    ) {
      temphomefeed[ind].computed.forEach((element, ix) => {
        var cloneResp = { ...temphomefeed[ind] };

        if (element.key === "COMMENTS") {
          var code = {
            ...temphomefeed[ind].computed[ix],
            key: element.key,
            value: element.value + 1,
          };
          convertArr.push(code);
          cloneResp["computed"] = convertArr;
          temphomefeed[ind] = cloneResp;
        } else {
          var code = {
            ...temphomefeed[ind].computed[ix],
            key: element.key,
            value: element.value,
          };
          convertArr.push(code);
          cloneResp["computed"] = convertArr;
          temphomefeed[ind] = cloneResp;
        }
      });
    } else {
      var cloneResp = { ...temphomefeed[ind] };
      temphomefeed[ind].computed.forEach((element, ix) => {
        var code = {
          ...temphomefeed[ind].computed[ix],
          key: element.key,
          value: element.value,
        };
        convertArr.push(code);
      });
      var obj = { key: "COMMENTS", value: 1 };
      convertArr.push(obj);
      cloneResp["computed"] = convertArr;
      temphomefeed[ind] = cloneResp;
    }

    store.dispatch(setExploreFeed(temphomefeed));
  }
};
/* --------------------------- Share Count--------------------------------- */
const updateShareFromReduxStore = (postid) => {
  let temphomefeed = [...store.getState().root.exploreFeed];
  var ind = temphomefeed.findIndex((i) => i._id === postid);

  if (ind > -1) {
    var convertArr = [];
    if (
      temphomefeed[ind]?.computed?.find((i) => i.key === "SHARE") &&
      Object.keys(temphomefeed[ind]?.computed?.find((i) => i.key === "SHARE"))
        .length > 0 &&
      temphomefeed[ind]?.computed?.find((i) => i.key === "SHARE").key ===
        "SHARE"
    ) {
      temphomefeed[ind].computed.forEach((element, ix) => {
        var cloneResp = { ...temphomefeed[ind] };
        if (element.key === "SHARE") {
          var code = {
            ...temphomefeed[ind].computed[ix],
            key: element.key,
            value: element.value + 1,
          };
          convertArr.push(code);
          cloneResp["computed"] = convertArr;
          temphomefeed[ind] = cloneResp;
        } else {
          var code = {
            ...temphomefeed[ind].computed[ix],
            key: element.key,
            value: element.value,
          };
          convertArr.push(code);
          cloneResp["computed"] = convertArr;
          temphomefeed[ind] = cloneResp;
        }
      });
    } else {
      var cloneResp = { ...temphomefeed[ind] };
      temphomefeed[ind].computed.forEach((element, ix) => {
        var code = {
          ...temphomefeed[ind].computed[ix],
          key: element.key,
          value: element.value,
        };
        convertArr.push(code);
      });
      var obj = { key: "SHARE", value: 1 };
      convertArr.push(obj);
      cloneResp["computed"] = convertArr;
      temphomefeed[ind] = cloneResp;
    }
    store.dispatch(setExploreFeed(temphomefeed));
  }
};

/*--------------------------- Update Explore Store --------------------------------------*/
const updateExploreStore = (obj) => {
  let temphomefeed = [...store.getState().root.exploreFeed];
  var ind = temphomefeed.findIndex((i) => i._id === obj._id);
  if (ind > -1) {
    temphomefeed[ind] = obj;
    store.dispatch(setExploreFeed(temphomefeed));
  }
};
/*--------------------------- Delete Explore Store --------------------------------------*/
const DeletePostExploreStore = (id) => {
  let temphomefeed = [...store.getState().root.exploreFeed];
  var ind = temphomefeed.findIndex((i) => i._id === id);
  if (ind > -1) {
    temphomefeed.splice(ind, 1);
    store.dispatch(setExploreFeed(temphomefeed));
    console.log("delete psot", temphomefeed, ind);
  }
};
const UpdatePostFromReduxStore = (newPost) => {
  /*-----------UPDATE USER PROFILE TABS DATA START-------------*/
  let tempuserProfileData = { ...store.getState().root.userProfileData };

  let newPosts = [...tempuserProfileData.posts.slice()];
  let newMedia = [...tempuserProfileData.media.slice()];
  let newReviews = [...tempuserProfileData.reviews.slice()];
  let newUsers = [...tempuserProfileData.taggedInPosts.slice()];

  let tempuserProfileDataPostsIndex = newPosts?.findIndex(
    (ii) => ii?._id === newPost?._id
  );
  let tempuserProfileDataMediaIndex = newMedia?.findIndex(
    (ii) => ii?._id === newPost?._id
  );
  let tempuserProfileDataReviewsIndex = newReviews?.findIndex(
    (ii) => ii?._id === newPost?._id
  );
  let tempuserProfileDataUsersIndex = newUsers?.findIndex(
    (ii) => ii?._id === newPost?._id
  );

  if (tempuserProfileDataPostsIndex > -1) {
    newPosts[tempuserProfileDataPostsIndex] = { ...newPost };
    tempuserProfileData = { ...tempuserProfileData, posts: newPosts };
  }

  if (tempuserProfileDataMediaIndex > -1) {
    newMedia[tempuserProfileDataMediaIndex] = { ...newPost };
    tempuserProfileData = { ...tempuserProfileData, media: newMedia };
  }

  if (tempuserProfileDataReviewsIndex > -1) {
    newReviews[tempuserProfileDataReviewsIndex] = { ...newPost };
    tempuserProfileData = { ...tempuserProfileData, reviews: newReviews };
  }

  if (tempuserProfileDataUsersIndex > -1) {
    newUsers[tempuserProfileDataUsersIndex] = { ...newPost };
    tempuserProfileData = { ...tempuserProfileData, taggedInPosts: newUsers };
  }

  /*-----------UPDATE USER PROFILE TABS DATA END-------------*/

  /*
   ***
   ***
   ***
   ***
   */

  /*-----------HOME FEED AND SAVED POSTS TABS DATA START-------------*/

  var tempHomeFeeds = [...store.getState().root.homeFeed];
  var tempSavedPosts = [...store.getState().root.savedPosts];

  // home feed and saved posts
  let foundIndexHomeFeed = tempHomeFeeds.findIndex(
    (ii) => ii?._id === newPost?._id
  );
  let foundIndexSavedPosts = tempSavedPosts.findIndex(
    (ii) => ii?._id === newPost?._id
  );

  // -- home feed and saved posts
  if (foundIndexHomeFeed > -1)
    tempHomeFeeds[foundIndexHomeFeed] = { ...newPost };

  if (foundIndexSavedPosts > -1)
    tempSavedPosts[foundIndexSavedPosts] = { ...newPost };

  /*-----------HOME FEED AND SAVED POSTS TABS DATA END-------------*/

  /*
   ***
   ***
   ***
   ***
   ***
   */

  if (store.getState()?.root?.user?._id === newPost?.createdBy?._id)
    store.dispatch(setUserProfileData(tempuserProfileData));
  store.dispatch(setHomeFeed(tempHomeFeeds));
  store.dispatch(setSavedPosts(tempSavedPosts));
};

const AddPostToReduxStore = (newPost) => {
  // let tempHomeFeeds = [...store.getState().root.homeFeed];
  // tempHomeFeeds = [newPost, ...tempHomeFeeds]

  let tempuserProfileData = { ...store.getState().root.userProfileData };

  let newPosts = [...tempuserProfileData.posts.slice()];
  newPosts?.unshift(newPost);
  if (store.getState()?.root?.user?._id === newPost?.createdBy?._id)
    store.dispatch(setUserProfileData({ posts: newPosts }));
  // store.dispatch(setHomeFeed(tempHomeFeeds));
};

const RemovePostFromReduxStore = (postID) => {
  let tempuserProfileData = { ...store.getState().root.userProfileData };
  const tempHomeFeeds = [...store.getState().root.homeFeed];
  const tempSavedPosts = [...store.getState().root.savedPosts];

  let tempUserProfilePosts = [...tempuserProfileData.posts.slice()];
  let tempUserProfileMedia = [...tempuserProfileData.media.slice()];

  store.dispatch(
    setUserProfileData({
      posts: tempUserProfilePosts.filter((ii) => ii?._id !== postID),
      media: tempUserProfileMedia.filter((ii) => ii?._id !== postID),
    })
  );

  store.dispatch(setHomeFeed(tempHomeFeeds.filter((ii) => ii?._id !== postID)));
  store.dispatch(
    setSavedPosts(tempSavedPosts.filter((ii) => ii?._id !== postID))
  );
};

const RemovePostsOfUserFromReduxStore = (userID) => {
  const tempHomeFeeds = [...store.getState().root.homeFeed];
  const tempSavedPosts = [...store.getState().root.savedPosts];

  store.dispatch(
    setHomeFeed(tempHomeFeeds.filter((ii) => ii?.createdBy?._id !== userID))
  );
  store.dispatch(
    setSavedPosts(tempSavedPosts.filter((ii) => ii?.createdBy?._id !== userID))
  );
};

const AddAssetBackground = (newBackground) => {
  let tempBackgrounds = { ...store.getState().root.myAssets };
  tempBackgrounds.backgrounds = [newBackground, ...tempBackgrounds.backgrounds];

  store.dispatch(setMyAssets(tempBackgrounds));
};

const AddAssetNickname = (newNickname) => {
  let tempNicknames = { ...store.getState().root.myAssets };
  tempNicknames.nicknames = [newNickname, ...tempNicknames.nicknames];

  store.dispatch(setMyAssets(tempNicknames));
};

const AddAssetCorner = (newCorner) => {
  let tempCorners = { ...store.getState().root.myAssets };
  tempCorners.corners = [newCorner, ...tempCorners.corners];

  store.dispatch(setMyAssets(tempCorners));
};
const ProfileReview = (obj) => {
  let temphomeProfileReview = { ...store.getState().root.userProfileData };
  let CloneReviews = [...temphomeProfileReview.reviews.slice()];
  let tempuserProfileDataReviewsIndex = CloneReviews?.findIndex(
    (ii) => ii?._id === obj._id
  );

  if (tempuserProfileDataReviewsIndex > -1) {
    let change_resp = {
      ...CloneReviews[tempuserProfileDataReviewsIndex],
      devices: obj?.devices,
      ratings: obj?.ratings,
      feedback: obj?.feedback,
    };

    CloneReviews[tempuserProfileDataReviewsIndex] = change_resp;
    temphomeProfileReview = { ...temphomeProfileReview, reviews: CloneReviews };
    store.dispatch(setUserProfileData(temphomeProfileReview));
  }
};
const DeleteProfileReview = (obj) => {
  let temphomeProfileReview = { ...store.getState().root.userProfileData };
  let CloneReviews = [...temphomeProfileReview.reviews.slice()];
  let tempuserProfileDataReviewsIndex = CloneReviews?.findIndex(
    (ii) => ii?._id === obj._id
  );

  if (tempuserProfileDataReviewsIndex > -1) {
    CloneReviews.splice(tempuserProfileDataReviewsIndex, 1);
    temphomeProfileReview = { ...temphomeProfileReview, reviews: CloneReviews };
    store.dispatch(setUserProfileData(temphomeProfileReview));
  }
};

export {
  AddAssetBackground,
  AddAssetCorner,
  AddAssetNickname,
  AddPostToReduxStore,
  RemovePostFromReduxStore,
  UpdatePostFromReduxStore,
  RemovePostsOfUserFromReduxStore,
  updateCommentFromRduxStore,
  updateShareFromReduxStore,
  updateExploreStore,
  DeletePostExploreStore,
  ProfileReview,
  DeleteProfileReview,
};
