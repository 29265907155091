import React from "react";
import {
  GetCommentsOfPost,
  DeleteCommentOfPost,
} from "../../../../../services";
import { useSelector, useDispatch } from "react-redux";
import {
  resetComment,
  setComment,
} from "../../../../../redux/reducers/commentSlice";
import { CircularProgress } from "@material-ui/core";
import CommentItem from "./listItem";
import _ from "lodash";

import {
  // EditorState,
  ContentState,
  Modifier,
  convertFromRaw,
  convertToRaw,
  SelectionState,
  forceSelection,
} from "draft-js";
export default function ListComment({
  nstate,
  nsetstate,
  PostInfo,
  editorState,
  setEditorState,
  EditorState,
}) {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    loading: true,
    loadingComment: true,
    limitComment: 5,
    allComment: [],
    length: 5,
    dummyComment: [],
    cursor: "",
    localcursor: "",
    allresponse: {},
    message: "",
    dummLoading: false,
  });
  const [Cmntstate, Cmntsetstate] = React.useState({
    user: "",
    comment: "",
    currentFocus: null,
    mentionUser: [],
  });

  //
  const { comment } = useSelector((state) => state.root);
  function getCommentHelper(cursor) {
    if (!cursor) {
      GetCommentsOfPost(
        (commentResponse) => {
          if (
            commentResponse &&
            commentResponse?.data &&
            commentResponse?.data.length > 0
          ) {
            let temp = [...state.allComment];

            // let length = temp.length;
            commentResponse?.data.forEach((element) => {
              let check = temp.findIndex((i) => i._id === element?._id);
              if (check > -1) {
                temp[check] = element;
              } else {
                temp = [element, ...temp];
              }
            });
            setState({
              ...state,
              loading: false,
              loadingComment: false,
              allresponse: commentResponse,
              allComment: temp,
              cursor: "",
              dummLoading: false,
            });

            setTimeout(() => {
              dispatch(resetComment());
            });
          } else {
            setState({
              ...state,
              cursor: "",
              loading: false,
              loadingComment: false,
              dummLoading: false,
              message: commentResponse?.message,
              allComment: commentResponse?.data,
            });
          }
        },
        state.cursor,
        state.limitComment,
        PostInfo._id
      );
    }
  }
  React.useEffect(() => {
    getCommentHelper(false);
    setState({
      ...state,
      dummLoading: true,
      cursor: "",
    });
  }, [comment, state.length]);
  const handleComment = (id) => {
    setState({
      ...state,
      selected: id,
    });
  };

  const handleEdit = (eidobj) => {
    setEditorState(() =>
      EditorState.createWithContent(ContentState.createFromText(eidobj?.text))
    );
    console.log(eidobj);
    nsetstate({
      ...nstate,
      comment: eidobj?.text,
      eidId: eidobj?._id,
      mentionUser: eidobj?.mentions,
    });
  };
  const handleDelete = (delId) => {
    DeleteCommentOfPost(() => {
      let clonestate = [...state.allComment];
      let findInd = clonestate.findIndex((i) => i._id === delId?._id);
      if (findInd > -1) {
        clonestate.splice(findInd, 1);
        setState({
          ...state,
          allComment: clonestate,
        });
      }
    }, delId?._id);
  };
  return (
    <>
      {state.loadingComment ? (
        <CircularProgress />
      ) : state?.allComment && state?.allComment.length > 0 ? (
        <>
          {state?.allComment.map((resp, indx) => {
            return (
              <>
                <CommentItem
                  stte={state}
                  handleComment={handleComment}
                  resp={resp}
                  key={indx}
                  handleEdit={() => handleEdit(resp)}
                  handleDelete={handleDelete}
                  Cmntstate={Cmntstate}
                  Cmntsetstate={Cmntsetstate}
                />
              </>
            );
          })}
          {state.dummLoading ? (
            <div style={{ textAlign: "center" }}>
              {" "}
              <CircularProgress />{" "}
            </div>
          ) : state.cursor !== "" ? (
            <>
              {state.allresponse?.cursor !== "" ? (
                <>
                  <div
                    style={{
                      marginLeft: "3.5rem",
                      marginTop: "1rem",
                      color: "gray",
                    }}
                    onClick={() => {
                      setState({
                        ...state,
                        // limitComment: 5,
                        length: state.length + 5,
                        cursor: state.allresponse?.cursor,
                        localcursor: state.allresponse?.cursor,
                        dummLoading: true,
                      });
                    }}
                  >
                    Read More
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : state.localcursor != "" ? (
            <div
              style={{
                marginLeft: "3.5rem",
                marginTop: "1rem",
                color: "gray",
              }}
              onClick={() => {
                setState({
                  ...state,
                  // limitComment: 5,
                  localcursor: state.allresponse?.cursor,
                  length: state.length + 5,
                  cursor: state.allresponse?.cursor,
                  dummLoading: true,
                });
              }}
            >
              {console.log(state.length)}
              Read More
            </div>
          ) : state.length === state.allComment.length &&
            state.allresponse?.cursor != "" ? (
            <div
              style={{
                marginLeft: "3.5rem",
                marginTop: "1rem",
                color: "gray",
              }}
              onClick={() => {
                setState({
                  ...state,
                  // limitComment: 5,
                  localcursor: state.allresponse?.cursor,
                  length: state.length + 5,
                  cursor: state.allresponse?.cursor,
                  dummLoading: true,
                });
              }}
            >
              Read More
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        "No Comment Found"
      )}
    </>
  );
}
