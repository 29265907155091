import React, { useState } from "react";
import ToolbarButton from "../ToolbarButton";

import "./Compose.css";

export default function Compose({ onSend, rightItems, t }) {
  const [message, setMessage] = useState("");
  const sendMessage = (e) => {
    if (e.key === "Enter") {
      onSend(message);
      setMessage("");
    }
  };
  return (
    <div className="compose">
      <input
        type="text"
        className="compose-input"
        placeholder={t("chat.typeMessage")}
        value={message}
        onChange={(res) => {
          setMessage(res.target.value);
        }}
        onKeyDown={sendMessage}
      />
      {/* <ToolbarButton icon="ion-ios-send" /> */}
      <div
        onClick={() => {
          onSend(message);
          setMessage("");
        }}
      >
        {rightItems}
      </div>
    </div>
  );
}
