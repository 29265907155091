import React, { useState } from "react";
import styles from "./UserReview.module.scss";
import UserAvatar from "../UserAvatar/UserAvatar";
import verified from "../../../assets/images/verified.svg";
import GameAvatar from "../GameAvatar/GameAvatar";
import EditGameReview from "../../common/dialogbox/EditGameReview";
import moment from "moment";
import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";
import reportIcon from "../../../assets/images/report.svg";
import ReportDialogBox from "../dialogbox/reportDialogbox";
const UserReview = ({
  profile,
  game,
  handleDeleteReview,
  user,
  handleReload,
  t,
}) => {
  const [toggle, setToggle] = useState(false);
  const [report, setReport] = useState(false);
  const handleToggleReview = () => {
    handleReload();
    setToggle(!toggle);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.reviewHeader}>
        {profile && (
          <div className={styles.reviewer}>
            <UserAvatar
              user={profile?.createdBy}
              size={"60px"}
              innerImageSize="53px"
            />
            <div className={styles.info}>
              <div className={styles.infoTop}>
                <div className={styles.name}>
                  {profile?.createdBy?.userName}
                </div>
                {profile?.createdBy?.isVerified && (
                  <img src={verified} alt="verified" />
                )}
                <div className={styles.followers}>
                  {profile?.createdBy?.level}
                </div>
              </div>
              <div className={styles.nickname}>
                {profile?.createdBy?.nickName}
              </div>
            </div>
          </div>
        )}
        {game && (
          <div className={styles.item}>
            <GameAvatar size={"60px"} rounded />

            <div className={styles.itemInfo}>
              <div className={styles.name}>Game Name</div>
              <div className={styles.type}>Console Game</div>
              <div className={styles.date}>Release Date: DD month YYYY</div>
            </div>
          </div>
        )}
        <div
          className={`${styles.itemRating} ${
            profile?.ratings != undefined && profile?.ratings > 5
              ? styles.item_success_rating
              : styles.item_success_rating_danger
          }`}
        >
          <div className={styles.ratingHeading}>
            {profile?.devices != undefined && profile?.devices[0]}
          </div>
          <div className={styles.ratingPoints}>
            {parseFloat(profile?.ratings || 0)?.toFixed(2)}
          </div>
        </div>
        {user._id === profile?.createdBy?._id ? (
          <>
            <div
              className={styles.deleteIcon}
              onClick={() => {
                handleDeleteReview(profile._id);
              }}
            >
              <img src={deleteIcon} alt="deleteIcon" />
            </div>
            <div
              className={styles.editIcon}
              onClick={() => {
                handleToggleReview();
              }}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
          </>
        ) : null}
        <div
          className={styles.deleteIcon}
          onClick={() => {
            setReport(true);
          }}
        >
          <img src={reportIcon} alt="reportIcon" />
        </div>
        {toggle ? (
          <EditGameReview
            value={toggle}
            setval={handleToggleReview}
            response={profile}
            t={t}
          />
        ) : null}
        {report && (
          <ReportDialogBox
            value={report}
            setvalue={setReport}
            obj={profile}
            type={"review"}
          />
        )}
      </div>
      <div className={styles.description}>{profile?.feedback}</div>
      <div className={styles.date}>
        {moment(profile?.createdAt).format("DD MMM YYYY")}
      </div>
    </div>
  );
};

export default UserReview;
