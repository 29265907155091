import React from "react";
import styles from "../../styles/pages.module.scss";
import SignIn from "../SignIn/SignIn";
import Posts from "../common/Posts/Posts";
import HomeFeed from "./HomeFeed";

const Home = ({ isAuthenticated }) => {
  // if (!isAuthenticated) {
  //   return <SignIn />;
  // }

  return (
    <div className={styles.wrapper}>
      <HomeFeed />
    </div>
  );
};

export default Home;
