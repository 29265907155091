import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { IconButton } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PostCard from "../Posts/PostCard/PostCard";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { EditReview } from "../../../services/gamesService";
import ShowError from "../../error/ShowError";
import {
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  FormControl,
  FormLabel,
  Slider,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CustomStyle from "../../Reviews/ReviewPage/ReviewPage.module.scss";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import GameConsoleDialog from "./gameConsoleDialog";
import { getData } from "../../../utils/AppStorage";
import { PostGameReview } from "../../../services/gamesService";
import { ProfileReview } from "../../../services/mutateReduxState";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    maxWidth: 500,
    border: "2px solid #0083ff",
    marginTop: "20px",
  },
}));
export default function EditGameReview({ value, setval, response, t }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const styles = (theme) => ({
    root: {
      margin: 5,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  // ------------------------------------ custom function
  const [state, setstate] = useState({
    ConsoleDialogOpen: false,
    consoleValue: _.flattenDeep(response?.devices[0]),
    rating: response?.ratings,
    description: response.feedback,
    user: {},
    errorfor: "",
    ErrorMsg: "",
    loading: false,
  });
  const [toast, setToast] = useState({
    showErr: false,
    message: "",
    severity: "",
  });
  const handleCloseError = () => {
    setToast({
      ...toast,
      showErr: false,
    });
  };
  React.useEffect(() => {
    getData("user", (data) => {
      if (data) {
        setstate({
          ...state,
          user: data,
        });
      }
    });
  }, []);
  const validate = () => {
    if (state.description === "") {
      setstate({
        ...state,
        errorfor: "description",
        ErrorMsg: t("review.feedback"),
      });
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    var valid = validate();

    if (valid) {
      setstate({
        ...state,
        loading: true,
      });
      var obj = {
        feedback: state.description,
        ratings: parseInt(state.rating),
        console: state.consoleValue,
        gameId: response?._id,
      };
      EditReview(
        (res) => {
          if (res) {
            setstate({ ...state, loading: false });
            ProfileReview(res);
            setToast({
              ...toast,
              message: t("review.addReview"),
              severity: "success",
              showErr: true,
            });
            setTimeout(() => {
              setval();
            }, 1000);
          } else {
          }
        },
        response._id,
        obj
      );
    }
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={value}
        onClose={setval}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "black",
          },
        }}
        disableBackdropClick={true}
      >
        <DialogTitle
          id="responsive-dialog-title"
          onClose={setval}
        ></DialogTitle>
        <DialogContent>
          <div>
            <div style={{ width: "500px", height: "500px", color: "#7f7f7f" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <UserAvatar source={response?.gameImage} size={"75px"} />
                    </ListItemAvatar>
                    <div style={{ marginLeft: "20px" }}>
                      <h5
                        style={{
                          marginBlockStart: "-10px",
                          marginBlockEnd: "4px",
                          color: "white",
                        }}
                      >
                        {response?.gameName}
                      </h5>
                      <h5
                        style={{
                          marginBlockStart: "1px",
                          marginBlockEnd: "2px",
                          fontWeight: "500",
                        }}
                      >
                        {response?.createdBy?.userName.toUpperCase()},
                      </h5>
                      <div style={{ fontSize: "13px" }}>
                        {t("review.releaseDate")}:{" "}
                        {moment(response?.releaseDate).format("DD MMM YYYY")}
                      </div>
                    </div>
                  </ListItem>
                  <FormControl style={{ marginTop: "17px" }}>
                    <FormLabel style={{ color: "white" }}>
                      {t("review.p1")}
                    </FormLabel>

                    <List
                      component="nav"
                      className={classes.root}
                      aria-label="mailbox folders"
                      onClick={() =>
                        setstate({
                          ...state,
                          ConsoleDialogOpen: !state.ConsoleDialogOpen,
                        })
                      }
                    >
                      <ListItem>{state.consoleValue}</ListItem>
                    </List>

                    {state.ConsoleDialogOpen ? (
                      <GameConsoleDialog
                        value={state}
                        setval={setstate}
                        t={t}
                      />
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <FormControl style={{ marginTop: "29px" }}>
                    <FormLabel style={{ color: "white" }}>
                      {t("review.p2")}
                    </FormLabel>
                    <Slider
                      defaultValue={5.0}
                      onChange={(e, value) => {
                        setstate({
                          ...state,
                          rating: value,
                        });
                      }}
                      value={state.rating}
                      aria-labelledby="discrete-slider-always"
                      step={0.1}
                      max={10}
                      min={0}
                      valueLabelDisplay="on"
                      style={{ marginTop: "60px", width: "500px" }}
                    />
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "30px" }}>
                    <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                    <OutlinedInput
                      style={{ color: "white" }}
                      id="outlined-adornment-amount"
                      value={state.description}
                      onChange={(e) => {
                        setstate({
                          ...state,
                          description: e.target.value,
                          errorfor: "",
                          ErrorMsg: "",
                        });
                      }}
                      placeholder={t("review.p3")}
                      startAdornment={
                        <InputAdornment position="start">
                          {Object.keys(state.user).length > 0 ? (
                            <UserAvatar
                              source={state?.user?.pic}
                              size={"40px"}
                            />
                          ) : (
                            <UserAvatar size={"40px"} />
                          )}
                        </InputAdornment>
                      }
                      labelWidth={60}
                    />
                    {state.errorfor === "description" &&
                    state.ErrorMsg !== "" ? (
                      <>
                        <Alert severity="error">{state.ErrorMsg}</Alert>
                      </>
                    ) : (
                      ""
                    )}
                    {toast.showErr ? (
                      <ShowError
                        open={toast.showErr}
                        message={toast.message}
                        severity={toast.severity}
                        handleClose={handleCloseError}
                      />
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={CustomStyle.wrapper}>
          <div className={CustomStyle.rate}>
            {state.loading ? (
              <button>
                {" "}
                <CircularProgress />
              </button>
            ) : (
              <button onClick={handleSubmit}>{t("review.editGame")}</button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
