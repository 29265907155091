const storeData = async (key, data) =>
  localStorage.setItem(key, JSON.stringify(data));
const getData = (key, callback) => {
  let data = localStorage.getItem(key);
  if (data) {
    callback(JSON.parse(data));
  } else {
    callback(false);
  }
};
const clearStorage = () => !!localStorage.clear();
const removeItem = (item) => localStorage.removeItem(item);

const removeItemsFromLocalStorage = async (keys) => {
  try {
    localStorage.removeItem(keys);
    return true;
  } catch (exception) {
    return false;
  }
  // keys.forEach(async (key) => {
  //   console.log(key);
  //   try {
  //     localStorage.removeItem(key);
  //     return true;
  //   } catch (exception) {
  //     return false;
  //   }
  // });
};

export {
  storeData,
  getData,
  clearStorage,
  removeItemsFromLocalStorage,
  removeItem,
};
