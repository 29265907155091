import React, { useState, useEffect } from "react";
import AddComment from "./addComments/addComment";
import ListComment from "./listComment/listComment";
import { useSelector } from "react-redux";
import {
  EditorState,
  ContentState,
  Modifier,
  convertFromRaw,
  convertToRaw,
  SelectionState,
  forceSelection,
} from "draft-js";

export default function Comment({ PostInfo }) {
  const [state, setstate] = React.useState({
    user: "",
    comment: "",
    currentFocus: null,
    mentionUser: [],
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const { comment } = useSelector((state) => state.root);

  // () => EditorState.createEmpty();
  // EditorState.createWithContent(ContentState.createFromText())
  useEffect(() => {}, [comment]);
  return (
    <>
      <ListComment
        nstate={state}
        nsetstate={setstate}
        PostInfo={PostInfo}
        editorState={editorState}
        setEditorState={setEditorState}
        EditorState={EditorState}
      />
      <AddComment
        state={state}
        setstate={setstate}
        PostInfo={PostInfo}
        editorState={editorState}
        setEditorState={setEditorState}
        EditorState={EditorState}
      />
    </>
  );
}
