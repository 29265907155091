import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import support from "../../../../../assets/images/support1.svg";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import styles from "../Setting.module.scss";
import { useHistory } from "react-router-dom";
const InfoHelp = ({ t }) => {
  let history = useHistory();
  const [anchorInfo, setAnchorInfo] = useState(null);
  const handleInfoHelp = (event) => {
    setAnchorInfo(event.currentTarget);
  };
  const handleCloseInfo = (event) => {
    setAnchorInfo(null);
  };
  return (
    <div style={{ display: "flex" }}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="secondary"
        style={{ padding: 0 }}
        onClick={handleInfoHelp}
        className={styles.infoHelp}
      >
        <img
          src={support}
          alt="support"
          style={{
            height: "30px",
            width: "50px",
            marginRight: "5px",
          }}
        />
        {t("setting.infoHelp")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorInfo}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center bottom",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorInfo)}
        onClose={handleCloseInfo}
      >
        {/* <MenuItem
                          onClick={() => {
                            setState({ ...state, appVersion: true });
                            setAnchorInfo(null);
                          }}
                          value="AppVersion"
                        >
                          App Version
                        </MenuItem> */}
        <MenuItem
          onClick={() => {
            history.push("data-policy");
            setAnchorInfo(null);
          }}
          value="DataPolicy"
        >
          {t("setting.dataPolicy")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("terms-of-use");
            setAnchorInfo(null);
          }}
          value="TermsUse"
        >
          {t("setting.termUse")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("report-problem");
            setAnchorInfo(null);
          }}
          value="ReportProblem"
        >
          {t("setting.reportProblem")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("/help-center");
            setAnchorInfo(null);
          }}
          value="Help Center"
        >
          {t("setting.helpCenter")}
        </MenuItem>
      </Menu>
    </div>
  );
};
export default InfoHelp;
