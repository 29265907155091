import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileData } from "../../redux/reducers/userProfileDataSlice";
import { GetPostsOfSpecificUserWhereTaggedIn } from "../../services";
import styles from "../../styles/pages.module.scss";
import PostListing from "../common/Posts/PostListing";

const UserProfileTaggedPosts = ({ userID }) => {
  let { userProfileData } = useSelector((state) => state.root);
  let dispatch = useDispatch();

  let [state, setState] = useState({
    loading: true,
  });

  useEffect(() => {
    GetPostsOfSpecificUserWhereTaggedIn((userPosts) => {
      if (userPosts) dispatch(setUserProfileData({ taggedInPosts: userPosts }));
      setState((prev) => ({ ...prev, loading: false }));
    }, `${userID}&taggedOnly=true`);
  }, []);

  return (
    <div>
      {state.loading ? (
        <div className="loader"></div>
      ) : (
        <div className={styles.wrapper}>
          <PostListing data={userProfileData?.taggedInPosts} />
        </div>
      )}
    </div>
  );
};

export default UserProfileTaggedPosts;
