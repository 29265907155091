import React, { useState } from "react";
import styles from "./ReportProblem.module.scss";
import { Button } from "@material-ui/core";
import ReportDialogBox from "../../../../common/dialogbox/reportDialogbox";
import ExposeInformation from "../ExposeInformation/ExposeInformation";
import HackedAccount from "../HackedAccount/HackedAccount";
import IssueScreen from "../IssueScreen/IssueScreen";
import Impersonation from "../Impersonation/Impersonation";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#262626",
  },
});
const ReportProblem = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  //initial Modal states
  const [values, setValues] = useState({
    spamScreen: false,
    exposeScreen: false,
    hackedScreen: false,
    issueScreen: false,
    impersonationScreen: false,
  });

  //Handler For closing Modal

  const handleSetValue = () => {
    setValues({ ...values, spamScreen: false });
  };
  const handleExposeScreen = () => {
    setValues({ ...values, exposeScreen: false });
  };
  const handleReportToUs = () => {
    setValues({ ...values, exposeScreen: false, spamScreen: true });
  };
  const handleHackedAccount = () => {
    setValues({ ...values, hackedScreen: false });
  };
  const handleIssueScreen = () => {
    setValues({ ...values, issueScreen: false });
  };
  const hanldeImpersonation = () => {
    setValues({ ...values, impersonationScreen: false });
  };

  return (
    <Grid container spacing={0} alignItems="center" justify="center">
      <Grid item sm={4} xs={12}>
        <div className={styles.parent}>
          <Card className={classes.root}>
            <CardContent>
              <h4 className={styles.body}>What do you want to report</h4>
              <Button
                className={styles.buttonColor}
                onClick={() => {
                  setValues({ ...values, spamScreen: true });
                }}
              >
                {t("setting.abuse")}
              </Button>
              <br />
              <Button
                className={styles.buttonColor}
                onClick={() => {
                  setValues({ ...values, exposeScreen: true });
                }}
              >
                {t("setting.expose")}
              </Button>
              <br />
              <Button
                className={styles.buttonColor}
                onClick={() => {
                  setValues({ ...values, hackedScreen: true });
                }}
              >
                {t("setting.hack")}
              </Button>

              {/* <br /> */}
              {/* <Button
                className={styles.buttonColor}
                onClick={() => {
                  setValues({ ...values, impersonationScreen: true });
                }}
              >
                Impersonation
              </Button> */}

              <br />
              <Button
                className={styles.buttonColor}
                onClick={() => {
                  setValues({ ...values, issueScreen: true });
                }}
              >
                {t("setting.issue")}
              </Button>

              {/*Show relevant modal on button click*/}
              {values.spamScreen ? (
                <ReportDialogBox
                  value={values.spamScreen}
                  setvalue={handleSetValue}
                  type={"setting"}
                  t={t}
                />
              ) : null}
              {values.exposeScreen ? (
                <ExposeInformation
                  open={values.exposeScreen}
                  handleExposeScreen={handleExposeScreen}
                  handleReportToUs={handleReportToUs}
                  t={t}
                />
              ) : null}
              {values.hackedScreen ? (
                <HackedAccount
                  open={values.hackedScreen}
                  handleHackedAccount={handleHackedAccount}
                  t={t}
                />
              ) : null}
              {values.issueScreen ? (
                <IssueScreen
                  open={values.issueScreen}
                  handleIssueScreen={handleIssueScreen}
                  t={t}
                />
              ) : null}
              {values.impersonationScreen ? (
                <Impersonation
                  open={values.impersonationScreen}
                  handleIssueScreen={hanldeImpersonation}
                  t={t}
                />
              ) : null}
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  );
};
export default ReportProblem;
