import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { GetUserFollower } from "../../../../services/profileService";
import UserAvatar from "../../../common/UserAvatar/UserAvatar";
import style from "./Followers.module.scss";
import "../../../../styles/loader.css";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 400,
    minWidth: 400,
  },
}))(MuiDialogContent);

const Followers = ({ open, handleClose, userData, t }) => {
  console.log("in followers", userData);
  const [values, setValues] = useState({
    userData: "",
    loading: false,
  });
  useEffect(() => {
    setValues({ ...values, loading: true });
    GetUserFollower(
      (res) => {
        setValues({ ...values, userData: res, loading: false });
      },
      "FOLLOWERS",
      userData?._id ? `?id=${userData?._id}` : ""
    );
  }, []);
  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        {values.loading ? (
          <DialogContent>
            <center>
              <div className="loader"></div>
            </center>
          </DialogContent>
        ) : (
          <div>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {t("profile.followers")}
            </DialogTitle>
            <DialogContent dividers className={style.parentStyle}>
              {values.userData.length > 0 ? (
                values.userData?.map((item, index) => {
                  return (
                    <Typography className={style.alignUser} key={index}>
                      <Typography>
                        <UserAvatar source={item?.pic} size={"40px"} />
                      </Typography>
                      <Typography className={style.textStyle}>
                        {item.userName}
                      </Typography>
                      <br />
                      <Divider />
                    </Typography>
                  );
                })
              ) : (
                <DialogContent>
                  <Typography>{t("profile.noFollowers")}</Typography>
                </DialogContent>
              )}
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
  );
};
export default Followers;
