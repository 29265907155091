import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetExplorePosts } from "../../services";
import { RemoveDuplicateObjectsFromArray } from "../../utils/AppHelperMethods";
import PostListing from "../common/Posts/PostListing";
import "../../styles/loader.css";
import { ToastContainer, toast } from "react-toastify";
import { resetQuery } from "../../redux/reducers/querySlice";
import { getData } from "../../utils/AppStorage";
import { setExploreFeed } from "../../redux/reducers/exploreFeedSlice";
import { setComment } from "../../redux/reducers/commentSlice";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { removeItemsFromLocalStorage } from "../../utils/AppStorage";
let enableReset = true;
let cursorArrPosts = [];
const ExplorePosts = () => {
  const dispatch = useDispatch();
  const { query, comment, exploreFeed } = useSelector((state) => state.root);
  // console.log("query", query);
  const [state, setState] = useState({
    loading: true,
    loadingPosts: true,
    refreshingPosts: false,
    allPosts: [],
    cursor: "",
    cursorBool: false,
    limit: 7,
  });
  const ResetFilter = () => {
    // console.log("filters");
    removeItemsFromLocalStorage("sort");
    removeItemsFromLocalStorage("time");
    removeItemsFromLocalStorage("filter");
  };
  function getexplorepostshelper(cursor, searchQuery) {
    if (!cursor) cursorArrPosts = [];

    getData("search", (searchvalue) => {
      if (searchvalue && searchvalue != "") {
        searchQuery = searchvalue ? "&search=" + searchvalue : "";
        getData("filter", (filtquery) => {
          if (filtquery) {
            searchQuery = searchQuery + filtquery;
          }
        });
      } else {
        getData("filter", (filtquery) => {
          if (filtquery) {
            searchQuery = filtquery;
          }
        });
      }
    });

    // console.log("Search Query", searchQuery);
    if (!cursorArrPosts.includes(cursor)) {
      GetExplorePosts(
        (postResponse, bool) => {
          if (
            postResponse?.data?.data &&
            (postResponse?.data?.data.length || enableReset)
          ) {
            let tempData = postResponse?.data?.data;
            // let PostClone = [...state.allPosts];
            let CloneExploreFeed = [...exploreFeed];
            // console.log("post clone", tempData);
            if (query) {
              if (!state.cursorBool) {
                setState({
                  ...state,
                  cursor: postResponse?.data?.cursor,
                  cursorBool: true,
                });
              } else {
                setState({
                  ...state,
                  cursorBool: true,
                  cursor: postResponse?.data?.cursor,
                });
              }
              tempData.forEach((element) => {
                let checkind = CloneExploreFeed.findIndex(
                  (i) => i._id === element._id
                );
                if (checkind > -1) {
                  CloneExploreFeed[checkind] = element;
                } else {
                  CloneExploreFeed = [...CloneExploreFeed, element];
                }
              });
              // tempData.forEach((element) => {
              //   let Postind = CloneExploreFeed.findIndex(
              //     (i) => i._id === element._id
              //   );
              //   if (Postind > -1) {
              //     CloneExploreFeed[Postind] = element;
              //   } else {
              //     CloneExploreFeed = [...CloneExploreFeed, element];
              //   }
              // });
              // CloneExploreFeed = tempData;
            } else if (comment && comment !== "resetComment") {
              tempData.forEach((element) => {
                let Postind = CloneExploreFeed.findIndex(
                  (i) => i._id === element._id
                );
                if (Postind > -1) {
                  CloneExploreFeed[Postind] = element;
                } else {
                  CloneExploreFeed = [element, ...CloneExploreFeed];
                }
              });
              setState({
                ...state,
                cursorBool: false,
                cursor: postResponse?.data?.cursor,
              });
            } else {
              tempData.forEach((element) => {
                let checkind = CloneExploreFeed.findIndex(
                  (i) => i._id === element._id
                );
                if (checkind > -1) {
                  CloneExploreFeed[checkind] = element;
                } else {
                  CloneExploreFeed = [...CloneExploreFeed, element];
                }
              });
              setState({
                ...state,
                cursorBool: false,
                cursor: postResponse?.data?.cursor,
              });
            }

            dispatch(setExploreFeed(CloneExploreFeed));
            setState((prev) => ({
              ...prev,
              loadingPosts: false,
              refreshingPosts: false,
              loading: false,
              // cursor: postResponse?.data?.cursor,
              allPosts: CloneExploreFeed,
            }));

            // dispatch(setComment(""));
          } else {
            setState((prev) => ({
              ...prev,
              loadingPosts: false,
              refreshingPosts: false,
              cursor: "",
              allPosts: [],
              loading: false,
            }));
            // console.log(postResponse.message.message);
            toast.error(postResponse.message.message || postResponse.message);
          }

          dispatch(setComment(""));
        },
        comment
          ? ""
          : query != "" && state.cursor && state.cursorBool
          ? state.cursor
          : query === "" && state.cursor && comment === "resetComment"
          ? state.cursor
          : query != "" && state.cursor && !state.cursorBool
          ? ""
          : state.cursor,
        searchQuery,
        state.limit
      );
      cursorArrPosts.push(cursor);
    } else {
      setState((prev) => ({ ...prev, refreshingPosts: false }));
    }
  }
  // console.log("comment filter", comment);

  function getMorePost() {
    var timebool = false;
    var sortbool = false;

    getData("time", (searchvalue) => {
      if (searchvalue && searchvalue == "") {
        timebool = true;
      }
    });
    getData("sort", (searchvalue) => {
      if (searchvalue && searchvalue == "") {
        sortbool = true;
      }
    });
    getData("search", (searchValue) => {
      if (searchValue && searchValue == "") {
        dispatch(resetQuery());
      }
    });
    if (sortbool && timebool) {
      dispatch(resetQuery());
    }

    getexplorepostshelper(false, query);
  }
  useEffect(() => {
    enableReset = true;
    cursorArrPosts = [];
    getexplorepostshelper(false, query);
  }, [query, comment]);

  return (
    <React.Fragment>
      {state.loading ? (
        <div className="loader"></div>
      ) : state.allPosts.length > 0 ? (
        <>
          <InfiniteScroll
            dataLength={state.allPosts.length}
            next={getMorePost}
            hasMore={true}
            loader={<> {state.cursor ? "Loading" : ""}</>}
            style={{ overflow: "hidden", width: "100%" }}
          >
            <PostListing data={state.allPosts} />
          </InfiniteScroll>
        </>
      ) : (
        <>
          No Result Found
          <ToastContainer />
        </>
      )}
    </React.Fragment>
  );
};

export default ExplorePosts;
