import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Grid,
  CircularProgress,
  useTheme,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CommentBar from "../CommentBar/CommentBar";
import { GetCommentsReplies } from "../../../../../services";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import { ReactComponent as Report } from "../../../../../assets/images/report.svg";
import options from "../../../../../assets/images/options.svg";
import { useTranslation } from "react-i18next";
import ReportDialogBox from "../../../dialogbox/reportDialogbox";
export default function ComentItem({
  resp,
  handleComment,
  stte,
  handleEdit,
  handleDelete,
  Cmntstate,
  Cmntsetstate,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { query, comment } = useSelector((state) => state.root);
  const [report, setReport] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let loginUser = JSON.parse(localStorage.getItem("user"));
  let [state, setState] = React.useState({
    reply: [],
  });
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    GetCommentsReplies(
      (data) => {
        setState({
          ...state,
          reply: data,
        });
      },
      false,
      200,
      resp?._id
    );
  }, [query, comment]);
  console.log("Comment Item", resp);
  return (
    <>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <UserAvatar
            size="50px"
            innerImageSize="45px"
            user={resp?.createdBy}
          />
          {/* <Avatar alt="Remy Sharp" src={resp?.createdBy?.pic} /> */}
        </Grid>
        <Grid justifycontent="left" item xs zeroMinWidth>
          <h5
            style={{
              margin: 0,
              textAlign: "left",

              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "gray" }}>
              {resp?.createdBy?.userName} -{" "}
              <span style={{ color: "gray" }}>
                {moment(resp?.createdAt).fromNow(true)}
              </span>
            </p>
            <img
              src={options}
              alt="options"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              // style={{ width: "190px" }}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {loginUser?._id === resp?.createdBy?._id && (
                <>
                  <MenuItem
                    onClick={() => {
                      handleEdit();
                      handleClose();
                    }}
                  >
                    <EditOutlined style={{ cursor: "pointer" }} />
                    {t("dashboard.modify")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDelete(resp);
                      handleClose();
                    }}
                  >
                    <DeleteOutline style={{ cursor: "pointer" }} />
                    {t("dashboard.delete")}
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={() => {
                  setReport(true);
                }}
              >
                <Report
                  className="icon"
                  style={{ height: "24px", width: "24px" }}
                />
                {t("setting.report")}
              </MenuItem>
            </Menu>
            {report && (
              <ReportDialogBox
                value={report}
                setvalue={setReport}
                obj={resp}
                type={"comment"}
              />
            )}
          </h5>
          <div
            id="parent"
            style={{ textAlign: "left", color: "gray", marginTop: "20px" }}
          >
            {resp &&
              resp?.text &&
              resp?.text?.split(" ").map((text, indx) => {
                return (
                  <>
                    {resp?.mentions.find((i) => text === `@${i.userName}`) ? (
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          history.push({
                            pathname: "/view-profile",
                            state: {
                              userID: resp?.mentions.find(
                                (i) => text === `@${i.userName}`
                              )?._id,
                            },
                          });
                        }}
                      >
                        {" "}
                        {text}{" "}
                      </span>
                    ) : (
                      <span> {text} </span>
                    )}
                  </>
                );
              })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderBottom: "1px solid #333333",
            }}
          >
            <CommentBar
              commentData={resp}
              commentHandler={handleComment}
              commentid={stte?.selected}
              rply={state.reply}
              Cmntstate={Cmntstate}
              Cmntsetstate={Cmntsetstate}
            />
          </div>
        </Grid>
      </Grid>

      {/* {state.reply.length > 0 && state.reply.length < state.reply.length + 1 ? (
          <>
            {state.reply.map((data, indx) => {
              return (
                <div style={{ marginLeft: "10ch" }}>
                  <ComentItem
                    resp={data}
                    stte={state}
                    handleComment={handleComment}
                  />
                </div>
              );
            })}
          </>
        ) : (
          ""
        )} */}
    </>
  );
}
{
  /* <span
                       dangerouslySetInnerHTML={{
                          __html: `
                             ${
                                resp?.text.replace(
                                 
                                      new RegExp(`@${
                                       
                                        resp?.mentions.find(i=>i)?.userName
                                      
                                      }`,"ig")
                                     ,
                                   
                                     `<span onClick="myfunc('${resp?.mentions.find(
                                      (i) => i
                                    )?._id}')"    id=${
                                       resp?.mentions.find(
                                         (i) => i
                                       )?._id
                                     }  style="color:blue; cursor:pointer">@${
                                       resp?.mentions.find(
                                         (i) => i
                                       )?.userName
                                     }</span> `
                                   )
                                 
                             }
                              `,
                        }}
                      ></span> */
}
