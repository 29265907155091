import gamerpage from "../../assets/images/gamerpage.jpeg";
import styles from "./LandingPage.module.scss";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import moment from "moment";
const LandingPage = () => {
  let history = useHistory();
  const [days, setDays] = useState(null);

  const target = new Date("10/16/2021");
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  const date1 = new Date(`${today}`);
  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }
  useEffect(() => {
    setDays(getDifferenceInDays(target, date1));
  }, []);

  return (
    <div className={styles.body}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {/* <h1 className={styles.heading}>Are you a GAMER?</h1> */}
          <h6 className={styles.heading_one}>
            The New Social Network for all{" "}
            <strong style={{ fontWeight: "700" }}> Gamers!</strong>
          </h6>
          {/* <h4 className={styles.subheading}>
            Join us! Discover a new world made for you!
          </h4> */}
          <div className={styles.socialNetwork}>
            <div className={styles.mid}>
              {/* <p> */}
              {/* <label className={styles.bold}>After {days} Days</label> */}
              {/* </p> */}
              <button className={styles.register}>
                Relase Date - 
                <strong style={{ fontWeight: "700" }}> TBA</strong>
              </button>
            </div>
          </div>
          {/* <div className={styles.div}>
             <div className={styles.div1}>
              <button className={styles.downlaod}>Download App</button>
              <div className={styles.button}>
                <button
                  className={styles.login}
                  onClick={() => history.push("sign-in")}
                >
                  Login
                </button>
              </div>
            </div> 
          </div>*/}
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
