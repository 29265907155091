import React from "react";
import {
  DialogTitle,
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  Slide,
  Grid,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ActionsOnUsers } from "../../../services/profileService";
import { setComment } from "../../../redux/reducers/commentSlice";
import { useDispatch } from "react-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function BlockUserDialog({ value, setvalue, obj }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const handleClose = () => {
    setvalue(false);
  };

  const handleDelete = () => {
    console.log("delete id", obj);
    ActionsOnUsers(
      (data) => {
        if (data) {
          setvalue(false);
          dispatch(setComment({}));
        }
      },
      obj?.createdBy?._id,
      "BLOCKED"
    );
  };

  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          open={value}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          disableBackdropClick
        >
          <DialogContent>
            <DialogContentText>
              <div>
                <div>
                  <div>
                    <div>
                      <Grid container spacing={2} alignItems="flex-end">
                        <p>Are you sure want to Block the User!!</p>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleDelete}>
              Yes
            </Button>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
