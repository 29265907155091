import React, { useState, useEffect } from "react";
import {
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  Slide,
  Grid,
  Container,
  IconButton,
} from "@material-ui/core";
import { MentionsInput, Mention } from "react-mentions";
import { Alert, AlertTitle } from "@material-ui/lab";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import AccountCircle from "@material-ui/icons/AccountCircle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import {
  Image,
  TrendingUpTwoTone,
  LocationOnSharp,
  Language,
} from "@material-ui/icons";
import DummyImage from "../../../assets/images/image.png";
import { useTheme, withStyles } from "@material-ui/core/styles";
import "./modifyDialog.css";
import { ImageUpload } from "../../../utils/appImageUpload";
import { ReactComponent as Tag } from "../../../assets/images/profile-tags.svg";
import UserAvatar from "../UserAvatar/UserAvatar";
import ReactPlayer from "react-player";
import {
  CreatePostService,
  EditModifyPostService,
} from "../../../services/postService";
import { setComment } from "../../../redux/reducers/commentSlice";
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";
import PrivateMenu from "./privateMenu";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Clear } from "@material-ui/icons";
import Friend from "./dialogbox component/dialogComponent";
import { GetAllTrendingUsers } from "../../../services";
import { GET_FRIEND_LIST_TYPES } from "../../../utils/AppConstants";
import { getData } from "../../../utils/AppStorage";
import { toast, ToastContainer } from "react-toastify";
import LocationDialog from "./dialogbox component/locationComponent";
import { updateExploreStore } from "../../../services/mutateReduxState";
import { useTranslation } from "react-i18next";
import defaultStyle from "./defaultStyle";
import { setHashTags } from "../../../redux/reducers/hashTagsSlice";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DialogBox({ value, setvalue, obj, t }) {
  const dispatch = useDispatch();
  const { hashTags } = useSelector((state) => state?.root);

  const handleClose = () => {
    setvalue(false);
    setImage(null);
    setImageUrl(null);
    settext("");
    setPrivateData("");
    setPrivateMenu(null);
    setporgress(false);
    setVideoOrImageType("");
    setFriend(false);
    setstate({
      friendList: [],
      tagedList: [],
      bool: true,
    });
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = (theme) => ({
    root: {
      margin: 5,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const [image, setImage] = React.useState(null);

  const [imageUrl, setImageUrl] = React.useState(null);
  const [text, settext] = React.useState("");
  const [mentionText, setMentionText] = React.useState("");
  const [individualObject, setObject] = React.useState("");
  // const [hashTags, setHashTags] = useState([]);
  const [requestTag, setRequestTag] = useState(hashTags?.tags);
  const [addressState, setAddressState] = React.useState({
    address: "",
    country: "",
    street: "",
    cordinate: "",
    bool: true,
  });
  const [prvteMenu, setPrivateMenu] = React.useState(null);
  const [privateData, setPrivateData] = React.useState("");
  const [progress, setporgress] = React.useState(false);
  const [videoOrImageType, setVideoOrImageType] = React.useState("");
  const [friend, setFriend] = React.useState(false);
  const [poolfeatuer, setPoolFeatuer] = React.useState(false);
  const [postfeature, setpostfeature] = React.useState(false);
  const [toggleLocation, setToggleLocation] = React.useState(false);
  // -----------------------------------custom own function -------------
  const [state, setstate] = React.useState({
    friendList: [],
    tagedList: [],
    search: "",
    bool: true,
    isLoad: false,
  });
  // console.log("before", state);

  const handleHashTags = () => {
    let data = {
      hashTags: [],
      oldHashTags: [],
      combine: [],
    };
    let hashTagsReg = /#(\w+)\b/gi;
    let oldHashTagsReg = /(?<=\[).*?(?=\])/g;
    let temp;
    while ((temp = hashTagsReg?.exec(text))) {
      data.hashTags.push(temp[1]);
    }
    data.oldHashTags = text.match(oldHashTagsReg);
    if (data?.oldHashTags?.length > 0) {
      data.combine = [...data?.oldHashTags, ...data?.hashTags];
    } else if (data?.hashTags?.length > 0) {
      data.combine = [...data?.hashTags];
    }
    return data;
  };

  const handleNewHashTags = (newTags) => {
    let tempText = text;
    if (newTags?.length > 0) {
      let uniqueTags = [...new Set(newTags)];
      let res = "";
      for (let i = 0; i < uniqueTags.length; i++) {
        res = tempText.replace(`#${uniqueTags[i]}`, `#[${uniqueTags[i]}](1)`);
        tempText = res;
      }
      let oldHashTags = [...hashTags?.tags];
      for (let i = 0; i < uniqueTags?.length; i++) {
        oldHashTags?.push({
          id: Math.floor(Math.random() * 100),
          display: uniqueTags[i],
        });
      }
      dispatch(setHashTags({ tags: oldHashTags }));
    }
    return tempText;
  };

  const limit = 20;
  function getfriendshelper(cursor, searchQuery) {
    console.log("Search query", searchQuery);
    GetAllTrendingUsers(
      (response) => {
        if (response && response?.data.length > 0) {
          setstate({
            ...state,
            friendList: response?.data,
            search: searchQuery,
            isLoad: true,
          });
        } else {
          setstate({
            ...state,
            friendList: response?.data,
            search: searchQuery,
            isLoad: true,
          });
        }
      },
      cursor ? cursor : "",
      searchQuery ? "search=" + searchQuery : "",
      limit ? "&limit=" + limit : ""
    );
  }
  if (obj?.tagged.length > 0 && state.bool) {
    setstate({
      ...state,
      bool: false,
      tagedList: obj?.tagged,
    });
  }

  if (obj?.location && addressState.bool) {
    setAddressState({
      ...addressState,
      address: obj?.location?.addressName,
      country: obj?.location?.country,
      street: obj?.location?.streetAddress,
      cordinate: `${obj?.location?.location?.coordinates[0]},${obj?.location?.location?.coordinates[1]}`,
      bool: false,
    });
  }

  React.useEffect(() => {
    if (obj && Object.keys(obj).length > 0) {
      var img_type = obj?.attachments[0]?.type.substr(0, 5);

      if (img_type === "image") {
        setVideoOrImageType(img_type);

        setImageUrl(obj?.attachments[0]?.url);
      } else if (img_type === "video") {
        setVideoOrImageType(img_type);
        setImageUrl(obj?.attachments[0]?.url);
      }
      console.log("objec", obj);

      setObject(obj);
      settext(obj?.text);
      setMentionText(obj?.text);
      // setImage(obj?.attachments[0]?.url);

      setporgress(false);
      setPrivateData("");
    } else {
      settext("");
      setporgress(false);
      setPrivateData("");
      setVideoOrImageType("");
      setImage(null);
      setImageUrl(null);
    }
    getfriendshelper(false, "");
  }, []);

  const handleImage = (e) => {
    ImageUpload(e, setVideoOrImageType, (data) => {
      if (data) {
        // console.log("data", data);
        setImage(data);
        setImageUrl(URL.createObjectURL(data));
        // console.log(image?.file);
      }
    });
  };

  const handleSubmit = () => {
    let hashTagsArr = handleHashTags();
    let updatedText = handleNewHashTags(hashTagsArr?.hashTags);
    var arr = [];
    if (state?.tagedList?.length > 0) {
      state.tagedList.forEach((element) => {
        arr.push(element._id);
      });
    }
    var obj = {
      text: text ? updatedText : "",
      privacy: privateData !== "" ? privateData : "PUBLIC",
      tagged: arr,
      hashTags: hashTagsArr?.combine,
      web: true,
    };
    if (image) {
      obj["file"] = image;
    }
    if (addressState.address) {
      obj["location"] = {
        addressName: addressState.address,
        country: addressState.country,
        streetAddress: addressState.street,
        coordinates: addressState.cordinate,
      };
    }
    setporgress(true);
    if (obj.text || obj.file) {
      CreatePostService((data) => {
        setporgress(false);
        toast.success("Post has been Successfully Created!!");
        setTimeout(() => {
          setvalue(false);
        }, 3000);

        dispatch(setComment("query"));
      }, obj);
    } else {
      setporgress(false);
      toast.error("Please add something to post!");
    }
  };
  const handleUpdatePost = () => {
    let hashTagsArr = handleHashTags();
    let updatedText = handleNewHashTags(hashTagsArr?.hashTags);

    var payload = {
      text: text ? updatedText : "",
      privacy: privateData !== "" ? privateData : "PUBLIC",
      hashTags: hashTagsArr?.combine,
      web: true,
    };
    if (image) {
      payload["file"] = image;
    }
    if (addressState.address) {
      payload["location"] = {
        addressName: addressState.address,
        country: addressState.country,
        streetAddress: addressState.street,
        coordinates: addressState.cordinate,
      };
    }
    if (!image && !imageUrl) {
      payload.removeMedia = true;
    } else {
      payload.removeMedia = false;
    }
    if (state.tagedList.length > 0) {
      var arr = [];
      if (state.tagedList.length > 0) {
        state.tagedList.forEach((element) => {
          arr.push(element._id);
        });
        payload["tagged"] = arr;
      }
    } else {
      payload["tagged"] = [];
    }

    setporgress(true);
    EditModifyPostService(
      (resp) => {
        console.log("resp", resp);
        updateExploreStore(resp.data);
        dispatch(setComment("query"));
        setporgress(false);
        setvalue(false);
      },
      individualObject?._id,
      payload
    );
  };
  const handleCrossImage = () => {
    setImageUrl("");
    setImage("");
  };
  const handlePool = () => {
    setPoolFeatuer(true);
    setTimeout(() => {
      setPoolFeatuer(false);
    }, 1000);
  };
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        open={Boolean(value)}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        disableBackdropClick
      >
        {obj ? (
          <>
            {" "}
            <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
              {t("createPost.updatePost")}
            </DialogTitle>
            <DialogContent style={{ padding: "8px 14px" }}>
              <DialogContentText>
                <div>
                  <div>
                    <div>
                      <div>
                        {friend ? (
                          <Friend
                            value={state}
                            setvalue={setstate}
                            search={(callresp) => {
                              setstate({
                                ...state,
                                search: callresp,
                              });
                              getfriendshelper(false, callresp);
                            }}
                          />
                        ) : toggleLocation ? (
                          <LocationDialog
                            location={addressState}
                            setlocation={setAddressState}
                          />
                        ) : (
                          <>
                            {" "}
                            <Grid container spacing={2} alignItems="flex-end">
                              <Grid item>
                                <UserAvatar
                                  size="50px"
                                  innerImageSize="45px"
                                  user={individualObject?.createdBy}
                                />
                              </Grid>
                              <Grid item>
                                <MentionsInput
                                  markup="#[__display__](__id__)"
                                  className="postInput"
                                  value={text}
                                  onChange={(e, newPlainTextValue) => {
                                    settext(e.target.value);
                                  }}
                                  style={defaultStyle}
                                  placeholder={t("createPost.whatNew")}
                                  a11ySuggestionsListLabel={
                                    "Suggested mentions"
                                  }
                                >
                                  <Mention
                                    markup="#[__display__](__id__)"
                                    onAdd={(id, display) => {
                                      settext(display);
                                    }}
                                    trigger="#"
                                    renderSuggestion={(
                                      suggestion,
                                      search,
                                      highlightedDisplay,
                                      index,
                                      focused
                                    ) => (
                                      <div
                                        className={`user ${
                                          focused ? "focused" : ""
                                        }`}
                                      >
                                        {highlightedDisplay}
                                      </div>
                                    )}
                                    className="input-mention"
                                    data={requestTag}
                                  />
                                </MentionsInput>
                              </Grid>
                            </Grid>
                            <Grid container justify="center">
                              {imageUrl ? (
                                <>
                                  {videoOrImageType === "image" ? (
                                    <div
                                      style={{
                                        marginTop: "17px",
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      <img
                                        src={imageUrl}
                                        loading="lazy"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          backgroundSize: "contain",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                        }}
                                      />
                                      <Clear
                                        style={{
                                          position: "absolute",
                                          right: "0",
                                        }}
                                        onClick={handleCrossImage}
                                      />
                                    </div>
                                  ) : // <div

                                  // >
                                  //   {console.log(imageUrl)}

                                  // </div>
                                  videoOrImageType === "video" ? (
                                    <ReactPlayer
                                      url={imageUrl}
                                      controls={true}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid
                              className="dialog_wrapper"
                              container
                              justify="center"
                            >
                              <div
                                id="images"
                                className="dialog_item dialog_item_Border"
                              >
                                <p className="icon_size">
                                  <input
                                    type="file"
                                    id="image"
                                    onChange={handleImage}
                                    style={{ display: "none" }}
                                  />
                                  <label
                                    htmlFor="image"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Image className="home__icon" />
                                  </label>
                                </p>
                              </div>
                              <div
                                id="pool"
                                className="dialog_item dialog_item_Border"
                                onClick={handlePool}
                              >
                                <p className="icon_size">
                                  <TrendingUpTwoTone className="home__icon" />
                                </p>
                              </div>
                            </Grid>
                            {poolfeatuer ? (
                              <Grid>
                                <Alert severity="info">
                                  {t("createPost.poolFeature")}{" "}
                                  <strong>{t("createPost.comingSoon")}</strong>
                                </Alert>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid
                              className="dialog_wrapper"
                              container
                              justify="center"
                            >
                              <div
                                id="video"
                                className="dialog_item "
                                onClick={() => setToggleLocation(true)}
                              >
                                <p className="privacy">
                                  {" "}
                                  <LocationOnSharp className="home__icon path" />
                                  <label>{t("createPost.location")}</label>
                                </p>
                              </div>
                              <div id="images" className="dialog_item">
                                <p
                                  className="privacy"
                                  onClick={(e) =>
                                    setPrivateMenu(e.currentTarget)
                                  }
                                >
                                  <Language className="home__icon" />
                                  <label>{t("createPost.privacy")}</label>
                                </p>
                                {Boolean(prvteMenu) ? (
                                  <PrivateMenu
                                    value={prvteMenu}
                                    setvalue={setPrivateMenu}
                                    privateStatus={privateData}
                                    setPrivateStatus={setPrivateData}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div
                                id="pool"
                                className="dialog_item"
                                onClick={() => setFriend(true)}
                              >
                                <p className="privacy">
                                  {" "}
                                  <Tag className="home__icon" />
                                  <label style={{ paddingTop: "5px" }}>
                                    {t("createPost.tag")}
                                  </label>
                                </p>
                              </div>
                            </Grid>
                            <Grid style={{ marginLeft: "19%" }}>
                              {addressState.address !== "" ? (
                                <>
                                  {" "}
                                  <LocationOnSharp className="home__icon path" />
                                  {addressState.address}
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid style={{ marginLeft: "20%" }}>
                              {state.tagedList && state.tagedList.length > 0
                                ? state.tagedList.map((rsp, idx) => {
                                    return (
                                      <span key={idx} style={{ color: "blue" }}>
                                        @{rsp.userName}{" "}
                                      </span>
                                    );
                                  })
                                : ""}
                            </Grid>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: "23px 0px" }}>
              {friend ? (
                <Container>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "inherit" }}
                    onClick={() => setFriend(false)}
                  >
                    {state.tagedList.length > 0
                      ? t("createPost.done")
                      : t("createPost.back")}
                  </Button>
                </Container>
              ) : toggleLocation ? (
                <Container>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "inherit" }}
                    onClick={() => setToggleLocation(false)}
                  >
                    {addressState.address !== ""
                      ? t("createPost.done")
                      : t("createPost.back")}
                  </Button>
                </Container>
              ) : (
                <Container>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "inherit" }}
                    onClick={handleUpdatePost}
                  >
                    {progress ? (
                      <CircularProgress color="danger" />
                    ) : (
                      t("createPost.update")
                    )}
                  </Button>
                </Container>
              )}
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
              {t("createPost.createPost")}
            </DialogTitle>
            <DialogContent style={{ padding: "8px 14px" }}>
              <div>
                <div>
                  <div>
                    <div>
                      {friend ? (
                        <Friend
                          value={state}
                          setvalue={setstate}
                          search={(callresp) => {
                            getfriendshelper(false, callresp);
                          }}
                        />
                      ) : toggleLocation ? (
                        <LocationDialog
                          location={addressState}
                          setlocation={setAddressState}
                        />
                      ) : (
                        <>
                          <Grid container spacing={2} alignItems="flex-end">
                            <Grid item>
                              <UserAvatar
                                user={JSON.parse(localStorage.getItem("user"))}
                                size={"50px"}
                                innerImageSize="45px"
                              />
                            </Grid>
                            <Grid item>
                              <MentionsInput
                                markup="#[__display__](__id__)"
                                className="postInput"
                                value={text}
                                onChange={(e, newPlainTextValue) => {
                                  settext(e.target.value);
                                }}
                                style={defaultStyle}
                                placeholder={t("createPost.whatNew")}
                                a11ySuggestionsListLabel={"Suggested mentions"}
                              >
                                <Mention
                                  markup="#[__display__](__id__)"
                                  trigger="#"
                                  className="input-mention"
                                  data={requestTag}
                                />
                              </MentionsInput>
                            </Grid>
                          </Grid>
                          <Grid container justify="center">
                            {imageUrl ? (
                              <>
                                {videoOrImageType === "image" ? (
                                  <div
                                    style={{
                                      height: "200px",
                                      width: "200px",
                                      backgroundImage: `url(${imageUrl})`,
                                      marginTop: "17px",
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  ></div>
                                ) : videoOrImageType === "video" ? (
                                  <ReactPlayer url={imageUrl} controls={true} />
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""

                              // <div
                              //   className="dialog_item_Border"
                              //   style={{
                              //     height: "150px",
                              //     width: "163px",
                              //     backgroundImage: `url(${DummyImage})`,
                              //     backgroundSize: "contain",
                              //     backgroundRepeat: "no-repeat",
                              //     backgroundPosition: "center",
                              //   }}
                              // ></div>
                            )}
                          </Grid>
                          <Grid
                            className="dialog_wrapper"
                            container
                            justify="center"
                          >
                            <div
                              id="images"
                              className="dialog_item dialog_item_Border"
                            >
                              <p className="icon_size">
                                <input
                                  type="file"
                                  id="image"
                                  onChange={handleImage}
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor="image"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Image className="home__icon" />
                                </label>
                              </p>
                            </div>
                            <div
                              id="pool"
                              className="dialog_item dialog_item_Border"
                              onClick={handlePool}
                            >
                              <p className="icon_size">
                                <TrendingUpTwoTone className="home__icon" />
                              </p>
                            </div>
                          </Grid>
                          {poolfeatuer ? (
                            <Grid>
                              <Alert severity="info">
                                {t("createPost.poolFeature")}{" "}
                                <strong>{t("createPost.comingSoon")}</strong>
                              </Alert>
                            </Grid>
                          ) : (
                            ""
                          )}

                          <Grid
                            className="dialog_wrapper"
                            container
                            justify="center"
                          >
                            <div
                              id="video"
                              className="dialog_item "
                              onClick={() => setToggleLocation(true)}
                            >
                              <p className="privacy">
                                {" "}
                                <LocationOnSharp className="home__icon path" />
                                <label style={{ paddingTop: "5px" }}>
                                  {t("createPost.location")}
                                </label>
                              </p>
                            </div>

                            <div id="images" className="dialog_item">
                              <p
                                className="privacy"
                                onClick={(e) => setPrivateMenu(e.currentTarget)}
                              >
                                <Language className="home__icon" />
                                <label style={{ paddingTop: "5px" }}>
                                  {t("createPost.privacy")}
                                </label>
                              </p>
                              {Boolean(prvteMenu) ? (
                                <PrivateMenu
                                  value={prvteMenu}
                                  setvalue={setPrivateMenu}
                                  privateStatus={privateData}
                                  setPrivateStatus={setPrivateData}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              id="pool"
                              className="dialog_item"
                              onClick={() => setFriend(true)}
                            >
                              <p className="privacy">
                                {" "}
                                <Tag className="home__icon" />
                                <label style={{ paddingTop: "5px" }}>Tag</label>
                              </p>
                            </div>
                          </Grid>
                          <Grid style={{ marginLeft: "19%" }}>
                            {addressState.address !== "" ? (
                              <>
                                {" "}
                                <LocationOnSharp className="home__icon path" />
                                {addressState.address}
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid style={{ marginLeft: "20%" }}>
                            {state.tagedList && state.tagedList.length > 0
                              ? state.tagedList.map((rsp, idx) => {
                                  return (
                                    <span key={idx} style={{ color: "blue" }}>
                                      @{rsp.userName}{" "}
                                    </span>
                                  );
                                })
                              : ""}
                          </Grid>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <ToastContainer />
            </DialogContent>

            <DialogActions style={{ padding: "23px 0px" }}>
              {friend ? (
                <Container>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "inherit" }}
                    onClick={() => setFriend(false)}
                  >
                    {state.tagedList.length > 0
                      ? t("createPost.done")
                      : t("createPost.back")}
                  </Button>
                </Container>
              ) : toggleLocation ? (
                <Container>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "inherit" }}
                    onClick={() => setToggleLocation(false)}
                  >
                    {addressState.address !== ""
                      ? t("createPost.done")
                      : t("createPost.back")}
                  </Button>
                </Container>
              ) : (
                <Container>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "inherit" }}
                    // disabled={text === "" ? true : false}
                    onClick={handleSubmit}
                  >
                    {progress ? (
                      <CircularProgress color="danger" />
                    ) : (
                      t("createPost.create")
                    )}
                  </Button>
                </Container>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
