import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import save from "../../../../assets/images/interaction_save1.svg";
import logout from "../../../../assets/images/Group1.svg";
import chat from "../../../../assets/images/chat.svg";
import styles from "./Setting.module.scss";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { LogOutUser } from "../../../../services";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { GetAppSettings } from "../../../../services/appSettingsService";
import { SetAppSettings } from "../../../../services/appSettingsService";
import "../../../../styles/loader.css";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { ReactComponent as Donate } from "../../../../assets/images/donate-solid.svg";
import {
  GetSingleUserProfile,
  UpdateProfile,
} from "../../../../services/profileService";
import Grid from "@material-ui/core/Grid";
import ShowError from "../../../error/ShowError";
import Logout from "./Logout/Logout";
import AccountSetting from "./AccountSetting/AccountSetting";
import InfoHelp from "./InfoHelp/InfoHelp";
import EnableNotifications from "./EnableNotifications/EnableNotifications";
import PrivateAccount from "./PrivateAccount/PrivateAccount";
import FeedBack from "./FeedBack/FeedBack";
import i18n from "../../../../services/translation/i18";
import "./setting.css";
import { useTranslation } from "react-i18next";
import SwitchToItalian from "./SwitchItalian";
import { setItalian } from "../../../../redux/reducers/translationSlice";
import { useSelector, useDispatch } from "react-redux";
// material-ui css

//------------------- Material-Ui CSS start ---------------------------------
const theme = createMuiTheme({
  //check button colors
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#ccc",
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "blue",
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#fff",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#fff",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#808080",
      contrastText: "#fff",
    },
    secondary: {
      light: "#808080",
      main: "#808080",
      dark: "#007856",
      contrastText: "#000",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
const useStyles = makeStyles({
  root: {
    backgroundColor: "#262626",
  },
});

//------------------------Material-Ui css End-----------------------------------------

const Setting = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  const { isItalian } = useSelector((state) => state.root);
  const classes = useStyles();
  const { t } = useTranslation();
  let user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    user = JSON.parse(localStorage.getItem("user"));
  });
  const [userProfile, setUserProile] = useState(user);
  let location = useLocation();
  let userData = location?.state?.userData;
  const [state, setState] = useState({
    loading: false,
    feedback: false,
    isPrivate: user?.isPrivate,
    showError: false,
    message: "",
    severity: "",
  });
  const [checkedNotification, setCheckedNotifications] = useState(false);
  const [open, setOpen] = useState(false);
  const getUserProfile = () => {
    setState({ ...state, loading: true });
    GetSingleUserProfile((dta) => {
      if (dta) {
        setUserProile(dta);
      }
    }, userData?.profileId);
  };
  useEffect(() => {
    dispatch(setItalian(JSON.parse(localStorage.getItem("italian"))));
    setState({ ...state, loading: true });
    GetAppSettings((res) => {
      setState({
        ...state,
        loading: false,
      });
      //Setting the notification bolean value
      setCheckedNotifications(res?.notificationsAllowed);
    });
    getUserProfile();
  }, []);
  //------------- Setting Logout Modal Open ---------------------

  const handleLogOutModal = () => {
    setOpen(!open);
  };

  const handleLogOut = () => {
    LogOutUser();
    setOpen(false);
    history.push("/");
  };

  const handleCloseError = () => {
    setState({ ...state, showError: false });
  };

  const handleNotificationsChecked = (event) => {
    setState({
      ...state,
      loading: true,
    });
    let formData = {
      notificationsAllowed: event.target.checked,
    };
    setCheckedNotifications(event.target.checked);
    SetAppSettings((res) => {
      setState({
        ...state,
        loading: false,
      });
    }, formData);
  };
  const handelFeedbackDialog = () => {
    setState({
      ...state,
      feedback: false,
    });
  };
  const handlePrivateAccount = (event) => {
    setState({
      ...state,
      loading: true,
    });
    let formData = {
      isPrivate: event.target.checked,
    };

    UpdateProfile((res) => {
      if (res) {
        setState({ ...state, loading: false, isPrivate: formData.isPrivate });
      } else {
        setState({ ...state, loading: false });
      }
    }, formData);
  };
  const handleChangeItalian = () => {
    if (!isItalian) {
      i18n.changeLanguage("it");
    } else {
      i18n.changeLanguage("en");
    }
    localStorage.setItem("italian", !isItalian);
    dispatch(setItalian(!isItalian));
  };

  return (
    <Grid container spacing={0} alignItems="center" justify="center">
      <Grid item sm={4} xs={12}>
        <div className={styles.wrapper}>
          {state?.loading ? (
            <div className="loader alignCenter"></div>
          ) : (
            <Card className={classes.root}>
              <CardContent>
                <MuiThemeProvider theme={theme}>
                  <div className={styles.parent}>
                    <h1 className={styles.header}>Settings</h1>
                    <div>
                      <Button
                        component="button"
                        id="save"
                        className={styles.savePost}
                        onClick={() => {
                          history.push("/saved-posts");
                        }}
                      >
                        <img
                          src={save}
                          alt="save"
                          className={styles.icon}
                          className={styles.imageStyles}
                        />
                        <p>{t("setting.savedPosts")}</p>
                      </Button>
                    </div>
                    <div className={styles.link}>
                      <EnableNotifications
                        checkedNotification={checkedNotification}
                        handleNotificationsChecked={handleNotificationsChecked}
                        t={t}
                      />
                    </div>
                    <div className={styles.link}>
                      <PrivateAccount
                        isPrivate={state.isPrivate}
                        handlePrivateAccount={handlePrivateAccount}
                        t={t}
                      />
                    </div>
                    <div>
                      <SwitchToItalian
                        isItalian={isItalian}
                        handleChangeItalian={handleChangeItalian}
                        t={t}
                      />
                    </div>
                    <div className={`${styles.link} ${styles.margin}`}>
                      <AccountSetting
                        userData={userData}
                        userProfile={userProfile}
                        t={t}
                      />
                    </div>
                    <div className={`${styles.link} ${styles.marginInfo}`}>
                      <InfoHelp t={t} />
                    </div>
                    <div className={`${styles.link} ${styles.margin}`}>
                      <div
                        className={styles.gameOverContainer}
                        onClick={handleLogOutModal}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          className={styles.gameOver}
                        >
                          <img
                            src={logout}
                            alt="logout"
                            className={styles.gameOverStyles}
                          />
                        </Link>
                        <p>{t("setting.Gameover")}</p>
                      </div>
                    </div>
                    <div
                      className={styles.link}
                      onClick={() => {
                        history.push(`/donations/${user?._id}`);
                      }}
                    >
                      <Button
                        component="button"
                        id="save"
                        className={styles.savePost}
                      >
                        <Donate
                          className={`highlight ${styles.donateStyles}`}
                        />
                        <p className={styles.donateTextStyles}>
                          {t("setting.donate")}
                        </p>
                      </Button>
                    </div>
                    <hr className={styles.horizontalLine} />
                    <center>
                      <Button
                        onClick={() => {
                          setState({ ...state, feedback: true });
                        }}
                        className={styles.leaveFeedBack}
                      >
                        <StarBorderIcon color="primay" />
                        {t("setting.leaveFeedback")}
                      </Button>
                    </center>
                    {state.feedback ? (
                      <FeedBack
                        open={state.feedback}
                        handleClose={handelFeedbackDialog}
                      />
                    ) : (
                      ""
                    )}
                    {open ? (
                      <Logout
                        open={open}
                        handleLogOut={handleLogOut}
                        handleLogOutModal={handleLogOutModal}
                        t={t}
                      />
                    ) : null}
                  </div>
                </MuiThemeProvider>
              </CardContent>
              {state.showError ? (
                <ShowError
                  open={state.showError}
                  handleClose={handleCloseError}
                  message={state.message}
                  severity={state.severity}
                />
              ) : null}
            </Card>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default Setting;
