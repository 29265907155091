import React, { useState, useCallback, useEffect } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import InteractionBar from "../InteractionBar/InteractionBar";
import PostUserInfo from "../PostUserInfo/PostUserInfo";
import styles from "./PostCard.module.scss";
import ReactPlayer from "react-player";
import Geocode from "react-geocode";
import ImageViewer from "react-simple-image-viewer";
import { useHistory } from "react-router-dom";
import { MentionsInput, Mention } from "react-mentions";
import "react-image-viewer-zoom/dist/style.css";
import defaultStyle from "../../dialogbox/defaultStyle";
import "./post.css";
import { useSelector } from "react-redux";
const PostCard = ({ postData, innerWidth, open, setClose }) => {
  const history = useHistory();
  const { hashTags } = useSelector((state) => state?.root);
  var resp = postData?.attachments[0]?.url?.split(".");
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(undefined);
  const [updatedText, setUpdatedText] = useState("");

  if (resp && resp.length > 0) {
    var img_type = resp[resp.length - 1].toLowerCase();
  }
  const openImageViewer = (index) => {
    console.log(index);
    setCurrentImage(index);
    setViewerIsOpen(true);
  };
  const closeImageViewer = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  var imgarr = [
    { src: postData?.attachments[0]?.url, title: "title", content: "content" },
  ];

  const handleText = (str) => {
    let test = "";
    if (str) {
      str.split(" ").map((subText, ind) => {
        if (subText.match("#")) {
          let test1 = subText.match(/(?<=\[).*?(?=\])/g);
          if (test1) {
            test = str.replace(`${subText}`, `#${test1[0]}`);
            str = test;
          }
        }
      });
      setUpdatedText(str);
    }
  };
  useEffect(() => {
    handleText(postData?.text);
  });

  return (
    <div
      className={styles.wrapper}
      style={{
        minWidth: `${innerWidth ? innerWidth : "1024px"}`,
        maxWidth: "inherit",
      }}
    >
      <PostUserInfo userData={postData} createdAt={postData?.createdAt} />
      {postData?.text && (
        <div className={styles.text}>
          {updatedText.split(" ").map((subText, ind) => {
            if (subText.match("#")) {
              return <span style={{ color: "blue" }}>{subText} </span>;
            } else {
              return <span style={{ color: "white" }}> {subText} </span>;
            }
          })}
        </div>
      )}

      {postData?.location?.streetAddress ? (
        <div className={styles.text}>
          {" "}
          {postData?.location?.streetAddress ? (
            <div style={{ color: "gray" }}>
              {" "}
              Location: {`${postData?.location?.streetAddress} `}
            </div>
          ) : (
            ""
          )}{" "}
        </div>
      ) : (
        ""
      )}
      {postData?.tagged.length > 0 ? (
        <div className={styles.text}>
          {" "}
          {postData?.tagged.length > 0 ? (
            <div style={{ color: "gray" }}>
              {postData?.tagged.map((tag, inx) => {
                return (
                  <span
                    style={{
                      color: "blue",
                      margin: "0 5px",
                      cursor: "pointer",
                    }}
                    key={inx}
                    onClick={() => {
                      history.push({
                        pathname: "/view-profile",
                        state: { userID: tag?._id },
                      });
                    }}
                  >
                    @{tag.userName}
                  </span>
                );
              })}
            </div>
          ) : (
            ""
          )}{" "}
        </div>
      ) : (
        ""
      )}

      {postData?.attachments?.length ? (
        <div className={styles.media}>
          {postData?.attachments[0]?.type?.substr(0, 5).toLowerCase() ===
          "image" ? (
            <>
              {imgarr.map((img, imind) => {
                return <img src={img.src} key={imind} className={styles.img} />;
              })}
            </>
          ) : postData?.attachments[0]?.type?.substr(0, 5).toLowerCase() ===
            "video" ? (
            <video
              src={postData?.attachments[0].url}
              type="video/mp4"
              controls={true}
              className={styles.img}
            />
          ) : (
            ""
          )}
        </div>
      ) : null}

      <InteractionBar
        postData={postData}
        interWidth={innerWidth}
        open={open}
        setClose={setClose}
      />
    </div>
  );
};

export default PostCard;
