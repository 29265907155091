import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./donation.module.scss";
import { CircularProgress } from "@material-ui/core";
import logo from "../../../../assets/images/favicons.ico";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import "./setting.css";
import { getData } from "../../../../utils/AppStorage";
import { useParams } from "react-router-dom";
import {
  GetSingleOpenUserProfile,
  StripeCharge,
} from "../../../../services/index";
import "./donate.css";
import Snackbar from "../../../Omegastore/SnackBar";
import { useTranslation } from "react-i18next";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51JSPisIsHCiCSokMLMp0nEIc0fHw07Oa3PY95oJGDQ2d2suX7gWfdddNuLYGSc1g7M7gXZ8bo4BdqPnHvSrNgt580062VsMf3S"
);
const Donation = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: false,
    userName: "",
    email: "",
  });
  const [errorfor, setError] = useState("");
  const [ErrorMsg, setErrorMsg] = useState("");
  const [toast, setToast] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [amount, setAmount] = useState(null);
  const params = useParams();

  useEffect(() => {
    console.log(params.id);

    GetSingleOpenUserProfile((data) => {
      console.log(data);
      if (data) {
        setState({
          ...state,
          userName: data?.userName,
          email: data?.email,
        });
      }
    }, params?.id);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = validate();
    if (valid) {
      // Block native form submission.
      setState({
        ...state,
        loading: true,
      });

      const { stripe, elements } = props;

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.log("[error]", error);
      } else {
        let body = {
          amount: Number(amount),
          customerId: params.id,
          paymentMethodId: paymentMethod.id,
        };
        StripeCharge((res) => {
          if (res) {
            cardElement.clear();
            setAmount(null);
            setToast({
              ...toast,
              show: true,
              message: "Payment Sent Successfully!",
              severity: "Success",
            });
            setState({
              ...state,
              loading: false,
            });
          } else {
            setToast({
              ...toast,
              show: true,
              message: "Payment Sending Failed!",
              severity: "Error",
            });
            setState({
              ...state,
              loading: false,
            });
          }
        }, body);

        console.log("[PaymentMethod]", paymentMethod);
      }
    }
  };
  const validate = () => {
    if (amount == null) {
      setError("Amount");
      setErrorMsg("Please Add the Amount");
      return false;
    } else if (amount == "") {
      setError("Amount");
      setErrorMsg("Please Add the Amount");
      return false;
    } else if (amount <= 0) {
      setError("Amount");
      setErrorMsg("Amount Must be greater than 0");
      return false;
    } else {
      return true;
    }
  };
  console.log("amount", amount);
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <img src={logo} alt="logo" style={{ height: "69px" }} />

          <div className={styles.main}>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={state.userName}
                disabled={true}
                placeholder="User Name"
              />
              <input
                type="text"
                value={state.email}
                disabled={true}
                placeholder="E-mail"
              />
              <input
                type="number"
                value={amount ? amount : ""}
                onChange={(e) => {
                  console.log(e.target.value);
                  setAmount(Number(e.target.value));
                }}
                placeholder={`${t("drawer.donate")}`}
              />
              {errorfor == "Amount" && ErrorMsg != "" ? (
                <p className="danger">{ErrorMsg}</p>
              ) : (
                ""
              )}
              <CardElement
                style={{ marginTop: "100px" }}
                options={{
                  style: {
                    base: {
                      marginTop: "10px",
                      fontSize: "16px",
                      color: "white",
                      "::placeholder": {
                        color: "#808080",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />

              <div className={styles.button}>
                <button>
                  {state.loading ? (
                    <CircularProgress size={30} />
                  ) : (
                    t("drawer.donate")
                  )}
                </button>
              </div>
            </form>
          </div>
          {/* <div className={styles.bottom}>
          </div> */}
        </div>
      </div>

      <Snackbar
        open={toast.show}
        handleClose={() =>
          setToast({
            ...toast,
            show: !toast.show,
          })
        }
        message={toast.message}
        severity={toast.severity}
      />
    </div>
  );
};
const InjectedCheckoutForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <Donation elements={elements} stripe={stripe} />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default InjectedCheckoutForm;
