import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styles from "./Omegastore.module.scss";
import Background from "./Background/Background";
import Corner from "./Corners/Corners";
import Nickname from "./Nickname/Nickname";
import { useLocation } from "react-router-dom";
import coin from "../../assets/images/coin.svg";
import { largeNumberShortify } from "../../utils/AppHelperMethods";
import { GetSingleUserProfile } from "../../services";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Omegastore = () => {
  let location = useLocation();
  let data = location?.state?.data;
  const { user } = useSelector((state) => state.root);
  const [value, setValue] = React.useState(location?.state?.tab);
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();
  let userID = user?._id;
  useEffect(() => {
    GetSingleUserProfile((dta) => {
      if (dta) {
        setUserData(dta);
      } else {
        setUserData(data);
      }
    }, userID);
  }, [userID]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className={styles.centerCoin}>
        <img className={styles.imgStyles} src={coin} alt="coin logo" />*{" "}
        {largeNumberShortify(userData?.earnedCoins)} OmegaCoins
      </div>
      <AppBar position="static" className={styles.appHeader}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          style={{ marginLeft: "132px" }}
        >
          <Tab label={t("omegaStore.background")} {...a11yProps(0)} />
          <Tab label={t("omegaStore.corners")} {...a11yProps(1)} />
          <Tab label={t("omegaStore.nickname")} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Background />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Corner user={userData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Nickname />
      </TabPanel>
    </div>
  );
};
export default Omegastore;
