import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress, Grid, TextareaAutosize } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import TextField from "@material-ui/core/TextField";
import style from "./FeedBack.module.scss";
import { Alert, AlertTitle } from "@material-ui/lab";
import { SendFeedback } from "../../../../../services/feedbackService";
import { useTranslation } from "react-i18next";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const PrettoSlider = withStyles({
  root: {
    color: "#e37c2d",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fc7844",
    border: "2px solid #fc7844",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const FeedBack = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    description: "",
  });
  const [loader, setLoader] = useState(false);
  const [successbool, setsucessbool] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));
  const handleSubmit = () => {
    setLoader(true);
    SendFeedback(values, (data) => {
      if (data) {
        setLoader(false);
        setsucessbool(true);
        setTimeout(() => {
          setsucessbool(false);
          handleClose();
        }, 3000);
      }
    });
    console.log("thank you clicke!", values);
  };

  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("setting.feedHeading1")}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{t("setting.feedPara1")}</Typography>

          <Typography gutterBottom>{t("setting.feedPara2")}</Typography>
          <Typography gutterBottom>{t("setting.feedHeading2")}</Typography>
          <PrettoSlider
            style={{ marginTop: "50px" }}
            aria-label="pretto slider"
            defaultValue={5}
            valueLabelDisplay="on"
            min={0}
            step={0.1}
            max={10}
          />
          {successbool ? (
            <Grid>
              <Alert severity="success">
                Thank you <strong>For the Feedback!</strong>
              </Alert>
            </Grid>
          ) : (
            ""
          )}
          {/* <UserAvatar source={user?.pic} size={"40px"} /> */}
          <div>
            <TextareaAutosize
              rowsMax={60}
              rowsMin={7}
              cols={70}
              aria-label="maximum height"
              placeholder={`${t("setting.feedPH")}`}
              onChange={(event) => {
                setValues({ ...values, description: event.target.value });
              }}
            />
            {/* <TextField
              id="standard-basic"
              label="What went Wrong?"
              style={{ width: "91%", marginLeft: "0.3rem" }}
              onChange={(event) => {
                setValues({ ...values, description: event.target.value });
              }}
            /> */}
          </div>
        </DialogContent>
        <DialogActions>
          {values.description !== "" ? (
            <Button onClick={handleSubmit} className={style.submitButton}>
              {loader ? (
                <CircularProgress size="30px" color="danger" />
              ) : (
                t("setting.feedSend")
              )}
            </Button>
          ) : (
            <Button disabled className={style.disableButton}>
              {t("setting.feedSend")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default FeedBack;
