import notifications from "../../../../../assets/images/icons_assistenza1.svg";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../../../../../styles/loader.css";
import styles from "../Setting.module.scss";
const EnableNotifications = ({
  checkedNotification,
  handleNotificationsChecked,
  t,
}) => {
  return (
    <div className={styles.notifContainer}>
      <div className={styles.notifInnerContainer}>
        <div className={styles.notifIconContainer}>
          <div className={styles.notifImageInnerContainer}>
            <img
              src={notifications}
              alt="notifications"
              className={styles.notifImageStyles}
            />
          </div>
        </div>
        <div className={styles.textContainer}>
          <p>{t("setting.notifications")}</p>
        </div>
      </div>
      <div className={styles.toggleButtonContainer}>
        <div className={styles.toggleButtonInnerContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={checkedNotification}
                onChange={handleNotificationsChecked}
                name="checkedNotification"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
export default EnableNotifications;
