import React, { useState } from "react";
import styles from "./ProfileTabs.module.scss";
import { ReactComponent as Posts } from "../../../assets/images/profile-posts.svg";
import { ReactComponent as Media } from "../../../assets/images/profile-media.svg";
import { ReactComponent as Reviews } from "../../../assets/images/profile-reviews.svg";
import { ReactComponent as Tags } from "../../../assets/images/profile-tags.svg";

const ProfileTabs = ({ onTabChange }) => {
  const [state, setState] = useState({
    selectTab: "posts",
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <div
          className={styles.tabItem}
          onClick={() => {
            if (onTabChange) {
              onTabChange("posts", (type) => {
                setState({
                  ...state,
                  selectTab: type,
                });
              }); //<----dont change
            }
          }}
        >
          <Posts className={state.selectTab === "posts" ? styles.img : ""} />
          {/* <img src={posts} alt="posts" /> */}
        </div>
        <div
          className={styles.tabItem}
          onClick={() => {
            if (onTabChange)
              onTabChange("media", (type) => {
                setState({
                  ...state,
                  selectTab: type,
                });
              }); //<----dont change
          }}
        >
          <Media className={state.selectTab === "media" ? styles.img : ""} />
          {/* <img src={media} alt="media" /> */}
        </div>
        <div
          className={styles.tabItem}
          onClick={() => {
            if (onTabChange)
              onTabChange("reviews", (type) => {
                setState({
                  ...state,
                  selectTab: type,
                });
              }); //<----dont change
          }}
        >
          <Reviews
            className={state.selectTab === "reviews" ? styles.img : ""}
          />
          {/* <img src={reviews} alt="users" /> */}
        </div>
        <div
          className={styles.tabItem}
          onClick={() => {
            if (onTabChange)
              onTabChange("tags", (type) => {
                setState({
                  ...state,
                  selectTab: type,
                });
              }); //<----dont change
          }}
        >
          <Tags className={state.selectTab === "tags" ? styles.img : ""} />
          {/* <img src={tags} alt="users" /> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileTabs;
