import React from "react";
import styles from "./Navigation.module.scss";
import { NavLink, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getData } from "../../../utils/AppStorage";
import "./rightbar.css";
import cross from "../../../assets/images/cross.png";
// import { NavLink } from "react-router-dom";
import "./rightbar.css";
import { useTranslation } from "react-i18next";
function NavMobile({ toggleMenu, setToggleMenu, openDialog, setDialog }) {
  let history = useHistory();
  const { t } = useTranslation();
  let userData = JSON.parse(localStorage.getItem("user"));
  const { user } = useSelector((state) => state.root);
  return (
    <div className={`${toggleMenu ? "block" : "sidebar"} `}>
      <div className="left"></div>
      <div className={`${toggleMenu ? "right-menu" : "right-close"} `}>
        <img
          src={cross}
          onClick={() => setToggleMenu(false)}
          className="close-image-icon"
        />
        <div className="menu">
          <NavLink
            exact
            to="/"
            className="inactive"
            activeClassName={styles.active}
            onClick={() => setToggleMenu(false)}
          >
            <div className="homeIcon"></div>
            <div className="text">{t("navigation.home")}</div>
          </NavLink>

          <NavLink
            to="/explore"
            className="inactive"
            activeClassName={styles.active}
            onClick={() => setToggleMenu(false)}
          >
            <div className="exploreIcon"></div>
            <div className="text">{t("navigation.explore")}</div>
          </NavLink>

          <NavLink
            to="/reviews"
            className="inactive"
            activeClassName={styles.active}
            onClick={() => setToggleMenu(false)}
          >
            <div className="reviewsIcon"></div>
            <div className="text">{t("navigation.reviews")}</div>
          </NavLink>

          <NavLink
            to="/quests"
            className="inactive"
            activeClassName={styles.active}
            onClick={() => setToggleMenu(false)}
          >
            <div className="questsIcon"></div>
            <div className="text">{t("navigation.quests")}</div>
          </NavLink>

          <div
            className="inactive"
            activeClassName={styles.active}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setToggleMenu(false);
              setDialog(true);
            }}
          >
            <div className="postIcon"></div>
            <div className="text">{t("navigation.newPost")}</div>
          </div>

          <NavLink
            to={{
              pathname: "/customize",
              state: { userData: user },
            }}
            className="inactive"
            activeClassName={styles.active}
            onClick={() => {
              setToggleMenu(false);
            }}
          >
            <div className="customizeIcon"></div>
            <div className="text">{t("navigation.customize")}</div>
          </NavLink>
          <NavLink
            to="/setting"
            className="inactive"
            activeClassName={styles.active}
            onClick={() => setToggleMenu(false)}
          >
            <div className="settingIcon"></div>
            <div className="text">{t("navigation.settings")}</div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default NavMobile;
