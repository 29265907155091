import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import verified from "../../../../assets/images/verified.svg";
import UserAvatar from "../../UserAvatar/UserAvatar";
import styles from "./UserToFollow.module.scss";
import { useHistory } from "react-router-dom";
const UserToFollow = ({ userData, followUnfollowUser, t }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.root);
  const [data, setData] = useState(userData);
  useEffect(() => {
    setData(userData);
  }, [userData]);
  return (
    <div className={styles.wrapper}>
      <UserAvatar source={userData?.pic} size={"50px"} />
      <div className={styles.userInfo}>
        <div
          className={styles.info}
          onClick={() => {
            history.push({
              pathname: "/view-profile",
              state: { userID: data?._id },
            });
          }}
        >
          <div className={styles.infoTop}>
            <div className={styles.name}>{data?.userName}</div>
            {data?.isVerified && <img src={verified} alt="verified" />}
            <div className={styles.followers}>{data?.level}</div>
          </div>
          <div className={styles.nickname}>{data?.nickName}</div>
        </div>

        {user?._id !== data?._id ? (
          data?.isFollowing ? (
            <div
              className={styles.follow}
              onClick={() => {
                if (followUnfollowUser) followUnfollowUser("following");
              }}
            >
              <button>{t("profile.following").toUpperCase()}</button>
            </div>
          ) : data?.isRequested ? (
            <div
              className={styles.follow}
              onClick={() => {
                if (followUnfollowUser) followUnfollowUser("requested");
              }}
            >
              <button>{t("profile.requested").toUpperCase()}</button>
            </div>
          ) : !data?.isFollowing ? (
            <div
              className={styles.follow}
              onClick={() => {
                if (followUnfollowUser) followUnfollowUser("follow");
              }}
            >
              <button>{t("profile.follow").toUpperCase()}</button>
            </div>
          ) : (
            ""
          )
        ) : null}
      </div>
    </div>
  );
};

export default UserToFollow;
