import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItemText,
} from "@material-ui/core";
export default function SkeleLoader() {
  return (
    <ListItem>
      <SkeletonTheme color="#E0E0E0" highlightColor="#B8B8B8">
        <ListItemAvatar>
          <Skeleton circle={true} height={50} width={50} />
        </ListItemAvatar>
      </SkeletonTheme>
      <ListItemText>
        <SkeletonTheme color="#E0E0E0" highlightColor="#B8B8B8">
          <Skeleton style={{ fontSize: 20, lineHeight: 4 }} />
        </SkeletonTheme>
      </ListItemText>
    </ListItem>
  );
}
