import React, { useState, useEffect } from "react";
import ToolbarButton from "../ToolbarButton";
import leftArrow from "../../../assets/images/left-arrow.png";
import { CHAT_SOCKET_EVENTS } from "../../../utils/AppConstants";
import { initSocket, socket } from "../../../services/socketService";
import { v1 } from "uuid";
import { useSelector, useDispatch } from "react-redux";

import cross from "../../../assets/images/cross.png";
import sampleMedia from "../../../assets/images/sample-media.png";
import rightArrow from "../../../assets/images/right-arrow.png";
import { setComment } from "../../../redux/reducers/commentSlice";

import "./NewChat.scss";

const NewChat = ({
  handleClose,
  show,
  friends,
  startNewChat,
  conversationsData,
  chatData,
  searchQuery,
  setSearchQuery,
  loading,
  t,
}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const [messageOpen, setMessageOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const user = useSelector((state) => state.root.user);
  const [message, setMessage] = useState("");

  const toggleMessageContainer = () => {
    setMessageOpen(!messageOpen);
  };

  const discardChanges = () => {
    setMessage("");
    setMessageOpen(false);
  };
  useEffect(() => {
    initSocket(user?.token);

    selectedContact &&
      socket.emit(CHAT_SOCKET_EVENTS.CONNECTED_WITH, {
        connectWith: selectedContact._id,
      });

    return () => {
      socket.emit(CHAT_SOCKET_EVENTS.CONNECTED_WITH, { connectWith: "" });
    };
  }, [selectedContact]);

  const onSend = (val) => {
    if (val.trim()) {
      let guidd = v1();
      let new_message = {
        guid: guidd,
        text: val.trim(),
        message: val.trim(),
        createdAt: new Date(),
        to: selectedContact?._id || "",
      };
      socket.emit(CHAT_SOCKET_EVENTS.NEW_MESSAGE, new_message);
    }
    conversationsData();
    handleClose();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <img src={cross} onClick={handleClose} className="close-icon" />
        {/* {friends.length > 0 &&
        friends?.find((i) =>
          chatData.find((ii) => i._id === ii?._id.substr(25, 40))
        ) ? (
          <p className="newchat_title">You Already in Conversation</p>
        ) : (
          <p className="newchat_title">Start a new chat</p>
        )} */}
        <input
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
          className="input-styles"
          placeholder={t("dashboard.search")}
        />
        <div className="container-styles">
          {loading ? (
            <div className="loader alignModal"></div>
          ) : (
            <div>
              {messageOpen ? (
                <>
                  <div className="new-message-header">
                    <img
                      src={leftArrow}
                      className="left-arrow"
                      onClick={discardChanges}
                    />
                  </div>
                  <div className="new-message-container">
                    <p className="new-message-name">
                      {selectedContact?.userName}
                    </p>

                    <textarea
                      className="modal-input"
                      cols="40"
                      rows="5"
                      placeholder="Type a message"
                      onChange={(text) => {
                        setMessage(text.target.value);
                      }}
                    ></textarea>

                    <div
                      onClick={() => {
                        onSend(message);
                        setMessageOpen(false);
                      }}
                      style={{
                        width: "100%",
                        border: "2px solid #007aff",
                        borderRadius: "29px",
                        textAlign: "center",
                        marginTop: "4px",
                      }}
                    >
                      {/* Send */}
                      <ToolbarButton key="emoji" icon="ion-ios-send" />
                    </div>
                  </div>
                </>
              ) : friends.length > 0 ? (
                friends?.map((item, index) => {
                  return chatData.find(
                    (ii) => item._id === ii?.message?.to?._id
                  ) ? (
                    ""
                  ) : (
                    <NewChatCard
                      key={index}
                      item={item}
                      toggleMessageContainer={toggleMessageContainer}
                      handleClose={handleClose}
                      setSelectedContact={setSelectedContact}
                    />
                  );
                })
              ) : (
                <div className="empty-chat-container">
                  <p className="emptyFriends">{t("chat.noFriend")}</p>
                  <p className="emptySub">{t("chat.moreFriend")}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

const NewChatCard = ({ item, toggleMessageContainer, setSelectedContact }) => {
  return (
    <div
      className="chat-card-container"
      onClick={() => {
        setSelectedContact(item);
        toggleMessageContainer();
      }}
    >
      <div className="chat-card-sub">
        <img src={item.pic} className="card-avatar" />
        <p className="card-name">{item.userName} </p>
      </div>

      <img src={rightArrow} className="right-arrow" />
    </div>
  );
};

export default NewChat;
