import { createSlice } from "@reduxjs/toolkit";

const initState = [];

export const commentListSlice = createSlice({
  name: "commentList",
  initialState: initState,
  reducers: {
    setCommentList: (state, action) => action.payload,
    resetCommentList: () => initState,
  },
});

export const { setCommentList, resetCommentList } = commentListSlice.actions;

export default commentListSlice.reducer;
