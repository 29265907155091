import { createSlice } from "@reduxjs/toolkit";

const initState = {
  requests: [],
  otherNotifications: [],
  counter: 0,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initState,
  reducers: {
    setNotifications: (state, action) => ({ ...state, ...action.payload }),
    resetNotifications: () => initState,
    notificationCounter: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  setNotifications,
  resetNotifications,
  notificationCounter,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
