import React, { useState, useEffect } from "react";
import "./coomentBar.css";
import like from "../../../../../assets/images/like.svg";
import likeActive from "../../../../../assets/images/like-active.svg";
import comment from "../../../../../assets/images/comment.svg";
import share from "../../../../../assets/images/share.svg";
import save from "../../../../../assets/images/save.svg";
import Heart from "../../../../../assets/images/heart.svg";
import { largeNumberShortify } from "../../../../../utils/AppHelperMethods";
import AddComment from "./addComments/addComment";
import { CommentReaction, GetCommentsReplies } from "../../../../../services";
import { useDispatch } from "react-redux";
import { setComment } from "../../../../../redux/reducers/commentSlice";
import ComentItem from "../listComment/listItem";
import { EditorState, ContentState } from "draft-js";
import {
  GetCommentsOfPost,
  DeleteCommentOfPost,
} from "../../../../../services";

const CommentBar = ({
  commentData,
  commentHandler,
  commentid,
  rply,
  Cmntstate,
  Cmntsetstate,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isLiked: commentData?.isLiked || false,
    dummValue: 0,
    value: commentData?.computed?.find((ii) => ii.key === "LIKE")?.value,
    mention: {},
    CommentBoolean: false,
    toggleCommentBox: false,
    specificAddCommentId: "",
    reply: [],
  });
  const [nstate, nsetstate] = React.useState({
    user: "",
    comment: "",
    currentFocus: null,
    mentionUser: [],
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEdit = (eidobj) => {
    if (eidobj != "") {
      setEditorState(() =>
        EditorState.createWithContent(ContentState.createFromText(eidobj?.text))
      );

      // setState(() => ({
      //   ...state,
      //   toggleCommentBox: !state.toggleCommentBox ? true : false,
      //   mention: commentData,
      //   specificAddCommentId: commentData?._id,
      // }));
      Cmntsetstate({
        ...Cmntstate,
        comment: eidobj.text,
        mentionUser: eidobj.mentions,
        eidId: eidobj?._id,
        parentComment: eidobj.parentComment,
        post: eidobj.post,
      });
      nsetstate({
        ...nstate,
        comment: eidobj?.text,
        eidId: eidobj?._id,
        mentionUser: eidobj?.mentions,
      });
    }
  };
  useEffect(() => {
    CommentReplies();

    setState((prev) => ({
      ...prev,
      isLiked: commentData?.isLiked || false,
      dummValue: state.dummValue,
      value: commentData?.computed?.find((ii) => ii.key === "LIKE")?.value || 0,
    }));
  }, []);

  const handleDelete = (delId) => {
    console.log(delId);
    DeleteCommentOfPost(() => {
      dispatch(setComment("good"));
      // let clonestate = [...state.allComment];
      // let findInd = clonestate.findIndex((i) => i._id === delId?._id);
      // if (findInd > -1) {
      //   clonestate.splice(findInd, 1);
      //   setState({
      //     ...state,
      //     allComment: clonestate,
      //   });
      // }
    }, delId?._id);
  };
  const CommentReplies = () => {
    GetCommentsReplies(
      (data) => {
        setState({
          ...state,
          reply: data,
        });
      },
      false,
      200,
      commentData?._id
    );
  };

  return (
    <div
      className="comment_wrapper comment_wrapper_border"
      // style={{ minWidth: "500px" }}
    >
      <div className="bar">
        <div
          className="item"
          onClick={() => {
            CommentReaction(
              () => {
                setState((prev) => ({
                  ...prev,
                  isLiked: !state.isLiked,

                  value:
                    state.value == 0 && state.dummValue == 0 && state.isLiked
                      ? state.value + 1
                      : state.value == 1 &&
                        state.dummValue == 1 &&
                        state.isLiked
                      ? state.value - 1
                      : state.value && state.dummValue == 0 && state.isLiked
                      ? state.value - 1
                      : state.value + 1,
                  dummValue: !state.dummValue ? 1 : 0,
                }));
                dispatch(setComment({}));
              },
              commentData?._id,
              {
                type: state.isLiked ? "UN_LIKE" : "LIKE",
              }
            );
          }}
        >
          <div className="">
            <img
              className="icon_sub_comment"
              src={state.isLiked ? likeActive : like}
              alt="like"
            />
          </div>
          <div className="" style={{ color: "#808080" }}>
            {largeNumberShortify(state.value)}
          </div>
        </div>
        {commentHandler ? (
          <div
            className="item"
            onClick={() => {
              setState(() => ({
                ...state,
                toggleCommentBox: !state.toggleCommentBox ? true : false,
                mention: commentData,
                specificAddCommentId: commentData?._id,
              }));
              return commentHandler(commentData?._id);
            }}
          >
            <div className="">
              <img src={comment} alt="comments" />
            </div>
            <div className="" style={{ color: "#808080" }}>
              {largeNumberShortify(rply?.length || 0)}
            </div>
          </div>
        ) : (
          <div
            className="item"
            onClick={() => {
              setState(() => ({
                ...state,
                mention: commentData,
                specificAddCommentId: commentData?._id,
                CommentBoolean: state.CommentBoolean ? false : true,
              }));
            }}
          >
            <div>
              <img src={comment} alt="comments" />
            </div>
            <div className="" style={{ color: "#808080" }}>
              {largeNumberShortify(rply?.length || 0)}
            </div>
          </div>
        )}
      </div>
      {state.toggleCommentBox && commentid === commentData?._id ? (
        <div
          style={{
            paddingTop: "10px",
            minWidth: "100%",
            width: "100%",
          }}
        >
          {rply && rply.length > 0 ? (
            <>
              {rply.map((resp, indx) => {
                return (
                  <>
                    {Cmntstate?.eidId && Cmntstate?.eidId == resp?._id ? (
                      <>
                        <AddComment
                          state={Cmntstate}
                          setstate={Cmntsetstate}
                          commentData={commentData}
                          inputWidth={"400px"}
                          editorState={editorState}
                          setEditorState={setEditorState}
                          EditorState={EditorState}
                        />
                      </>
                    ) : (
                      <ComentItem
                        resp={resp}
                        handleEdit={() => handleEdit(resp)}
                        handleDelete={handleDelete}
                        key={`name${resp._id}`}
                      />
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <>
              <AddComment
                state={nstate}
                setstate={nsetstate}
                commentData={commentData}
                inputWidth={"400px"}
                editorState={editorState}
                setEditorState={setEditorState}
                EditorState={EditorState}
              />
            </>
          )}
        </div>
      ) : Object.keys(state.mention).length > 0 && state.CommentBoolean ? (
        <>
          <AddComment
            state={nstate}
            setstate={nsetstate}
            commentData={state.mention}
            inputWidth={"400px"}
            editorState={editorState}
            setEditorState={setEditorState}
            EditorState={EditorState}
          />
        </>
      ) : state.mention?._id == state.specificAddCommentId ? (
        ""
      ) : (
        // <AddComment commentData={state.mention} inputWidth={"400px"} />
        ""
      )}
      {/* {console.log(
        "mention",
        state.mention,
        "specific comment",
        state.specificAddCommentId,
        "boolean",
        state.CommentBoolean
      )} */}
    </div>
  );
};

export default CommentBar;
