import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  List,
  ListItem,
  Divider,
  ListItemText,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function LocationDialog({ location, setlocation }) {
  const [state, setState] = useState({ addrss: "", bool: true });
  const [suggestn, setSuggestion] = useState([]);
  const classes = useStyles();
  const handleChange = (addrss) => {
    if (addrss === "") {
      setlocation({
        ...location,
        street: "",
        address: "",
        country: "",
        cordinate: "",
      });
    }
    setState({ addrss });
  };
  //   console.log(location);
  if (location.address !== "" && state.addrss === "" && state.bool) {
    setState({
      ...state,
      addrss: location.address,
      bool: false,
    });
  }
  const handleSelect = (address) => {
    console.log(address);
    geocodeByAddress(address)
      .then((resp) => {
        var coordinates = "";
        var addrs = "";
        var country = "";
        getLatLng(resp[0]).then((info) => {
          coordinates = `${info?.lat},${info?.lng}`;
          const address = resp[0].formatted_address.split(",");
          country = address[address.length - 1];
          console.log("address 1", address[1]);
          addrs = address[1] ? address[0]?.concat(address[1]) : address[0];
          setlocation({
            ...location,
            street: resp[0].formatted_address,
            address: addrs,
            country: country,
            cordinate: coordinates,
          });
          setState({ ...state, addrss: addrs });
          setSuggestion([]);
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  return (
    <PlacesAutocomplete
      value={state.addrss}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        if (suggestions.length > 0) {
          setSuggestion(suggestions);
        }
        return (
          <div>
            <TextField
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
              style={{ width: "500px" }}
              value={state.addrss}
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {/* {suggestn.length > 0 ? <h3>Latest Search</h3> : ""} */}

              {suggestn.map((suggestion) => {
                return (
                  <div {...getSuggestionItemProps(suggestion, {})}>
                    <List className={classes.root} style={{ width: "500px" }}>
                      <ListItem alignItems="flex-start">
                        <ListItemText primary={suggestion.description} />
                      </ListItem>
                    </List>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
}
