import { JSONBodyHelper } from ".";
import { EndPoints } from "../utils/AppEndpoints";
import { AppLogger, AppShowToast } from "../utils/AppHelperMethods";
import { GenerateThumbnailFromVideo } from "../utils/AppMediaPicker";
import Interceptor from "../utils/Interceptor";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// current vid size = 2498125
async function VideoAndImageCompressor(image) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  const compressedFile = await imageCompression(image, options);
  return compressedFile;
}

function postFiles(callback, fileName, bucket, data) {
  axios(EndPoints.UPLOAD_MEDIA + bucket, {
    method: "POST",
    headers: Interceptor.getHeadersMultiPart(),
    body: data,
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      if (status === 201 || status === 200) {
        console.log("success");
        let uploaderResponse = data?.data?.media[0];
        callback({
          ...uploaderResponse,
          name: fileName,
          oType: uploaderResponse?.type,
          type: uploaderResponse?.type
            ? uploaderResponse?.type.includes("video")
              ? "video"
              : "photo"
            : "photo",
        });
      } else {
        console.log("else");
        AppShowToast(data?.message || "Failed to upload");
        callback(false);
      }
    })
    .catch((error) => {
      console.log("Inside catch block");
      console.log(error);

      callback(false);
    });
}
export const uploadP2pDisputeFile = async (bucket, file) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const data = new FormData();
  data.append("file", file);
  const url = EndPoints.UPLOAD_MEDIA + bucket;
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
const UploadMedia = (callback, bucket, mediaObj) => {
  let type = mediaObj.type;
  if (type.includes("image")) {
    // uploadP2pDisputeFile(bucket, mediaObj)
    //   .then((res) => callback(res))
    //   .catch((err) => callback(false));
    VideoAndImageCompressor(mediaObj).then((compressedFile) => {
      uploadP2pDisputeFile(bucket, compressedFile)
        .then((res) => callback(res))
        .catch((err) => callback(false));
    });
  } else {
    uploadP2pDisputeFile(bucket, mediaObj)
      .then((res) => callback(res))
      .catch((err) => callback(false));
  }

  // postFiles((fileUploadRes) => {
  //     console.log(fileUploadRes)
  // }, "nameofthefile", "PROFILE_DOC", multiFormData)
};
// .then((compressionResponse) => {
//     debugger
//     let fileName = 'assetmedia.' + (compressionResponse.oType.split('/')[1] ? ('' + compressionResponse.oType.split('/')[1]) : '');
//     let multiFormData = new FormData()
//     multiFormData.append('files', { uri: compressionResponse?.compressed?.uri, name: fileName, type: compressionResponse?.oType });
//     postFiles((fileUploadRes) => {

//         if (fileUploadRes) {
//             if (mediaObj?.type === 'video' || mediaObj?.type?.includes('video')) {
//                 GenerateThumbnailFromVideo((thumbnail) => {
//                     if (thumbnail) {
//                         let multiFormDataForThumbnail = new FormData()
//                         multiFormDataForThumbnail.append('files', { uri: thumbnail, name: "thumbnail.png", type: 'image/png' });
//                         debugger
//                         postFiles((thumbnailUploadRes) => {
//                             if (thumbnailUploadRes) {
//                                 callback({ ...fileUploadRes, thumbnail: { thumbnail: true, url: thumbnailUploadRes.url, oType: 'image/png' } })
//                             } else {
//                                 callback(fileUploadRes)
//                             }
//                         }, "thumbnail.png", bucket, multiFormDataForThumbnail)
//                     } else {
//                         callback(fileUploadRes)
//                     }
//                 }, compressionResponse?.compressed?.uri)
//             } else {
//                 callback(fileUploadRes)
//             }
//         } else {
//             callback(false)
//         }
//     }, fileName, bucket, multiFormData)
// }).catch(err => {
//     AppLogger('--------ERROR COMMPRESSING MEDIA -------->\n', err)
//     callback(false)
// });
export { UploadMedia };
