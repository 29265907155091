import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/favicons.ico";
import styles from "./VerifyEmail.module.scss";
import { VerifyUserEmail } from "../../services/authService";
import PinInput from "react-pin-input";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import { ResendVerificationCode } from "../../services/authService";
import { SecurityCodeTypeEnum } from "../../utils/AppConstants";
import Timer from "./Timer";
const VerifyEmail = (props) => {
  let history = useHistory();
  const [showTime, setShowTime] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [state, setState] = useState({
    verifyCode: 0,
    loading: false,
    value: "",
    error: false,
    severity: "",
    message: "",
    verificationLoading: false,
  });
  const handleSecond = (sec) => {
    setSeconds(sec);
  };
  const handleShowTime = (show) => {
    setShowTime(show);
  };

  const onsubmit = (e) => {
    e.preventDefault();
    let formData = {
      email: props.email,
      code: parseInt(state.value),
    };
    setState({ ...state, loading: true });
    VerifyUserEmail((res) => {
      if (res) {
        history.push("/");
      } else {
        setState({
          ...state,
          error: true,
          severity: "error",
          message: "Please enter the same verificaiton code!",
          loading: false,
        });
      }
    }, formData);
  };
  const onChange = (value) => {
    setState({ ...state, value: value });
  };

  const handleChange = (e) => {
    setState((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
  };

  const handleResendCode = () => {
    setState({ ...state, verificationLoading: true });
    ResendVerificationCode(
      (res) => {
        if (res) {
          setState({
            ...state,
            error: true,
            severity: "success",
            message: "We have sent you the verification code!",
            verificationLoading: false,
          });
          setSeconds(60);
          setShowTime(true);
        } else {
          setState({
            ...state,
            error: true,
            severity: "error",
            message: "Unable to send you verification code, Please try again!",
            loading: false,
          });
        }
      },
      { email: props.email, type: SecurityCodeTypeEnum.verification }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={logo} alt="logo" style={{ height: "69px" }} />
          {/* <img src={logo} alt="logo" /> */}
        </Link>
      </div>
      <div className={styles.card}>
        <div className={styles.main}>
          <form onSubmit={onsubmit}>
            <PinInput
              length={4}
              focus
              // disabled
              inputFocusStyle={{ borderColor: "blue" }}
              inputStyle={{ borderColor: "#868686" }}
              type="numeric"
              onChange={onChange}
            />
            <div className={styles.row}>
              <div className={styles.forget}>
                {state.verificationLoading && !showTime && (
                  <CircularProgress size={30} />
                )}
                {!state.verificationLoading && showTime && (
                  <div className={styles.alignResend}>
                    <button
                      onClick={handleResendCode}
                      className={styles.disableResend}
                      disabled
                    >
                      Resend Verification Code:
                    </button>
                    <Timer
                      seconds={seconds}
                      handleSecond={handleSecond}
                      handleShowTime={handleShowTime}
                    />
                  </div>
                )}
                {!state.verificationLoading && !showTime && (
                  <button onClick={handleResendCode} className={styles.resend}>
                    Resend Verification Code
                  </button>
                )}
              </div>
            </div>
            <div className={styles.button}>
              <button>
                {state.loading ? (
                  <CircularProgress size={30} />
                ) : (
                  "VERIFY EMAIL"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      {state.error ? (
        <Snackbar
          open={state.error}
          autoHideDuration={4000}
          onClose={() => {
            setState({ ...state, error: false });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => {
              setState({ ...state, error: false });
            }}
            severity={state.severity}
          >
            {state.message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </div>
  );
};

export default VerifyEmail;
