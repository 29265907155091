import React, { useEffect, useState } from "react";
import styles from "./ProfileInfo.module.scss";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import moment from "moment";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import TableComp from "./table";

const ProfileInfo = ({ userData, t }) => {
  const history = useHistory();
  const [user, setUser] = useState(userData);
  let actualUser = JSON.parse(localStorage.getItem("user"));
  const [values, setValues] = useState({
    dropDown: true,
  });
  useEffect(() => {
    setUser(userData);
  }, [userData]);
  function createData(type, value) {
    return { type, value };
  }
  let userinfo = user?.firstName;
  // console.log(user)
  if (user?.firstName && user?.lastName !== "undefined") {
    userinfo =
      user?.firstName && user?.lastName
        ? user?.firstName + " " + user?.lastName
        : user?.firstName;
  }

  const rows = [
    createData(t("profile.name"), userinfo),
    createData(
      "DOB",
      user &&
        user?.dateOfBirth !== undefined &&
        moment(user?.dateOfBirth).subtract(0, "days").calendar()
    ),
    createData(t("profile.favGame"), user?.favouriteGame),
    createData(t("profile.favGameGenre"), user?.FavouriteVideogameGenre),
    createData(t("profile.ownedConsoles"), user?.ownedConsoles?.toString()),
    createData(
      t("profile.favConsole"),
      user && user?.favouriteConsole !== undefined && user?.favouriteConsole
    ),
    createData(
      t("profile.xbox"),
      user?.gamingAccounts !== undefined && user?.gamingAccounts[0]?.account
    ),
    createData(
      t("profile.psn"),
      user?.gamingAccounts !== undefined && user?.gamingAccounts[1]?.account
    ),
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <p>{user?.bio}</p>
        </div>
        <div className={styles.readMore}>
          <div style={{ display: "flex" }}>
            <div className={styles.body}>{t("profile.moreAbout")}</div>
            {values.dropDown ? (
              <IconButton
                onClick={(event) => {
                  setValues({ ...values, dropDown: !values.dropDown });
                }}
              >
                <ArrowDropDownIcon
                  name="dropDown"
                  className={styles.iconStyle}
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={(event) => {
                  setValues({ ...values, dropDown: !values.dropDown });
                }}
              >
                <ArrowDropUpIcon className={styles.iconStyle} />
              </IconButton>
            )}
          </div>
        </div>
        <div>
          {values.dropDown ? null : (
            <div className={styles.body}>
              <TableComp rows={rows} />
            </div>
          )}
        </div>
        {actualUser?._id === userData?._id ? (
          <div className={styles.edit}>
            <button
              onClick={() => {
                history.push({
                  pathname: "/edit-profile",
                  state: { userData: user },
                });
              }}
            >
              {t("profile.editProfile")}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default ProfileInfo;
