import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import DisplayPicture from "../../Profile/Customize/CustomizeTabs/Background/DisplayPicture";
import { GetAllAssets } from "../../../services/customizationService";
import { ASSET_TYPES } from "../../../utils/AppConstants";
import BuyAvatar from "../BuyAvatar";
import "../../../styles/loader.css";

const Background = () => {
  const [background, setBackground] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [avatarData, setAvatarData] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  useEffect(() => {
    setLoading(true);
    GetAllAssets((res) => {
      setBackground(res);
      setLoading(false);
    }, ASSET_TYPES.BACKGROUND);
  }, [reloadData]);
  const handleModalData = (picData) => {
    setAvatarData(picData);
    setOpenDialog(!openDialog);
  };
  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
    setReloadData(!reloadData);
  };
  return (
    <div>
      {loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <Grid
          container
          spacing={1}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {background?.length > 0
            ? background?.map((item, index) => (
                <DisplayPicture
                  source={item}
                  handleModalData={handleModalData}
                />
              ))
            : null}
          {openDialog ? (
            <BuyAvatar
              open={openDialog}
              handleClose={handleCloseDialog}
              data={avatarData}
            />
          ) : null}
        </Grid>
      )}
    </div>
  );
};
export default Background;
