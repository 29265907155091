import React, { useEffect, useState } from "react";
import styles from "../../styles/pages.module.scss";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import ProfileTabs from "./ProfileTabs/ProfileTabs";
import { useLocation } from "react-router-dom";
import { GetSingleUserProfile } from "../../services";
import { useSelector } from "react-redux";
import UserProfilePosts from "./UserProfilePosts";
import UserProfileMedia from "./UserProfileMedia";
import UserProfileReviews from "./UserProfileReviews";
import UserProfileTaggedPosts from "./UserProfileTaggedPosts";
import "../../styles/loader.css";
import { setUser } from "../../redux/reducers/userSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const Profile = () => {
  const { user } = useSelector((state) => state.root);
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("user"));
  const [state, setState] = useState({
    selectedTabType: "posts", // posts, media,reviews, tags
    userData: null,
    loading: true,
  });
  let userID = location?.state?.userID || user?._id;

  useEffect(() => {
    GetSingleUserProfile((dta) => {
      if (dta) {
        setState((prev) => ({ ...prev, userData: dta, loading: false }));
        if (localUser._id === userID) {
          dispatch(setUser(dta));
        }
      } else {
        setState((prev) => ({ ...prev, loading: false }));
      }
    }, userID);
  }, [userID]);

  return (
    <div>
      {state.loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <div className={styles.wrapper}>
          <ProfileHeader userData={state?.userData} t={t} />
          <ProfileInfo userData={state?.userData} t={t} />

          {localUser?._id == state?.userData?._id ? (
            <>
              <ProfileTabs
                onTabChange={(type, callback) => {
                  setState((prev) => ({ ...prev, selectedTabType: type }));
                  callback(type);
                }}
              />
              {state.selectedTabType === "posts" ? (
                <UserProfilePosts userID={userID} />
              ) : state.selectedTabType === "media" ? (
                <UserProfileMedia userID={userID} />
              ) : state.selectedTabType === "reviews" ? (
                <UserProfileReviews userID={userID} />
              ) : (
                <UserProfileTaggedPosts userID={userID} />
              )}
            </>
          ) : localUser?._id != state?.userData?._id &&
            state?.userData?.isPrivate &&
            state?.userData?.isFollowing ? (
            <>
              {console.log(state)}
              <ProfileTabs
                onTabChange={(type, callback) => {
                  setState((prev) => ({ ...prev, selectedTabType: type }));
                  callback(type);
                }}
              />
              {state.selectedTabType === "posts" ? (
                <UserProfilePosts userID={userID} />
              ) : state.selectedTabType === "media" ? (
                <UserProfileMedia userID={userID} />
              ) : state.selectedTabType === "reviews" ? (
                <UserProfileReviews userID={userID} />
              ) : (
                <UserProfileTaggedPosts userID={userID} />
              )}
            </>
          ) : localUser?._id != state?.userData?._id &&
            !state?.userData?.isPrivate ? (
            <>
              <ProfileTabs
                onTabChange={(type, callback) => {
                  setState((prev) => ({ ...prev, selectedTabType: type }));
                  callback(type);
                }}
              />
              {state.selectedTabType === "posts" ? (
                <UserProfilePosts userID={userID} />
              ) : state.selectedTabType === "media" ? (
                <UserProfileMedia userID={userID} />
              ) : state.selectedTabType === "reviews" ? (
                <UserProfileReviews userID={userID} />
              ) : (
                <UserProfileTaggedPosts userID={userID} />
              )}
            </>
          ) : (
            "Account is Private"
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
