import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./Background.module.scss";
import AddIcon from "@material-ui/icons/Add";
import ProfilePicDisplay from "./ProfilePicDisplay";
import { GetMyAssets } from "../../../../../services/customizationService";
import { ASSET_TYPES } from "../../../../../utils/AppConstants";
import { useHistory } from "react-router-dom";
import "../../../../../styles/loader.css";
import ApplyBackground from "./ApplyBackground";
import { SetAssetAsDefault } from "../../../../../services/customizationService";

const Background = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [background, setBackground] = useState("");
  const [picData, setPicdata] = useState("");
  const [openDialog, setOpendialog] = useState(false);
  useEffect(() => {
    setLoading(true);
    GetMyAssets((res) => {
      setBackground(res);
      setLoading(false);
    }, ASSET_TYPES.BACKGROUND);
  }, []);
  const handleModalData = (picData) => {
    setPicdata(picData);
    setOpendialog(!openDialog);
  };
  const handleSubmit = (picData) => {
    setLoading(true);
    SetAssetAsDefault(
      (res) => {
        if (res) {
          //dispatch(setUser(res));
          setLoading(false);
          setOpendialog(!openDialog);
          history.push("/profile");
        }
      },
      ASSET_TYPES.BACKGROUND,
      picData._id
    );
  };
  const handleClose = () => {
    setOpendialog(!openDialog);
  };
  return (
    <div>
      {loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <Grid container spacing={1}>
          {background?.length > 0
            ? background?.map((item, index) => (
                <ProfilePicDisplay
                  source={item}
                  handleModalData={handleModalData}
                  key={index}
                />
              ))
            : null}
          <Grid item xs={3}>
            <div
              className={styles.addBackground}
              onClick={() => {
                history.push({
                  pathname: "omega-store",
                  state: { tab: 0 },
                });
              }}
            >
              <div>
                <AddIcon className={styles.addIcon} />
              </div>
            </div>
          </Grid>
          {openDialog ? (
            <ApplyBackground
              open={openDialog}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              data={picData}
              title="Background"
            />
          ) : null}
        </Grid>
      )}
    </div>
  );
};
export default Background;
