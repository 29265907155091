import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #7C7C7C",
    backgroundColor: "#262626",
    colr: "#7C7C7C",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFFFFF",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#7C7C7C",
      },
    },
    color: "#7C7C7C",
  },
}))(MenuItem);

export { StyledMenu, StyledMenuItem };
