import React, { useState } from "react";
import styles from "./SignUp.module.scss";
import logo from "../../assets/images/favicons.ico";
import { Link } from "react-router-dom";
import { ValidateEmail, ValidatePassword } from "../../utils/AppValidators";
import { SignUpUser } from "../../services/authService";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ShowError from "../error/ShowError";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const SignUp = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: false,
    passwordVisibleA: true,
    passwordVisibleB: true,
    keyboardVisible: false,
    passwordError: "",
    email: "",
    password: "",
    retypedPassword: "",
    username: "",
    verifyEmail: false,
    emailCode: "",
    inviteCode: "",
    showError: false,
    showWarning: false,
    dateOfBirth: new Date("2015-08-18T21:11:54"),
    location: {
      addressName: "",
      country: "",
      streetAddress: "",
      location: {
        type: "Point",
        coordinates: [74.277066, 31.47069],
      },
    },
    age: "",
  });

  const changeHandler = (e) => {
    var reWhiteSpace = new RegExp("\\s+");
    console.log(reWhiteSpace.test(e?.target?.value));

    setState((prev) => ({
      ...prev,
      [e?.target?.name]: e?.target?.value?.trim(),
    }));
  };
  const handleAge = (date) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age > 14) {
      setState((prev) => ({ ...prev, dateOfBirth: date, age: age }));
    } else {
      setState((prev) => ({
        ...prev,
        age: age,
        loading: false,
        showError: true,
        passwordError: "Age must be greater than 14",
      }));
    }
  };
  const handleClose = () => {
    setState({
      ...state,
      verifyEmail: false,
    });
  };
  const handleError = () => {
    setState({
      ...state,
      showError: false,
      showWarning: false,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.username) {
      var reWhiteSpace = new RegExp("\\s+");
      // console.log(reWhiteSpace.test(e?.target?.value));
      if (reWhiteSpace.test(state.username)) {
        setState((prev) => ({
          ...prev,
          passwordError: "White Spaces Not Allowed",
          showError: true,
          loading: false,
        }));
      } else {
        if (state.age > 14) {
          if (ValidateEmail(state.email)) {
            if (state.password && state.password.length > 7) {
              if (state.password === state.retypedPassword) {
                if (ValidatePassword(state.password)) {
                  setState((prev) => ({
                    ...prev,
                    loading: true,
                    passwordError: "",
                  }));
                  if (state.inviteCode) {
                    SignUpUser(
                      (res) => {
                        if (res?.success) {
                          setState({
                            ...state,
                            loading: false,
                            showWarning: true,
                            passwordError:
                              "Verification Email has been sent! Please Verify!",
                          });
                          setTimeout(() => {
                            setState({
                              ...state,
                              verifyEmail: true,
                              loading: false,
                            });
                          }, 2000);
                        } else if (!res?.success) {
                          setState({
                            ...state,
                            loading: false,
                            showError: true,
                            passwordError: res?.message?.message
                              ? res?.message?.message
                              : "Username or email already exists!",
                          });
                        } else {
                          setState({
                            ...state,
                            loading: false,
                            showError: true,
                            passwordError: "Internal Server Error!",
                          });
                        }
                      },
                      {
                        userName: state.username.toLowerCase().trim(),
                        password: state.password.trim(),
                        email: state.email.toLowerCase().trim(),
                        code: state.inviteCode,
                        dateOfBirth: state.dateOfBirth,
                        location: JSON.stringify(state.location),
                      }
                    );
                  } else {
                    setState((prev) => ({
                      ...prev,
                      passwordError: "Invitation Code is required",
                      showError: true,
                      loading: false,
                    }));
                  }
                } else
                  setState((prev) => ({
                    ...prev,
                    passwordError:
                      "Password should contain [special characters, Upper and Lower case Alphabets and numbers]",
                    showError: true,
                    loading: false,
                  }));
              } else {
                setState((prev) => ({
                  ...prev,
                  passwordError: "Password does not match",
                  showError: true,
                  loading: false,
                }));
              }
            } else {
              setState((prev) => ({
                ...prev,
                passwordError: "Password must b 8 or more characters long",
                showError: true,
                loading: false,
              }));
            }
          } else {
            setState((prev) => ({
              ...prev,
              passwordError: "Please provide valid email address",
              showError: true,
              loading: false,
            }));
          }
        } else {
          setState({
            ...state,
            loading: false,
            showError: true,
            passwordError: "Age must be greater than 14",
          });
        }
      }
    } else {
      setState((prev) => ({
        ...prev,
        passwordError: "Please provide username",
        showError: true,
        loading: false,
      }));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={logo} style={{ height: "69px" }} alt="logo" />
        </Link>
      </div>
      {state.verifyEmail ? (
        <VerifyEmail email={state.email} handleClose={handleClose} />
      ) : (
        <div className={styles.card}>
          <div className={styles.main}>
            <form onSubmit={onSubmit}>
              <input
                onChange={changeHandler}
                type="text"
                name={"username"}
                placeholder={t("auth.username")}
              />
              <input
                onChange={changeHandler}
                type="email"
                name={"email"}
                placeholder={t("auth.email")}
              />
              <input
                onChange={changeHandler}
                type="password"
                name={"password"}
                placeholder={t("auth.password")}
              />
              <input
                onChange={changeHandler}
                type="password"
                name={"retypedPassword"}
                placeholder={t("auth.confirmPassword")}
              />
              {state.showError ? (
                <ShowError
                  open={state.showError}
                  handleClose={handleError}
                  severity="error"
                  message={state.passwordError}
                />
              ) : null}
              {state.showWarning ? (
                <ShowError
                  open={state.showWarning}
                  handleClose={handleError}
                  severity="info"
                  message={state.passwordError}
                />
              ) : null}
              <input
                onChange={changeHandler}
                type="text"
                name={"inviteCode"}
                placeholder={t("auth.invite_code")}
              />
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ width: "100%" }}
                    margin="normal"
                    id="date-picker-dialog"
                    format="MM/dd/yyyy"
                    value={state.dateOfBirth}
                    inputProps={{ style: { color: "#808080" } }}
                    InputLabelProps={{
                      style: { color: "#808080" },
                    }}
                    keyboardIcon={<DateRangeIcon color="primary" />}
                    onChange={(date) => {
                      handleAge(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>

              {state.showError ? (
                <ShowError
                  open={state.showError}
                  handleClose={handleError}
                  severity="error"
                  message={state.passwordError}
                />
              ) : null}
              {state.showWarning ? (
                <ShowError
                  open={state.showWarning}
                  handleClose={handleError}
                  severity="info"
                  message={state.passwordError}
                />
              ) : null}
              <div className={styles.button}>
                <button>
                  {state.loading ? (
                    <CircularProgress size={30} />
                  ) : (
                    t("auth.createAccount")
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className={styles.middle}>
            <div className={styles.text}>
              <Link to="/terms-of-use">
                <span>{t("auth.policy1")}</span>
              </Link>{" "}
              and{" "}
              <Link to="/data-policy">
                <span>{t("auth.policy2")}</span>
              </Link>
              .
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.topText}>{t("auth.alreadyAccount")}</div>
            <Link to="/sign-in">
              <div className={styles.bottomText}>{t("auth.login")}</div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
