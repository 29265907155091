import React from "react";
import UserToFollow from "./UserToFollow/UserToFollow";

const Users = ({ data, followUnfollowUser, t }) => {
  return (
    <React.Fragment>
      {data.length > 0
        ? data?.map((item, index) => {
            return (
              <UserToFollow
                key={item?._id}
                userData={item}
                followUnfollowUser={(type) => {
                  if (followUnfollowUser) followUnfollowUser(type, index);
                }}
                t={t}
              />
            );
          })
        : t("dashboard.noData")}
    </React.Fragment>
  );
};

export default Users;
