import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPostsOfSpecificUser } from "../../services";
import PostListing from "../common/Posts/PostListing";
import styles from "../../styles/pages.module.scss";
import { setUserProfileData } from "../../redux/reducers/userProfileDataSlice";
let cursorArr = [];
const UserProfilePosts = ({ userID }) => {
  let { userProfileData } = useSelector((state) => state.root);
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetPostsOfSpecificUser((userPosts) => {
      if (userPosts) dispatch(setUserProfileData({ posts: userPosts }));
      setLoading(false);
    }, userID);
  }, [userID]);

  return (
    <div>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={styles.wrapper}>
          <PostListing data={userProfileData.posts} />
        </div>
      )}
    </div>
  );
};

export default UserProfilePosts;
