import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GetAllTrendingUsers } from "../../../../../../services";
import { GET_FRIEND_LIST_TYPES } from "../../../../../../utils/AppConstants";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItemText,
} from "@material-ui/core";
import SkeleLoader from "./skeleLoader";
const useStyles = makeStyles({
  root: {
    minWidth: 55,
    width: "20vw",
    height: 275,

    overflowY: "auto",
    position: "absolute",
    top: 2,
    zIndex: 1,
    boxShadow: "0.5px 1px 7px #463c3cbf",
    background: "black",
    color: "gray",
  },
  line: {
    background: "#463c3cbf !important",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 0,
  },
  inline: {
    display: "inline",
  },
});

export default function ListCard({ value, dumVal, setCard, listTag, setTag }) {
  const classes = useStyles();
  // let Searchstring = value?.comment.slice(1);
  let stringSPlit = value?.comment.split("@");
  let Searchstring = stringSPlit[stringSPlit.length - 1];
  console.log(stringSPlit[stringSPlit.length - 1]);
  const [state, setstate] = React.useState({
    friendList: [],
    tagedList: [],
    bool: true,
  });
  const limit = 10;
  useEffect(() => {
    getfriendshelper(false, Searchstring);
  }, [Searchstring]);
  function getfriendshelper(cursor, searchQuery) {
    GetAllTrendingUsers(
      (response) => {
        console.log(response);
        if (response && response?.data.length > 0) {
          setstate({
            ...state,
            friendList: response?.data,
          });
        }
      },
      cursor ? cursor : "",
      searchQuery ? "search=" + searchQuery : "",
      limit ? "&limit=" + limit : ""
    );
  }
  let Filter = state?.friendList.filter((i) =>
    Searchstring !== ""
      ? i.userName.toLowerCase().includes(Searchstring.toLowerCase())
      : i
  );

  return (
    <Card className={classes.root}>
      <List>
        {state && state?.friendList.length > 0 ? (
          Filter.map((res, inx) => {
            return (
              <>
                <ListItem
                  alignItems="flex-start"
                  onClick={() => {
                    setTag(res);
                    setCard(false);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt={res?.userName.toUpperCase()} src={res?.pic} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${res?.userName
                      .charAt(0)
                      .toUpperCase()}${res?.userName?.slice(1)}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        ></Typography>
                        {res?.userName}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider className={classes.line} />
              </>
            );
          })
        ) : (
          <>
            <SkeleLoader />
            <SkeleLoader />
            <SkeleLoader />
          </>
        )}
      </List>
    </Card>
  );
}
