import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TableComp({ rows }) {
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {rows.map((row, indx) => (
              <TableRow key={indx}>
                <TableCell
                  component="th"
                  style={{ color: "white" }}
                  scope="row"
                >
                  {row.type}
                </TableCell>
                <TableCell style={{ color: "white" }} align="right">
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
