import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
const ApplyBackground = ({ open, handleClose, handleSubmit, data, title }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("dashboard.osbtToast4")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("setting.cancel")}
          </Button>
          <Button
            onClick={() => {
              handleSubmit(data);
            }}
            color="primary"
            autoFocus
          >
            {t("dashboard.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ApplyBackground;
