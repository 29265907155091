import React from "react";
import "./contenttop.css";

export default function ContentTopBar(props) {
  const { title, leftItems, rightItems } = props;
  return (
    <div className="content-toolbar">
      <div className="content-left-items"></div>
      <h1 className="content-toolbar-title">{title}</h1>
      <div className="content-right-items">{rightItems}</div>
    </div>
  );
}
