import React, { useState } from "react";
import styles from "./ReviewItem.module.scss";
import arrow from "../../../assets/images/arrow-right.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";
import GameAvatar from "../../common/GameAvatar/GameAvatar";
import moment from "moment";
const ReviewItem = ({ game, onClick, type, t }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.item}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        {console.log("games", game, type)}
        <div>
          {type === "profile" ? (
            <GameAvatar source={game?.gameImage} size={"60px"} rounded />
          ) : (
            <GameAvatar source={game?.profile?.url} size={"60px"} rounded />
          )}
        </div>
        <div className={styles.itemInfo}>
          {type === "profile" ? (
            <div className={styles.name}>{game.gameName}</div>
          ) : (
            <div className={styles.name}>{game.name}</div>
          )}
          <div className={styles.type}>
            {game.supportedDevices?.map((ii) => (ii + ", ").toUpperCase()) ||
              game.devices?.map((ii) => (ii + ", ").toUpperCase())}
          </div>
          <div className={styles.date}>
            {t("review.releaseDate")}:{" "}
            {moment(game.releaseDate).format("DD MMMM YYYY")}
          </div>
          <div>
            {type === "profile" ? (
              <div className={styles.feedback}>{game.feedback}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.review}>
        <div
          className={` ${styles.points} ${
            game?.computed != undefined &&
            (game?.computed[0]?.value || game?.ratings) /
              (game?.computed[1]?.value || 1) >
              5
              ? styles.point_success
              : styles.point_danger
          }`}
        >
          {game?.computed != undefined
            ? (
                (game?.computed[0]?.value || game.ratings || 0) /
                (game?.computed[1]?.value || 1)
              )?.toFixed(2)
            : null}
        </div>
        <div className={styles.icon}>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
