import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { store } from "../../redux/store";
const isItalian = store.getState().root?.isItalian;
const italian = localStorage.getItem("italian");
let lang = italian ? "it" : "en";
console.log("language", lang);
i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: lang,
  resources: {
    en: require("../../assets/langs/en.json"),
    it: require("../../assets/langs/it.json"),
  },
});
export default i18n;
