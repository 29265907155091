import React from "react";
import PollCard from "./PollCard/PollCard";
import PostCard from "./PostCard/PostCard";

const PostListing = ({ data }) => {
  return (
    <React.Fragment>
      {data?.length > 0
        ? data?.map((item, index) => {
            if (item.postType === "pool")
              return <PollCard key={item?._id} postData={item} />;
            else return <PostCard key={item?._id} postData={item} />;
          })
        : "No Result Found"}
    </React.Fragment>
  );
};

export default PostListing;
