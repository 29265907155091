import { createSlice } from "@reduxjs/toolkit";

const initState = 0;
export const countSlice = createSlice({
  name: "count",
  initialState: initState,
  reducers: {
    setCount: (state, action) => action.payload,

    resetCount: () => initState,
  },
});

export const { setCount, resetCount } = countSlice.actions;

export default countSlice.reducer;
