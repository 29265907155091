import { JSONBodyHelper } from "..";
import { AppConfig } from "../../config";
import { EndPoints } from "../../utils/AppEndpoints";
import { AppLogger } from "../../utils/AppHelperMethods";
import Interceptor from "../../utils/Interceptor";
const LIMIT = 100;

const AppShowPushNotification = (title, body, onPress) => {
  if (global.popupRef) {
    global.popupRef.show({
      onPress: onPress ? onPress : () => {},
      // appIconSource: require('./assets/icon.jpg'),
      appTitle: AppConfig.appName,
      timeText: "Now",
      title,
      body,
      slideOutTime: 3000,
    });
  }
};

const GetNotificationHistory = (callback, cursor) => {
  fetch(
    `${EndPoints.GET_NOTIFICATION_HISTORY}?limit=${LIMIT}${
      cursor ? "&cursor=" + cursor : ""
    }`,
    {
      method: "GET",
      headers: Interceptor.getHeaders(),
    }
  )
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      AppLogger(
        "-----------NOTIFICATION HISTORY RESPONSE-----------",
        JSON.stringify(data)
      );
      if (status === 201 || status === 200) {
        callback(data?.data?.data || []);
      } else callback(false);
    })
    .catch((error) => {
      AppLogger("---------NOTIFICATION HISTORY ERROR-----------", error);
      callback(false);
    });
};

const ReadUpdateNotificationStatus = (callback, notificationID) => {
  fetch(`${EndPoints.NOTIFICATION_STATUS_SET}${notificationID}`, {
    method: "POST",
    headers: Interceptor.getHeaders(),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      if (status === 201 || status === 200) {
        callback(true);
      } else {
        callback(false);
      }
    })
    .catch((error) => {
      callback(false);
    });
};
const GetNotificationCounter = (callback) => {
  fetch(EndPoints.NOTIFICATION_COUNTER, {
    method: "GET",
    headers: Interceptor.getHeaders(),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      if (status === 201 || status === 200) {
        callback(data);
      } else {
        callback(false);
      }
    })
    .catch((error) => {
      callback(false);
    });
};

export {
  AppShowPushNotification,
  GetNotificationHistory,
  ReadUpdateNotificationStatus,
  GetNotificationCounter,
};
