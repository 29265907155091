import React, { useState } from "react";
import { Link } from "react-router-dom";
import facebook from "../../assets/images/facebook.svg";
import google from "../../assets/images/google.svg";
import instagram from "../../assets/images/instagram.svg";
import logo from "../../assets/images/favicons.ico";
import twitter from "../../assets/images/twitter.svg";
import { LogInUser } from "../../services/authService";
import { AppShowToast } from "../../utils/AppHelperMethods";
import { ValidateEmail } from "../../utils/AppValidators";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import styles from "./SignIn.module.scss";
import SnackBar from "../Omegastore/SnackBar";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { storeData } from "../../utils/AppStorage";
import { removeItem } from "../../utils/AppStorage";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { t } = useTranslation();
  let location = useLocation();
  let getItem = JSON.parse(localStorage.getItem("rememberMe"));
  let history = useHistory();

  const [state, setState] = useState({
    email: getItem?.userName.toLowerCase().trim() || "",
    password: getItem?.password || "",
    loading: false,
    codeVerify: false,
    showSnackBar: location?.state?.severity || false,
    severity: location?.state?.severity || "",
    message: location?.state?.message || "",
    rememberMe: getItem?.userName ? true : false,
  });
  const handleClose = () => {
    setState({
      ...state,
      codeVerify: false,
      showSnackBar: true,
      message: "Your account is verified! Please login",
      severity: "success",
    });
  };
  const handleCloseSnack = () => {
    setState({ ...state, showSnackBar: false });
  };
  const onsubmit = (e) => {
    e.preventDefault();
    if (state.email) {
      if (state.password) {
        setState((prev) => ({ ...prev, loading: true }));
        LogInUser(
          (dta) => {
            setState((prev) => ({ ...prev, loading: false }));
            if (dta) {
              if (dta?.message?.message === "Verify email") {
                setState({ ...state, codeVerify: true });
              } else if (dta.res) {
                history.push("/");
              } else {
                setState({
                  ...state,
                  showSnackBar: true,
                  severity: "error",
                  message: "Please enter valid username/email and password",
                });
              }
              if (state.rememberMe) {
                storeData("rememberMe", {
                  userName: state.email.trim(),
                  password: state.password.trim(),
                });
              } else {
                removeItem("rememberMe");
              }
            } else {
              setState({
                ...state,
                showSnackBar: true,
                severity: "error",
                message: "Internal server error",
              });
            }
          },
          {
            userName: ValidateEmail(state.email)
              ? state.email.toLowerCase().trim()
              : state.email.trim(),
            password: state.password.trim(),
          }
        );
      } else AppShowToast("Provide password");
    } else AppShowToast("Please provide valid email address");
  };

  const handleChange = (e) => {
    setState((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
  };
  return (
    <div>
      {state.codeVerify ? (
        <VerifyEmail email={state.email} handleClose={handleClose} />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logo} alt="logo" style={{ height: "69px" }} />
            </Link>
          </div>
          <div className={styles.card}>
            <div className={styles.main}>
              <form onSubmit={onsubmit}>
                <input
                  onChange={handleChange}
                  name={"email"}
                  type="text"
                  value={state.email}
                  placeholder={t("auth.login")}
                />
                <input
                  onChange={handleChange}
                  name={"password"}
                  value={state.password}
                  type="password"
                  placeholder={t("auth.password")}
                />
                <div className={styles.row}>
                  <div className={styles.remember}>
                    <input
                      type="radio"
                      id="test1"
                      name="radio-group"
                      checked={state.rememberMe}
                      onChange={() => {}}
                      onClick={(e) => {
                        setState({ ...state, rememberMe: !state.rememberMe });
                      }}
                    />
                    <label htmlFor="test1">{t("auth.rememberMe")}</label>
                  </div>
                  <div className={styles.forget}>
                    <Link to="/forget-password">
                      {t("auth.forgotPassword")}
                    </Link>
                  </div>
                </div>
                <div className={styles.button}>
                  <button>
                    {state.loading ? (
                      <CircularProgress size={30} />
                    ) : (
                      t("auth.start")
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div className={styles.middle}>
              <div className={styles.socialText}>{t("auth.startWith")}:</div>
              <div className={styles.socialIcons}>
                <div className={styles.icon}>
                  <img src={facebook} alt="facebook" />
                </div>
                <div className={styles.icon}>
                  <img src={twitter} alt="twitter" />
                </div>
                <div className={styles.icon}>
                  <img src={instagram} alt="instagram" />
                </div>
                <div className={styles.icon}>
                  <img src={google} alt="google" />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.topText}>{t("auth.dontAccount")}</div>
              <Link to="/sign-up">
                <div className={styles.bottomText}>{t("auth.signup")}</div>
              </Link>
            </div>
          </div>
          {state.showSnackBar ? (
            <SnackBar
              open={state.showSnackBar}
              handleClose={handleCloseSnack}
              message={state.message}
              severity={state.severity}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SignIn;
