import styles from "./Background.module.scss";
import Grid from "@material-ui/core/Grid";

const ProfilePicDisplay = ({ source, handleModalData }) => {
  return (
    <Grid item xs={3}>
      <div
        className={styles.profilePic}
        onClick={() => {
          handleModalData(source);
        }}
      >
        <div
          className={styles.pic}
          style={{ backgroundImage: `url(${source.attachment.url})` }}
        >
          {/* <img src={source.attachment.url} alt="Profile picture" /> */}
        </div>
      </div>
    </Grid>
  );
};
export default ProfilePicDisplay;
