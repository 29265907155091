import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ActionsOnUsers, GetAllTrendingUsers } from "../../services";
import { FRIEND_STATUSES_ACTIONS } from "../../utils/AppConstants";
import { RemoveDuplicateObjectsFromArray } from "../../utils/AppHelperMethods";
import { getData } from "../../utils/AppStorage";
import Users from "../common/Users/Users";
import "../../styles/loader.css";
import { ToastContainer, toast } from "react-toastify";
import EmptyChatComponent from "../Chat/EmptyChatComponent";

let enableReset = true;
let cursorArrUsers = [];

const ExploreUsers = ({ t }) => {
  const { query } = useSelector((state) => state.root);
  console.log("query", query);
  const [state, setState] = useState({
    loading: true,
    loadingAllUsers: true,
    refreshingAllUsers: false,
    usersList: [],
  });

  function followUnfollowUser(item, index) {
    let tempUserObj = { ...item };

    console.log(tempUserObj);

    if (!tempUserObj?.isRequested) {
      ActionsOnUsers(
        (actionsRes) => {
          let tempData = state.usersList;
          console.log(actionsRes);
          let tempindex = tempData.findIndex((i) => i._id === tempUserObj?._id);
          if (tempindex > -1) {
            if (actionsRes?.data?.status === "REQUESTED") {
              tempUserObj.isRequested = true;
            } else if (actionsRes?.data?.status === "UN_FOLLOW") {
              tempUserObj.isFollowing = false;
              tempUserObj.isRequested = false;
            } else {
              tempUserObj.isFollowing = true;
              tempUserObj.isRequested = false;
            }

            tempData[tempindex] = tempUserObj;
            console.log("temp data", tempData);
            console.log(actionsRes?.data?.status);
          }
          setState((prev) => ({ ...prev, usersList: tempData }));
        },
        item?._id,
        tempUserObj?.isFollowing
          ? FRIEND_STATUSES_ACTIONS.UNFOLLOW
          : FRIEND_STATUSES_ACTIONS.FOLLOW
      );
    }
  }

  function getalltrendingusers(cursor, searchQuery) {
    let newQuery = searchQuery.split("&");
    let newQuery2 = newQuery.find((ii) => ii.includes("search")) || "";

    if (!cursor) cursorArrUsers = [];

    if (!cursorArrUsers.includes(cursor)) {
      GetAllTrendingUsers(
        (postResponse) => {
          if (postResponse.data && (postResponse.data.length || enableReset)) {
            let tempData = postResponse.data;
            setState((prev) => ({
              ...prev,
              loadingAllUsers: false,
              loading: false,
              refreshingAllUsers: false,
              usersList: RemoveDuplicateObjectsFromArray(tempData),
            }));
          } else {
            setState((prev) => ({
              ...prev,
              loadingAllUsers: false,
              loading: false,
              refreshingAllUsers: false,
            }));
            toast.error(postResponse.message.message || postResponse.message);
          }
        },
        cursor,
        `${newQuery2}`
      );
    } else {
      setState((prev) => ({ ...prev, refreshingAllUsers: false }));
    }
  }

  useEffect(() => {
    enableReset = true;
    cursorArrUsers = [];
    getData("search", (searchvalue) => {
      if (searchvalue) {
        var query1 = searchvalue ? "&search=" + searchvalue : "";
        getalltrendingusers(false, query1);
      } else {
        getalltrendingusers(false, query);
      }
    });
  }, [query]);

  return (
    <React.Fragment>
      {state.loading ? (
        <div className="loader"></div>
      ) : (
        <>
          {/* .filter((i) =>
                query !== "" ? i.userName.includes(query) : i.userName
              ) */}
          {state.usersList.length > 0 ? (
            <Users
              data={state.usersList}
              followUnfollowUser={(type, index) => {
                let item = state.usersList[index];
                if (type === "follow") {
                  followUnfollowUser(item, index);
                } else if (type === "requested") {
                  let tempUserObj = { ...item };
                  ActionsOnUsers(
                    () => {},
                    item?._id,
                    FRIEND_STATUSES_ACTIONS.CANCEL_FOLLOW_REQUEST
                  );
                  tempUserObj["isRequested"] = false;
                  tempUserObj["isFollowing"] = false;

                  let tempData = state.usersList;
                  tempData[index] = tempUserObj;
                  setState((prev) => ({ ...prev, usersList: tempData }));
                } else if (type === "following") {
                  console.log("following", type);
                  let tempUserObj = { ...item };
                  ActionsOnUsers(
                    () => {},
                    item?._id,
                    FRIEND_STATUSES_ACTIONS.UNFOLLOW
                  );
                  tempUserObj["isRequested"] = false;
                  tempUserObj["isFollowing"] = false;

                  let tempData = state.usersList;
                  tempData[index] = tempUserObj;
                  setState((prev) => ({ ...prev, usersList: tempData }));
                  // followUnfollowUser(item, index);
                }
              }}
              t={t}
            />
          ) : (
            t("dashboard.noData")
          )}
          <ToastContainer />
        </>
      )}
    </React.Fragment>
  );
};

export default ExploreUsers;
