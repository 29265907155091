import React from "react";
import BoxCard from "./BoxCard/BoxCard";
import styles from "./BoxCard/Media.module.scss";

const PostGridListing = ({ data }) => {
  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={styles.row}>
          {data?.length > 0
            ? data.map((item, index) => {
                return (
                  <BoxCard
                    key={item?._id}
                    allData={data}
                    ind={index}
                    postData={item}
                  />
                );
              })
            : "No Result Found"}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostGridListing;
