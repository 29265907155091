import React, { useState } from "react";
import styles from "./SearchBar.module.scss";
import filter from "../../../assets/images/filter.svg";
import { ReactComponent as Reset } from "../../../assets/images/reset_undo.svg";
import { useDispatch, useSelector } from "react-redux";
import { setQuery } from "../../../redux/reducers/querySlice";
import { setComment } from "../../../redux/reducers/commentSlice";
import FilterComp from "./filter/filter";
import {
  getData,
  storeData,
  removeItemsFromLocalStorage,
} from "../../../utils/AppStorage";
import {
  resetExploreFeed,
  setExploreFeed,
} from "../../../redux/reducers/exploreFeedSlice";
import { react } from "@babel/types";

const SearchBar = ({ t }) => {
  const [open, setopen] = React.useState(false);
  const [searchVale, setSearchVal] = React.useState("");
  const [Localquery, setLocalQuery] = React.useState("");
  const [sort, setSort] = React.useState("");
  const [time, settime] = React.useState("");
  const [bool, setbool] = React.useState(true);
  const query = useSelector((state) => state.root.query);

  let dispatch = useDispatch();
  const localResp = () => {
    getData("search", (data) => {
      if (data) {
        setSearchVal(data);
        var resp = data ? `&search=${data}` : "";
        dispatch(setQuery(resp));
      } else {
      }
    });
    getData("sort", (data) => {
      console.log(data);
      if (data) {
        setSort(data);
      } else {
        setSort("");
      }
    });
    getData("time", (data) => {
      if (data) {
        settime(data);
      } else {
        settime("");
      }
    });
  };
  if (searchVale === "" && bool) {
    getData("search", (data) => {
      if (data) {
      } else {
        dispatch(setExploreFeed([]));
        dispatch(setQuery(""));
      }
    });
    setbool(false);
  }
  React.useEffect(() => {
    localResp();
  }, [query]);

  let storeAndSearchData = (value) => {
    var seachData = value;

    if (value) {
      seachData = value;
      storeData("search", value);
    } else {
      seachData = "";
      setbool(true);
      dispatch(setComment("resetComment"));
      removeItemsFromLocalStorage("search");
    }
    const data = seachData ? `&search=${seachData}` : "";
    // setLocalQuery(data);
    setSearchVal(value);

    dispatch(setQuery(data));
    dispatch(resetExploreFeed([]));
  };
  let SearchData = () => {
    dispatch(resetExploreFeed([]));
    dispatch(setQuery(Localquery));
  };

  const ResetFilter = () => {
    dispatch(resetExploreFeed([]));
    dispatch(setQuery(""));
    dispatch(setComment("resetComment"));
    removeItemsFromLocalStorage("sort");
    removeItemsFromLocalStorage("time");
    removeItemsFromLocalStorage("filter");
  };
  console.log(sort, time);
  return (
    <div className={styles.wrapper}>
      <div className={styles.searchInput} onClick={SearchData}>
        <input
          type="text"
          value={searchVale}
          placeholder={t("dashboard.search")}
          onChange={(e) => {
            storeAndSearchData(e.target.value, e);
            // setSearchVal(e.target.value)
          }}
        />
      </div>
      {sort != "" || time != "" ? (
        <Reset className={styles.reset} onClick={ResetFilter} />
      ) : (
        <div className={styles.filter}>
          <img src={filter} alt="filter" onClick={() => setopen(true)} />
          {open ? <FilterComp value={open} setval={setopen} /> : ""}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
