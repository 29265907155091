import React from "react";
import styles from "./HelpCenter.module.scss";
import SubHelpCenter from "./SubHelpCenter";
import { useTranslation } from "react-i18next";
const HelpCenter = () => {
  const { t } = useTranslation();
  let omeGame = {
    title: t("setting.question1"),
    body: t("setting.answer1"),
  };
  let postPicture = {
    title: t("setting.question2"),
    body: t("setting.answer2"),
  };
  let followSomeone = {
    title: t("setting.question3"),
    body: t("setting.answer3"),
  };
  let quest = {
    title: t("setting.question4"),
    body: t("setting.answer4"),
  };
  let levelup = {
    title: t("setting.question5"),
    body: t("setting.answer5"),
  };
  let seePost = {
    title: t("setting.question6"),
    body: t("setting.answer6"),
  };
  let rateGame = {
    title: t("setting.question7"),
    body: t("setting.answer7"),
  };
  let question = {
    title: t("setting.question8"),
    body: t("setting.answer8"),
  };

  return (
    <div className={styles.parent}>
      <h4 className={styles.body}>USING OMEGAME</h4>
      <SubHelpCenter title={omeGame.title} body={omeGame.body} />
      <SubHelpCenter title={postPicture.title} body={postPicture.body} />
      <SubHelpCenter title={followSomeone.title} body={followSomeone.body} />
      <SubHelpCenter title={quest.title} body={quest.body} />
      <SubHelpCenter title={levelup.title} body={levelup.body} />
      <SubHelpCenter title={seePost.title} body={seePost.body} />
      <SubHelpCenter title={rateGame.title} body={rateGame.body} />

      <h4 className={styles.body}>PRIVACY & SAFETY</h4>
      <SubHelpCenter title={question.title} body={question.body} />
      <SubHelpCenter title={question.title} body={question.body} />
      <SubHelpCenter title={question.title} body={question.body} />
      <SubHelpCenter title={question.title} body={question.body} />

      <h4 className={styles.body}>NEW CATEGORY</h4>
      <SubHelpCenter title={question.title} body={question.body} />
      <SubHelpCenter title={question.title} body={question.body} />
      <SubHelpCenter title={question.title} body={question.body} />
      <SubHelpCenter title={question.title} body={question.body} />
    </div>
  );
};
export default HelpCenter;
