import styles from "./Background.module.scss";
import Grid from "@material-ui/core/Grid";
import coin from "../../../../../assets/images/coin.svg";

const DisplayPicture = ({ source, handleModalData }) => {
  return (
    <Grid item className="text-center">
      <div
        className={styles.fill}
        onClick={() => {
          handleModalData(source);
        }}
      >
        <div
          className={styles.pic}
          style={{ backgroundImage: `url(${source.attachment.url})` }}
        >
          {/* <img src={source.attachment.url} /> */}
        </div>
        <div style={{ textAlign: "center" }}>
          <p style={{ textAlign: "center" }}>Images</p>
          <img
            src={coin}
            alt="coin"
            style={{ height: "20px", width: "20px" }}
            alt="coin logo"
          />{" "}
          * {source.priceInCoins}
        </div>
      </div>
    </Grid>
  );
};
export default DisplayPicture;
