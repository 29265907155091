import { useState, useEffect } from "react";
import ColorScheme from "../../Profile/Customize/CustomizeTabs/Corner/ColorScheme";
import Grid from "@material-ui/core/Grid";
import CornerContainer from "./CornerContainer";
import { GetAllAssets } from "../../../services/customizationService";
import { ASSET_TYPES } from "../../../utils/AppConstants";
import "../../../styles/loader.css";
import BuyCorner from "./BuyCorner";
const Corners = ({ user }) => {
  const [corners, setCorners] = useState("");
  const [loading, setLoading] = useState(false);
  const [avatarData, setAvatarData] = useState(null);
  const [color, setColor] = useState("gray");
  const [openDialog, setOpenDialog] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  useEffect(() => {
    setLoading(true);
    GetAllAssets((res) => {
      setCorners(res);
      setLoading(false);
    }, ASSET_TYPES.CORNER);
  }, [reloadData]);
  const handleModalData = (picData) => {
    setAvatarData(picData);
    setOpenDialog(!openDialog);
  };
  const handleClose = () => {
    setOpenDialog(!openDialog);
    setReloadData(!reloadData);
  };
  const handleColor = (data) => {
    setColor(data);
  };
  return (
    <div>
      {loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <div style={{ marginLeft: "132px" }}>
          {/* <ColorScheme handleColor={handleColor} /> */}
          <Grid container>
            {corners?.length > 0
              ? corners?.map((item, index) => (
                  <CornerContainer
                    key={index}
                    data={item}
                    handleModalData={handleModalData}
                    user={user}
                    color={color}
                  />
                ))
              : null}
          </Grid>
          {openDialog ? (
            <BuyCorner
              open={openDialog}
              handleClose={handleClose}
              data={avatarData}
              user={user}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default Corners;
