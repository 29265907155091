import { useState, useEffect } from "react";
import ColorScheme from "../Corner/ColorScheme";
import AddIcon from "@material-ui/icons/Add";
import styles from "./Nickname.module.scss";
import { useHistory } from "react-router-dom";
import { GetMyAssets } from "../../../../../services/customizationService";
import { ASSET_TYPES } from "../../../../../utils/AppConstants";
import "../../../../../styles/loader.css";
import NicknameBox from "./NicknameBox";
import ApplyBackground from "../Background/ApplyBackground";
import { SetAssetAsDefault } from "../../../../../services/customizationService";

const Nickname = () => {
  let history = useHistory();
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [nickname, setNickname] = useState("");
  const [nicknameData, setNicknamedata] = useState("");
  const [openDialog, setOpendialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetMyAssets((res) => {
      setNickname(res);
      setLoading(false);
    }, ASSET_TYPES.NICKNAME);
  }, []);
  const handleColor = (getColor) => {
    setColor(getColor);
  };
  const handleModalData = (getData) => {
    setNicknamedata(getData);
    setOpendialog(!openDialog);
  };
  const handleClose = () => {
    setOpendialog(!openDialog);
  };
  const handleSubmit = (data) => {
    SetAssetAsDefault(
      (res) => {
        if (res) {
          //dispatch(setUser(res));
          setLoading(false);
          setOpendialog(!openDialog);
          history.push("/profile");
        }
      },
      ASSET_TYPES.NICKNAME,
      nicknameData._id,
      color
    );
    setOpendialog(!openDialog);
  };
  return (
    <div>
      {loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <div>
          {/* <ColorScheme handleColor={handleColor} /> */}
          <div className={styles.displayInline}>
            <div className={styles.displayInline}>
              {nickname?.length > 0
                ? nickname?.map((item, index) => (
                    <NicknameBox
                      source={item}
                      handleModalData={handleModalData}
                      color={color}
                      key={index}
                    />
                  ))
                : null}
            </div>
            <div>
              <span className={styles.blueCircle}>
                <span className={styles.whiteCircle}>
                  <AddIcon
                    className={styles.addIcon}
                    onClick={() => {
                      history.push({
                        pathname: "omega-store",
                        state: { tab: 2 },
                      });
                    }}
                  />
                </span>
              </span>
            </div>
          </div>
          {openDialog ? (
            <ApplyBackground
              open={openDialog}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              data={nicknameData}
              title="Nickname"
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default Nickname;
