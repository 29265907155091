import React from "react";
import styles from "./PollCard.module.scss";
import PostUserInfo from "../PostUserInfo/PostUserInfo";
import InteractionBar from "../InteractionBar/InteractionBar";

const PollCard = () => {
  return (
    <div className={styles.wrapper}>
      <PostUserInfo />
      <div className={styles.text}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
        nonummy?
      </div>
      <form>
        <div className={styles.poll}>
          <div className={styles.option}>
            <input type="radio" id="first" name="radio-group" />
            <label htmlFor="first">First Answer</label>
          </div>
          <div className={styles.option}>
            <input type="radio" id="second" name="radio-group" />
            <label htmlFor="second">Second Answer</label>
          </div>
          <div className={styles.option}>
            <input type="radio" id="third" name="radio-group" />
            <label htmlFor="third">Third Answer</label>
          </div>
          <div className={styles.option}>
            <input type="radio" id="fourth" name="radio-group" />
            <label htmlFor="fourth">Fourth Answer</label>
          </div>
          <div className={styles.option}>
            <input type="radio" id="fifth" name="radio-group" />
            <label htmlFor="fifth">Fifth Answer</label>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bWrapper}>
            <div className={styles.button}>
              <button>Vote</button>
            </div>
            <div className={styles.time}>TIME LEFT: 3d 4h 50m</div>
          </div>
        </div>
      </form>
      <InteractionBar />
    </div>
  );
};

export default PollCard;
