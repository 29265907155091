import React from "react";
import styles from "./ExploreBar.module.scss";
import { ReactComponent as Posts } from "../../../assets/images/posts.svg";
import { ReactComponent as Media } from "../../../assets/images/media.svg";
import { ReactComponent as Users } from "../../../assets/images/users.svg";
import SearchBar from "../../common/SearchBar/SearchBar";

const ExploreBar = ({ onChangeTab, t }) => {
  const [state, setState] = React.useState({
    selectTab: "posts",
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <div
          className={styles.tabItem}
          onClick={() => {
            if (onChangeTab)
              onChangeTab("posts", (type) => {
                setState({
                  ...state,
                  selectTab: type,
                });
              });
          }}
        >
          <Posts className={state.selectTab === "posts" ? styles.img : ""} />
        </div>
        <div
          className={styles.tabItem}
          onClick={() => {
            if (onChangeTab)
              onChangeTab("media", (type) => {
                setState({
                  ...state,
                  selectTab: type,
                });
              });
          }}
        >
          <Media
            className={state.selectTab === "media" ? styles.img : ""}
            t={t}
          />
        </div>
        <div
          className={styles.tabItem}
          onClick={() => {
            if (onChangeTab)
              onChangeTab("users", (type) => {
                setState({
                  ...state,
                  selectTab: type,
                });
              });
          }}
        >
          <Users className={state.selectTab === "users" ? styles.img : ""} />
        </div>
      </div>
      <SearchBar t={t} />
    </div>
  );
};

export default ExploreBar;
