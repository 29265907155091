import React from "react";
import defaultImage from "../../../assets/images/defaultpic.jpeg";
import styles from "./UserAvatar.module.scss";

const UserAvatar = ({ user, size, innerImageSize }) => {
  return (
    <div className={styles.parent}>
      {user?.corner ? (
        <div>
          <div className={styles.image1}>
            <img
              src={user?.corner || defaultImage}
              alt="profile image"
              style={{
                height: `${size || "120px"}`,
                width: `${size || "120px"}`,
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className={styles.image2}>
            <img
              src={user?.pic || defaultImage}
              alt="profile image"
              style={{
                height: `${innerImageSize || "110px"}`,
                width: `${innerImageSize || "110px"}`,
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.image3}>
          <img
            src={user?.pic || defaultImage}
            alt="profile image"
            style={{
              height: `${size || "120px"}`,
              width: `${size || "120px"}`,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
