import { AppLogger } from "./AppHelperMethods";

export default class Interceptor {
  static token = null;

  static headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  static getHeaders() {
    var user = JSON.parse(localStorage.getItem("user"));
    let temp = this.headers;
    temp["Content-Type"] = "application/json";
    temp["Authorization"] = "Bearer " + user?.token;

    return temp;
  }

  static getHeadersMultiPart() {
    let temp = this.headers;
    temp["Accept"] = "application/json";
    temp["Content-Type"] = "multipart/form-data";
    temp["Authorization"] = "Bearer " + this.token;
    return temp;
  }

  static setToken(token) {
    this.token = token;
  }
  static getToken() {
    return this.token;
  }
}
