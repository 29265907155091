import React from "react";
import {
  DialogTitle,
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  Slide,
  Grid,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function CreatePost() {
  const [redirect, setredirct] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setredirct(false);
    history.goBack();
  };
  const history = useHistory();
  if (history.location.pathname === "/post" && redirect) {
    setOpen(true);
    setredirct(false);
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("createPost.createPost")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>
                <div>
                  <div>
                    <div>
                      <Grid container spacing={2} alignItems="flex-end">
                        <Grid item>
                          <AccountCircle style={{ fontSize: "29px" }} />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="input-with-icon-grid"
                            style={{ width: "50ch" }}
                            label="What's new?"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              {t("createPost.disagree")}
            </Button>
            {/* <Button onClick={handleClose} color="danger" autoFocus>
                        Agree
          </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
