import { AppConfig } from '../config';

const MEDIA_OPTIONS = {
    width: 800,
    height: 800,
    cropping: true,
    multiple: false
}

function pickerResponseMaker(callback, response, type) {
   
}
const GenerateThumbnailFromVideo = (callback, videoPath) => {

}

const OpenGalleryPicker = (callback, type = 'video') => {

}

const OpenCameraPicker = (callback, type = 'video') => {

}

const OpenCameraGalleryPromptPicker = (callback, type) => {

}

const CleanCachedImages = (callback) => {

}

export { OpenCameraPicker, OpenGalleryPicker, OpenCameraGalleryPromptPicker, CleanCachedImages, GenerateThumbnailFromVideo };
