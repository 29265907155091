import React from "react";

const Timer = ({ seconds, handleSecond, handleShowTime }) => {
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => handleSecond(seconds - 1), 1000);
    } else {
      handleShowTime(false);
    }
  });
  return <div>{seconds}</div>;
};
export default Timer;
