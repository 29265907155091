import React, { useState } from "react";
import styles from "../../styles/pages.module.scss";

import ExploreBar from "./ExploreBar/ExploreBar";
import ExploreMedia from "./ExploreMedia";
import ExplorePosts from "./ExplorePosts";
import ExploreUsers from "./ExploreUsers";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
const Explore = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    selectedTabType: "posts", // type can be:-> simplePosts, mediaPosts, users
  });
  return (
    <div className={styles.wrapper}>
      <ExploreBar
        onChangeTab={(type, callback) => {
          setState((prev) => ({ ...prev, selectedTabType: type }));
          callback(type);
        }}
        t={t}
      />
      {state.selectedTabType === "posts" ? ( // simplePosts
        <ExplorePosts />
      ) : state.selectedTabType === "media" ? ( // mediaPosts
        <ExploreMedia />
      ) : (
        // users
        <ExploreUsers t={t} />
      )}
    </div>
  );
};

export default Explore;
