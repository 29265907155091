import privateIcon from "../../../../../assets/images/private1.svg";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "../Setting.module.scss";

const PrivateAccount = ({ isPrivate, handlePrivateAccount, t }) => {
  return (
    <div className={styles.notifContainer}>
      <div className={styles.notifInnerContainer}>
        <div className={styles.notifIconContainer}>
          <div className={styles.notifImageInnerContainer}>
            <img
              src={privateIcon}
              alt="PrivateIcon"
              className={styles.notifImageStyles}
            />
          </div>
        </div>
        <div className={styles.textContainer}>
          <p>{t("setting.privateAccount")}</p>
        </div>
      </div>
      <div className={styles.toggleButtonContainer}>
        <div className={styles.toggleButtonInnerContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={isPrivate}
                onChange={handlePrivateAccount}
                name="checkedPrivate"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
export default PrivateAccount;
