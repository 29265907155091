import React, { useEffect, useState } from "react";
import { Container, Button, TextField } from "@material-ui/core";
import UserAvatar from "../../UserAvatar/UserAvatar";
import styles from "./tag.module.scss";
import { GET_FRIEND_LIST_TYPES } from "../../../../utils/AppConstants";
import { GetAllTrendingUsers } from "../../../../services";
import { getData } from "../../../../utils/AppStorage";
import SkeleLoader from "../../Posts/comments/addComments/skeleLoader";
import { useTranslation } from "react-i18next";
export default function Friend({ value, setvalue, search }) {
  const { t } = useTranslation();
  const [UserSearch, setSearch] = useState(value.search);
  const toggleArray = (obj) => {
    var toggleStatus = [...value.friendList];
    var toggleTaged = [...value.tagedList];
    var toggleStatusIndex = toggleStatus.findIndex((i) => i._id === obj._id);
    var toggleTagIndex = toggleTaged.findIndex((i) => i._id === obj._id);
    if (toggleTagIndex > -1) {
      toggleTaged.splice(toggleTagIndex, 1);
      toggleStatus[toggleStatusIndex].check = false;
      setvalue({
        ...value,
        friendList: toggleStatus,
        tagedList: toggleTaged,
      });
    } else {
      toggleTaged = [...toggleTaged, obj];
      toggleStatus[toggleStatusIndex].check = true;
      setvalue({
        ...value,
        friendList: toggleStatus,
        tagedList: toggleTaged,
      });
    }
  };

  return (
    <>
      <Container style={{ width: "29vw ", height: "40vh" }}>
        <p className="notification_title" style={{ color: "black" }}>
          Friends
        </p>
        <TextField
          value={UserSearch}
          placeholder="Search Friend"
          onChange={(e) => {
            search(e.target.value);

            setSearch(e.target.value);
          }}
          className={styles.input}
        />

        <div className="notification_container" style={{ marginLeft: "0" }}>
          <div className="notifications_containerBottom">
            {value && value.friendList.length > 0 && value.isLoad ? (
              value.friendList.map((resp, indx) => {
                return (
                  <div
                    className="notification_followRequest"
                    style={{ alignItems: "start" }}
                    key={indx}
                  >
                    <div
                      className="notification_cardContainer"
                      style={{
                        borderBottom: "1px solid rgb(8 8 8 / 40%)",
                        width: "27.5vw",
                      }}
                    >
                      <UserAvatar source={resp.pic} size={"60px"} />
                      <p
                        className="notification_cardTitle"
                        style={{
                          marginLeft: "7px",
                          width: "inherit",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{resp.userName}</span>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            right: "100px",

                            bottom: "20px",
                          }}
                        >
                          {value.tagedList.length > 0 ? (
                            value.tagedList.map((data, indx) => {
                              return data._id === resp._id ? (
                                <Button
                                  key={indx}
                                  style={{
                                    position: "absolute",
                                    zIndex: "1",
                                  }}
                                  className={styles.tag_friend}
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => toggleArray(resp)}
                                >
                                  {t("dashboard.cancel")}
                                </Button>
                              ) : (
                                <Button
                                  key={indx}
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    position: "absolute",

                                    zIndex: "0",
                                  }}
                                  className={styles.tag_friend}
                                  onClick={() => toggleArray(resp)}
                                >
                                  {t("dashboard.addTag")}
                                </Button>
                              );
                            })
                          ) : (
                            <Button
                              color="primary"
                              className={styles.tag_friend}
                              style={{
                                position: "absolute",
                                zIndex: "0",
                              }}
                              variant="contained"
                              onClick={() => toggleArray(resp)}
                            >
                              {t("dashboard.addTag")}
                            </Button>
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                );
              })
            ) : value && value.isLoad ? (
              <p>No User Found</p>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  width: "28vw",
                }}
              >
                {/* <CircularProgress /> */}
                <SkeleLoader />
                <SkeleLoader />
                <SkeleLoader />
                <SkeleLoader />
                <SkeleLoader />
                <SkeleLoader />
                <SkeleLoader />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
