import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import shave from "shave";

import "./ConversationListItem.css";

export default function ConversationListItem(props) {
  const user = useSelector((state) => state.root.user);

  let otherUser =
    user._id === props.data.message.to._id
      ? props.data.message.from
      : props.data.message.to;

  useEffect(() => {
    shave(".conversation-snippet", 20);
  });

  const photo = otherUser.pic || "";
  const name = otherUser.userName || "";
  const text = props.data.message.text || "";

  return (
    <div className="conversation-list-item">
      <img className="conversation-photo" src={photo} alt="conversation" />
      <div className="conversation-info">
        <h1 className="conversation-title">{name}</h1>
        <p className="conversation-snippet">{text}</p>
      </div>
    </div>
  );
}
