import React, {
  useCallback,
  useMemo,
  useState,
  forwardRef,
  useEffect,
} from "react";
import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention";
import editorStyles from "./SimpleMentionEditor.module.css";
import mentionsStyles from "./MentionsStyles.module.css";
import { GetAllTrendingUsers } from "../../../../../../services";
import { MentionData } from "@draft-js-plugins/mention";
import {
  ContentState,
  Modifier,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  SelectionState,
} from "draft-js";
import { CancelOutlined } from "@material-ui/icons";

export default forwardRef(function CommentInput(
  { state, setstate, EditorState, editorState, setEditorState, ResetError },
  ref
) {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      mentionPrefix: "@",
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const limit = 5;
  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    getfriendshelper(false, value, function (resp) {
      setSuggestions(defaultSuggestionsFilter(value, suggestions));
    });
  }, []);

  function getfriendshelper(cursor, searchQuery) {
    GetAllTrendingUsers(
      (response) => {
        if (response && response?.data.length > 0) {
          let arr = [];
          response?.data.forEach((element) => {
            element["name"] = element?.userName;
            element["avatar"] = element?.pic;
            arr.push(element);
          });
          setSuggestions(arr);
        } else {
          setSuggestions([]);
        }
      },
      cursor ? cursor : "",
      searchQuery ? "search=" + searchQuery : "",
      limit ? "&limit=" + limit : ""
    );
  }
  return (
    <div
      className={editorStyles.editor}
      onInput={(e) => {
        setstate({
          ...state,
          comment: e.currentTarget.textContent,
        });
        ResetError();
      }}
      placeholder={"Type here..."}
    >
      <div
        style={{
          display: "inline-block",
          width: "90%",
          paddingBottom: `${state?.eidId ? "8px" : ""}`,
        }}
      >
        <Editor
          editorKey={"editor"}
          editorState={editorState}
          onChange={(e) => {
            setEditorState(e);
          }}
          plugins={plugins}
          handleBeforeInput={(e) => {
            console.log(e);
          }}
          autoComplete
          handleKeyCommand={(e) => {
            console.log(
              // convertToRaw(
              editorState.getCurrentContent().getPlainText("\u0001")
              // )
            );
            setstate({
              ...state,
              comment: editorState.getCurrentContent().getPlainText("\u0001"),
            });
          }}
          ref={ref}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          handleKeyCommand={(e) => {
            console.log(
              // convertToRaw(
              editorState.getCurrentContent().getPlainText("\u0001")
              // )
            );
            setstate({
              ...state,
              comment: editorState.getCurrentContent().getPlainText("\u0001"),
            });
          }}
          onAddMention={(e) => {
            let cloneState = [...state.mentionUser];
            cloneState.push(e);

            setstate({
              ...state,
              mentionUser: cloneState,
              comment: state.comment.concat(`@${e.userName}`),
            });
            // get the mention object selected
          }}
        />
      </div>
      {state?.eidId ? (
        <CancelOutlined
          onClick={() => {
            setstate({
              user: "",
              comment: "",
              currentFocus: null,
              mentionUser: [],
            });
            setEditorState(() =>
              EditorState.createWithContent(ContentState.createFromText(""))
            );
          }}
          style={{
            fontSize: "30px",
            color: "white",
            cursor: "pointer",
            boxSizing: "content-box",
            paddingTop: "12px",
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
});
