import { JSONBodyHelper } from ".";
import Interceptor from "../utils/Interceptor";
import { EndPoints } from "../utils/AppEndpoints";

function SendFeedback(obj, callback) {
  fetch(EndPoints.SEND_FEEDBACK, {
    method: "Post",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(obj),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      if (status == 201 || status == 200) {
        callback(data);
      } else {
        callback(false);
      }
    })
    .catch((e) => {
      callback(false);
    });
}
export { SendFeedback };
