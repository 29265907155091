import firebase from "firebase";
import { JSONBodyHelper } from ".";
import { resetFriends } from "../redux/reducers/friendsSlice";
import { resetGameReviews } from "../redux/reducers/gameReviewsSlice";
import { resetHomeFeed } from "../redux/reducers/homeFeedSlice";
import { resetInbox } from "../redux/reducers/inboxSlice";
import { resetMyAssets } from "../redux/reducers/myAssetsSlice";
import { resetNotifications } from "../redux/reducers/notificationsSlice";
import { resetQuests } from "../redux/reducers/questsSlice";
import { resetSavedPosts } from "../redux/reducers/savedPostsSlice";
import { resetUserProfileData } from "../redux/reducers/userProfileDataSlice";
import { resetUser, setUser } from "../redux/reducers/userSlice";
import { store } from "../redux/store";
import { EndPoints } from "../utils/AppEndpoints";
import {
  AppLogger,
  AppShowToast,
  CapitalizeFirstLetter,
} from "../utils/AppHelperMethods";
import {
  clearStorage,
  getData,
  removeItemsFromLocalStorage,
  storeData,
} from "../utils/AppStorage";
import Interceptor from "../utils/Interceptor";
var LogInUser = (callback, formData) => {
  fetch(EndPoints.LOGIN, {
    method: "POST",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(formData),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      AppLogger("----------LOGIN REPOSNE ------", JSON.stringify(data));
      if (status === 201 || status === 200) {
        Interceptor.setToken(data?.data?.access_token || "");
        let UserObj = {
          ...data.data.user,
          ...data?.data?.user?.profile,
          token: data.data.access_token,
          email: formData?.userName,
        };
        // if (UserObj?._id) {
        //     firebase.messaging().subscribeToTopic(UserObj?._id);
        // }
        storeData("user", UserObj)
          .then((res) => {
            store.dispatch(setUser(UserObj));
            callback({ res: true });
          })
          .catch((err) => {
            callback(false);
          });
      } else {
        callback({ res: false, message: data.message });
      }
    })
    .catch((error) => {
      callback(false);
    });
};

var SignUpUser = (callback, formedData) => {
  fetch(EndPoints.SIGNUP, {
    method: "POST",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(formedData),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      AppLogger("-----------SIGN UP RESPONSE----------", JSON.stringify(data));
      if (status === 201 || status === 200) {
        callback(data);
      } else {
        callback(data);
      }
    })
    .catch((error) => {
      AppLogger("---------SIGN UP DATA - ERROR-----------", error);
      callback(false);
    });
};

var ForgotPasswordCall = (callback, formedData) => {
  fetch(EndPoints.FORGOT_PASSWORD + "?email=" + formedData, {
    method: "GET",
    headers: Interceptor.getHeaders(),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      AppLogger(
        "---------------FORGOT PASS RES-------------",
        JSON.stringify(data)
      );
      if (status === 201 || status === 200) {
        callback(data);
      } else {
        callback(data);
      }
    })
    .catch((error) => {
      AppLogger("---------SIGN UP DATA - ERROR-----------", error);
      callback(false);
    });
};

var ChangeUserPassword = (callback, formedData) => {
  fetch(EndPoints.CHANGE_PASSWORD, {
    method: "PATCH",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(formedData),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      AppLogger("---------------CHANGE PASS RES-------------", data);
      if (status === 201 || status === 200) {
        callback(true);
      } else {
        AppShowToast(data?.message?.message || "Wrong password provided");
        callback(false);
      }
    })
    .catch((error) => {
      AppLogger("--------- CHANGE PASS - ERROR-----------", error);
      callback(false);
    });
};

var LogOutUser = async (callback) => {
  try {
    let unsubRes = await firebase
      .messaging()
      .unsubscribeFromTopic(store.getState()?.root?.user?._id);
  } catch (err) {}

  getData("rememberMe", (dta) => {
    if (dta) {
      removeItemsFromLocalStorage(["user"]);
    } else {
      clearStorage();
    }

    store.dispatch(resetUser());
    store.dispatch(resetHomeFeed());
    store.dispatch(resetSavedPosts());

    store.dispatch(resetFriends());
    store.dispatch(resetGameReviews());
    store.dispatch(resetInbox());
    store.dispatch(resetMyAssets());
    store.dispatch(resetNotifications());
    store.dispatch(resetQuests());
    store.dispatch(resetUserProfileData());
  });
};

var DeleteUserAccount = (callback) => {
  fetch(EndPoints.DELETE_MY_ACCOUNT, {
    method: "DELETE",
    headers: Interceptor.getHeaders(),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      AppLogger("---------------ACCOUNT DELETION RES-------------", data);
      if (status === 201 || status === 200) {
        LogOutUser(() => {});
        clearStorage()
          .then((res) => callback(true))
          .catch((err) => {
            AppLogger("------------CLEARING STORAGE ERROR---------", err);
            callback(false);
          });
      } else {
        AppShowToast(data?.message?.message || "Wrong password provided");
      }
    })
    .catch((error) => {
      AppLogger("--------- ACCOUNT DELETION - ERROR-----------", error);
    });
};

var VerifyUserEmail = (callback, formData) => {
  fetch(EndPoints.VERIFY_EMAIL, {
    method: "POST",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(formData),
  })
    .then(JSONBodyHelper)
    .then(([status, data]) => {
      AppLogger("---------------Email Verification-------------", data);
      if (status === 201 || status === 200) {
        Interceptor.setToken(data?.data?.access_token || "");
        let UserObj = {
          ...data.data.user,
          ...data?.data?.user?.profile,
          token: data.data.access_token,
          email: formData?.userName,
        };
        // if (UserObj?._id) {
        //     firebase.messaging().subscribeToTopic(UserObj?._id);
        // }
        storeData("user", UserObj)
          .then((res) => {
            store.dispatch(setUser(UserObj));
            callback(UserObj);
          })
          .catch((err) => {
            callback(false);
          });
        callback(data);
      } else {
        console.log(data);
        callback(false);
      }
    });
};
const ResendVerificationCode = (callback, body) => {
  fetch(EndPoints.RESEND_VERIFICATION_CODE, {
    method: "POST",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(body),
  })
    .then(JSONBodyHelper)
    .then(async ([status, data]) => {
      if (status === 201 || status === 200) {
        console.log(data);
        callback(true);
      } else {
        console.log(data);
        callback(false);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(false);
    });
};
const ResetPasswordService = (callback, body) => {
  fetch(EndPoints.RESET_PASSWORD, {
    method: "POST",
    headers: Interceptor.getHeaders(),
    body: JSON.stringify(body),
  })
    .then(JSONBodyHelper)
    .then(async ([status, data]) => {
      if (status === 201 || status === 200) {
        if (data?.data) {
          Interceptor.setToken(data?.data?.access_token || "");
          let UserObj = {
            ...data.data.user,
            ...data?.data?.user?.profile,
            token: data.data.access_token,
          };
          // if (UserObj?._id) {
          //     firebase.messaging().subscribeToTopic(UserObj?._id);
          // }
          storeData("user", UserObj).then((res) => {
            store.dispatch(setUser(UserObj));
            callback(UserObj);
          });
        } else return false;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
};
export {
  LogInUser,
  SignUpUser,
  LogOutUser,
  ChangeUserPassword,
  ForgotPasswordCall,
  DeleteUserAccount,
  VerifyUserEmail,
  ResendVerificationCode,
  ResetPasswordService,
};
