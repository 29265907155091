import React, { useState } from "react";
import "./questItem.css";
import coin from "../../../assets/images/coin.svg";
import { timeRemaining } from "../../../utils/AppHelperMethods";
export default function QuestItem({ item, t }) {
  const [showDetails, setShowDetails] = useState();
  const {
    questTitle,
    questRewardInCoins,
    questRewardInXp,
    userProgress,
    questExpiryDate,
    questDescription,
    goal,
  } = item;
  const remainingTime = timeRemaining(questExpiryDate);

  const clickHandler = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <div className="wrapper" onClick={clickHandler}>
        <div>
          <h4 className="white">{questTitle}</h4>
          <span className="blue">{userProgress?.startCount}</span>
          <span className="gray">
            {" "}
            / {userProgress?.endCount ? userProgress?.endCount : goal}{" "}
            {t("profile.complete")}
          </span>
          <p className="gray">
            {t("profile.timeLeft")}: {remainingTime?.txt}
          </p>
        </div>
        <div>
          <h4 className="rewardsContainer">
            <img src={coin} alt="coins" className="coin" />
            <label className="coinLabel">{questRewardInCoins}</label>
          </h4>

          <div>
            <label className="blue">XP + </label>
            <label>{questRewardInXp}</label>
          </div>
        </div>
      </div>
      {showDetails && (
        <div>
          <p>{questDescription}</p>
        </div>
      )}
      <hr className="hr" />
    </>
  );
}
