import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styles from "./HelpCenter.module.scss";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#262626",
    marginTop: "5px",
    color: "#ffffff",
    width: "50%",
  },
  bullet: {
    display: "inline-block",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    color: "#ffffff",
  },
});

const SubHelpCenter = ({ title, body }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    dropUP: false,
    dropDown: true,
  });
  return (
    <Card className={classes.root}>
      <div>
        <CardContent>
          <Typography className={styles.title}>
            {title}
            {values.dropDown ? (
              <IconButton
                onClick={(event) => {
                  setValues({ ...values, dropDown: !values.dropDown });
                }}
              >
                <ArrowDropDownIcon
                  name="dropDown"
                  className={styles.iconStyle}
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={(event) => {
                  setValues({ ...values, dropDown: !values.dropDown });
                }}
              >
                <ArrowDropUpIcon className={styles.iconStyle} />
              </IconButton>
            )}
          </Typography>
          {values.dropDown ? null : (
            <Typography className={styles.body}>{body}</Typography>
          )}
        </CardContent>
      </div>
    </Card>
  );
};
export default SubHelpCenter;
