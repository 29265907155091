import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import style from "./ExposeInformation.module.scss";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ExposeInformation = ({
  open,
  handleExposeScreen,
  handleReportToUs,
  t,
}) => {
  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleExposeScreen}>
          {t("setting.exposeHeading")}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {t("setting.exposePara1")}{" "}
            <Link to="terms-of-use" className={style.terms}>
              {t("setting.exposeLink1")}
            </Link>
            .
          </Typography>
          <Typography gutterBottom>{t("setting.exposePara2")}</Typography>
          <Typography gutterBottom>
            {t("setting.exposePara3")}{" "}
            <Button
              color="primary"
              onClick={handleReportToUs}
              className={style.terms}
            >
              {t("setting.exposeLink2")}
            </Button>
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleExposeScreen}
            color="primary"
            className={style.exposeButton}
          >
            {t("setting.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ExposeInformation;
