import React, { useRef } from "react";
import styles from "./Media.module.scss";
import ReactPlayer from "react-player";
import PostCamera from "../../dialogbox/postCameraDialog";
import { ReactComponent as Play } from "../../../../assets/images/playbutton.svg";
import { GetSinglePost } from "../../../../services/postService";
import "./boxcard.css";
import "../../../../styles/overlayLoader.css";
const BoxCard = ({ postData, allData }) => {
  var [open, setOpen] = React.useState(false);
  var [resp, setresp] = React.useState({});
  var [loader, setLoader] = React.useState(false);
  const ValidateImage = () => {
    var spli = postData?.attachments[0]?.url.split(".");
    const handlePost = (id) => {
      console.log(id);
      setLoader(true);
      GetSinglePost((data) => {
        if (data) {
          setresp(data);
          setOpen(true);
          setLoader(false);
        }
      }, id);
    };
    return (
      <>
        {postData?.attachments[0]?.type?.substr(0, 5).toLowerCase() ===
        "image" ? (
          <span
            onClick={() => handlePost(postData?._id)}
            className={styles.image}
            style={{ backgroundImage: `url(${postData?.attachments[0]?.url})` }}
          >
            {/* <img
            className={styles.image}
            loading="lazy"
           
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.google.com/url?sa=i&url=https%3A%2F%2F1080motion.com%2Fjump-start-1080-map-montana%2F&psig=AOvVaw12njjKGoMBppgJExHapLg2&ust=1614856378346000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPChvvj-k-8CFQAAAAAdAAAAABAN";
            }}
            src={postData?.attachments[0]?.url}
            style={{ background: "white" }}
            alt="media"
          /> */}
          </span>
        ) : postData?.attachments[0]?.type?.substr(0, 5).toLowerCase() ===
          "video" ? (
          <>
            <ReactPlayer
              url={postData?.attachments[0]?.url}
              onClick={() => handlePost(postData?._id)}
              type="video/mp4"
              width="320px"
              height="320px"
            ></ReactPlayer>
            <span
              style={{ position: "absolute", bottom: "20px", left: "61px" }}
            >
              <Play
                className="box-icon"
                style={{ height: "43px", width: "53px" }}
              />
            </span>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const toggleModal = () => {
    setOpen(!open);
    setresp({});
  };
  // console.log(loader);

  return (
    <>
      <div className={styles.mediaItem} style={{ position: "relative" }}>
        {ValidateImage()}
      </div>

      {open && !loader ? (
        <PostCamera value={open} setval={toggleModal} response={postData} />
      ) : loader ? (
        <div class="overlay">
          <div class="overlay__inner">
            <div class="overlay__content">
              <span class="spinner"></span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BoxCard;
