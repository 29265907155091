import React, { useEffect, useState } from "react";
import styles from "./SavedPosts.module.scss";
import { GetBookmarkPosts } from "../../../../../services/postService";
import PostListing from "../../../../common/Posts/PostListing";
import "../../../../../styles/loader.css";

const SavedPosts = () => {
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    GetBookmarkPosts((res) => {
      setPosts(res.data);
      setLoading(false);
    });
  }, []);
  return (
    <div className={styles.parent}>
      {loading ? (
        <div className="loader alignCenter"></div>
      ) : (
        <PostListing data={posts} />
      )}
    </div>
  );
};
export default SavedPosts;
