import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import style from "./Corners.module.scss";
import coin from "../../../assets/images/coin.svg";
import SnackBar from "../SnackBar";
import { BuyAsset } from "../../../services/customizationService";
import "../../../styles/loader.css";
import FramedAvatar from "./FramedAvatar";
import { useTranslation } from "react-i18next";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "300px",
    minHeight: "200px",
  },
}))(MuiDialogContent);

const BuyCorner = ({ open, handleClose, data, user }) => {
  const { t } = useTranslation();
  const [showSnackBar, setSnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSnackBar = () => {
    setSnackBar(!showSnackBar);
  };
  const handleBuyAsset = () => {
    setLoading(true);
    BuyAsset((res) => {
      if (res) {
        if (res?.success) {
          setMessage(t("dashboard.purSuccess"));
          setSnackBar(!showSnackBar);
          setSeverity("success");
          setTimeout(() => {
            handleClose();
          }, 3000);
        } else {
          setMessage(t("dashboard.purNotCoins"));
          setSnackBar(!showSnackBar);
          setSeverity("error");
        }
      } else {
        setMessage(t("dashboard.rateToast2"));
        setSnackBar(!showSnackBar);
        setSeverity("error");
      }
      setLoading(false);
    }, data._id);
  };
  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("omegaStore.corners")}
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <div className="loader alignModal"></div>
          ) : (
            <div className={style.headerCorner}>
              <div className={style.dialogFooter} style={{ marginLeft: 90 }}>
                <FramedAvatar
                  source={data.attachment.url}
                  profilePic={user.pic}
                />
                <div>
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      paddingTop: "5px",
                      marginLeft: "10px",
                    }}
                    alt="coin logo"
                    src={coin}
                  />
                  * {data.priceInCoins}
                </div>
                {data.isPurchased ? (
                  <button
                    className={style.buttonStyle}
                    onClick={() => {
                      setMessage(t("dashboard.purCoins"));
                      setSeverity("info");
                      setSnackBar(!showSnackBar);
                    }}
                  >
                    {t("dashboard.purchased")}
                  </button>
                ) : (
                  <button
                    className={style.buttonStyle}
                    onClick={handleBuyAsset}
                  >
                    {t("dashboard.buy")}
                  </button>
                )}
                {showSnackBar ? (
                  <SnackBar
                    open={showSnackBar}
                    handleClose={handleSnackBar}
                    message={message}
                    severity={severity}
                  />
                ) : null}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default BuyCorner;
