import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { v1 } from "uuid";
import {
  GetChatMessages,
  DeleteChat,
  MuteChatOfSpecificUser,
} from "../../../services";
import { initSocket, socket } from "../../../services/socketService";
import { CHAT_SOCKET_EVENTS } from "../../../utils/AppConstants";
import Compose from "../Compose";
import Message from "../Message";
// import Toolbar from "../Toolbar/contentTopbar";
import ToolbarButton from "../ToolbarButton";
import { Menu, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setComment } from "../../../redux/reducers/commentSlice";
import { resetFriends } from "../../../redux/reducers/friendsSlice";
import options from "../../../assets/images/options.png";
import ContentTopBar from "../Toolbar/contentTopbar";
import "./MessageList.css";

export default function MessageList({
  selected,
  otherUser,
  handleReload,
  setSelectedConversation,
  t,
}) {
  const [messages, setMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mute, setMute] = useState(false);
  const user = useSelector((state) => state.root.user);
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    initSocket(user?.token);
    scrollToBottom();
    GetCMessages();
    // scrollToBottom();
    let messagesListner = socket.on(CHAT_SOCKET_EVENTS.NEW_MESSAGE, (msg) => {
      console.log(msg);
      setMessages((previousMessages) => [msg, ...previousMessages]);
    });

    socket.emit(CHAT_SOCKET_EVENTS.CONNECTED_WITH, {
      connectWith: otherUser._id,
    });

    return () => {
      socket.emit(CHAT_SOCKET_EVENTS.CONNECTED_WITH, { connectWith: "" });
      messagesListner.removeListener(CHAT_SOCKET_EVENTS.NEW_MESSAGE);
    };
  }, [selected]);
  useEffect(scrollToBottom, [messages]);

  const GetCMessages = () => {
    GetChatMessages(
      (res) => {
        setMessages(res);
      },
      3,
      otherUser._id
    );
  };

  const renderMessages = () => {
    let i = messages.length - 1;
    let messageCount = 0;
    let tempMessages = [];
    while (i >= messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.from.userName === user?.userName;
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.from.userName === user?.userName;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = current.from.userName === user?.userName;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i--;
    }

    return tempMessages;
  };
  const onSend = (val) => {
    if (val.trim()) {
      let guidd = v1();
      let new_message = {
        guid: guidd,
        text: val.trim(),
        message: val.trim(),
        createdAt: new Date(),
        to: otherUser?._id || "",
      };
      socket.emit(CHAT_SOCKET_EVENTS.NEW_MESSAGE, new_message);
    }
  };
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMute = () => {
    MuteChatOfSpecificUser(
      (res) => {
        console.log("--chat of this user is muted successfully", res);
      },
      otherUser._id,
      String(!mute)
    );
    setAnchorEl(null);
  };

  const handleChatDelete = () => {
    DeleteChat((res) => {
      console.log("---chat has been deleted successfully---", res);
      GetCMessages();
      dispatch(setComment({}));
      handleReload();
      setSelectedConversation(null);
      // dispatch(resetFriends());
    }, selected?._id);
    setAnchorEl(null);
  };

  return (
    <div className="message-list">
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem key="one" onClick={handleChatDelete}>
          {t("setting.delete")}
        </MenuItem>
        {/* <MenuItem key="two" onClick={handleMute}>
          {mute ? "Unmute" : "mute"}
        </MenuItem> */}
      </Menu>
      <ContentTopBar
        title={otherUser?.userName}
        rightItems={[
          <img
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleClick}
            key="nsnd"
            src={options}
            style={{
              resize: "contain",
              height: 50,
              width: 50,
            }}
          />,
        ]}
      />

      <div className="message-list-container">
        {renderMessages()}
        <div ref={messagesEndRef} />
      </div>

      <Compose
        otherUser={otherUser}
        onSend={onSend}
        rightItems={[<ToolbarButton key="emoji" icon="ion-ios-send" />]}
        t={t}
      />
    </div>
  );
}
